export class Unternehmen {
  guid: string;
  firmenname: string;
  firmennummer: string;
  strasse: string;
  plz: string;
  ort: string;
  land: string;
  ansprechpartner: string;
  ansprechpartnerEmail: string;
  ansprechpartnerTel: string;
  isDeleted: number;

  constructor () {
    this.guid = "";
    this.firmenname = "";
    this.firmennummer = "";
    this.strasse = "";
    this.plz = "";
    this.ort = "";
    this.land = "";
    this.ansprechpartner = "";
    this.ansprechpartnerEmail = "";
    this.ansprechpartnerTel = "";
    this.isDeleted = 0;
  }
}