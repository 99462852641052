import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { Unternehmensthema } from '@/models/UnternehmensthemenModels';


export type Getters = {
  unternehmensthemen(state: State): Array<Unternehmensthema>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  unternehmensthemen(state) {
    return state.unternehmensthemen;
  },
}