<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm rounded select-unternehmensthema-row" @click="showModal()">
    <div class="col my-auto">{{ thema.kategorie }}</div>
    <div class="col my-auto">{{ thema.thema }}</div>
    <div class="col-1 my-auto">
      <router-link v-if="thema.unternehmenGuid != null" :to="{name: 'EditUnternehmensthema', query: {unternehmensthemaid: thema.id, unternehmenguid: unternehmenguid}}" class="btn btn-sm"><font-awesome-icon icon="pen" /></router-link>
    </div>
  </div>

    <!-- Answer Modal -->
    <div
      class="modal fade p-0"
      :id="'answer' + thema.id"
      tabindex="-1"
      :aria-labelledby="'answer' + thema.id"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      ref="answerModalRef"
      >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-primary py-0">
            <div class="w-100 d-flex justify-content-between py-2 text-white me-3">
              <strong class="modal-title my-auto" :id="'answerModalLabel' + thema.id">
                {{ thema.kategorie}}
              </strong>
            </div>

            <button
              type="button"
              class="btn-close"
              @click="closeModal()"
              ></button>
          </div>

          <div class="modal-body" :id="'answerModalBody' + thema.id">

            <div class="">
              <div class="d-felx flex-column">
                <div class="py-2 px-3 border-bottom border-primary">
                  <strong>{{ thema.thema }}</strong>
                </div>
                <div class="py-2 px-3">
                  Bitte bewerten Sie dieses Thema in Bezug auf Ihr Unternehmen und auf Ihre Stakeholder.
                </div>
                <div class="py-2 px-3">
                  <strong>Beschreibung:</strong>
                  <div v-html="thema.beschreibung"></div>
                </div>

                <hr class="my-3">

                <div class="pb-2 px-3 mb-3">
                  <div class="d-flex justify-content-between">
                    <span>Bitte bewerten Sie die <strong>Wirkungsrichtung</strong> des Themas:</span>
                  </div>

                  <div class="form-check mb-2">
                    <input class="form-check-input" type="radio" :value="2" :id="'insideout-' + thema.id" v-model="frage.wirkungsrichtung">
                    <label class="form-check-label" :for="'insideout-' + thema.id">
                      <span>Wir verursachen eine positive oder negative Wirkung (I-O)</span>
                    </label>
                  </div>

                  <div class="form-check mb-2">
                    <input class="form-check-input" type="radio" :value="1" :id="'outsidein-' + thema.id" v-model="frage.wirkungsrichtung">
                    <label class="form-check-label" :for="'outsidein-' + thema.id">
                      <span>Das Thema hat eine positive oder negative Wirkung auf unser Unternehmen (O-I)</span>
                    </label>
                  </div>

                  <div class="form-check mb-2">
                    <input class="form-check-input" type="radio" :value="3" :id="'bothways-' + thema.id" v-model="frage.wirkungsrichtung">
                    <label class="form-check-label" :for="'bothways-' + thema.id">
                      <span>Beide Richtungen</span>
                    </label>
                  </div>
                </div>

                <hr class="my-3">

                <div v-if="frage.wirkungsrichtung > 0 && frage.wirkungsrichtung < 4" class="py-2 px-3">
                  <div class="d-flex justify-content-between mb-4">
                    <strong>Wann bzw. über welchen Zeitraum ist die Wirkung zu erwarten?</strong>
                  </div>

                  <div class="form-check mb-2">
                    <input class="form-check-input" type="checkbox" value="" :id="'shortterm-' + thema.id" v-model="frage.shortTerm">
                    <label class="form-check-label" :for="'shortterm-' + thema.id">
                      <span>Kurzfristig: bis zu 12 Monate</span>
                    </label>
                  </div>
                        
                  <div class="form-check mb-2">
                    <input class="form-check-input" type="checkbox" value="" :id="'mediumterm-' + thema.id" v-model="frage.mediumTerm">
                    <label class="form-check-label" :for="'mediumterm-' + thema.id">
                      <span>Mittelfristig: bis zu 5 Jahre</span>
                    </label>
                  </div>

                  <div class="form-check mb-2">
                    <input class="form-check-input" type="checkbox" value="" :id="'longterm-' + thema.id" v-model="frage.longTerm">
                    <label class="form-check-label" :for="'longterm-' + thema.id">
                      <span>Langfristig: mehr als 5 Jahre</span>
                    </label>
                  </div>

                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" :id="'zeitraum-dont-' + thema.id" v-model="frage.termDontKnow">
                    <label class="form-check-label" :for="'zeitraum-dont-' + thema.id">
                      <span>Kann ich nicht beurteilen</span>
                    </label>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal()">Abbrechen</button>
            <button type="button" class="btn btn-primary ms-3" @click="saveAnswer()">Speichern</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { Modal } from 'bootstrap';

import { Unternehmensthema } from '@/models/UnternehmensthemenModels';
import { LongList } from '@/models/UmfrageModels';

export default defineComponent({
  name: "LongListRow",

  props: {
    thema: {
      type: Object as PropType<Unternehmensthema>,
      required: true
    },
    unternehmenguid: {
      type: String as PropType<string>,
      required: true
    },
    umfrageGuid: {
      type: String as PropType<string>,
      required: true
    }
  },

  emits: ['add-thema-longlist'],

  setup(props, { emit }) {
    const store = useStore()


    const answerModalRef = ref();
    const closeModal = () => {
      Modal.getInstance(answerModalRef.value)?.hide();
    }
    const showModal = async () => {
      if (answerModalRef.value) {
        new Modal(answerModalRef.value).show();
        clearFrage();
      }
    }

    const frage = ref({
      wirkungsrichtung: 0,
      shortTerm: false,
      mediumTerm: false,
      longTerm: false,
      termDontKnow: false
    });

    
    function saveAnswer() {
      const item = new LongList(props.umfrageGuid, null, props.thema.id, 100);
      item.beantwortet = 1;
      item.relevant = 1;
      item.nichtRelevant = 0;

      if (frage.value.wirkungsrichtung == 1) {
        item.outsideIn = 1;
      }
      else if (frage.value.wirkungsrichtung == 2) {
        item.insideOut = 1;
      }
      else if (frage.value.wirkungsrichtung == 3) {
        item.bothWays = 1;
      }

      item.shortTerm = frage .value.shortTerm ? 1 : 0;
      item.mediumTerm = frage.value.mediumTerm ? 1 : 0;
      item.longTerm = frage.value.longTerm ? 1 : 0;
      item.termDontKnow = frage.value.termDontKnow ? 1 : 0;
      console.log(item);

      closeModal();
      emit('add-thema-longlist', item);
    }

    function clearFrage() {
      frage.value = {
        wirkungsrichtung: 0,
        shortTerm: false,
        mediumTerm: false,
        longTerm: false,
        termDontKnow: false
      }
    }


    return {
      store,

      answerModalRef,
      closeModal,
      showModal,

      frage,
      saveAnswer
    }
  },

})
</script>

<style scoped>
.select-unternehmensthema-row {
  cursor: pointer;
}
</style>