<template>
  <div class="row py-2 mb-2 mx-1 shadow-sm rounded" :class="bgColor">
    <div class="col-4 my-auto border-end cut-text" data-bs-toggle="tooltip" data-bs-placement="top" :title="titel">{{ titel }}</div>
    <div class="col-2 my-auto border-end">{{ longlist.zustimmung }}%</div>
    <div class="col-1 my-auto">{{ longlist.insideOutBewertung == 0 ? '-' : longlist.insideOutBewertung == 1 ? 'Positiv' : 'Negativ' }}</div>
    <div class="col-2 my-auto border-end">{{ longlist.insideOutBewertungWert }}</div>
    <div class="col-1 my-auto">{{ longlist.outsideInBewertung == 0 ? '-' : longlist.outsideInBewertung == 1 ? 'Chance' : 'Risiko' }}</div>
    <div class="col-2 my-auto">{{ longlist.outsideInBewertungWert }}</div>
  </div>
</template>

<script lang="ts">
  import { PropType, defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';

  import { ActionTypes as FragenkatalogActionTypes } from '@/store/modules/Fragenkatalog/actions';

  import { LongList } from '@/models/UmfrageModels';
  import { Fragenkatalog } from '@/models/FragenkatalogModels';
  import { Unternehmensthema } from '@/models/UnternehmensthemenModels';

  export default defineComponent({
    name: 'ErgebnisLonglistRow',

    props: {
      longlist: {
        type: Object as PropType<LongList>,
        required: true,
      },
    },

    setup(props) {
      const store = useStore();

      const titel = computed(() => {
        if (props.longlist.frageid != null) {
          const frage = store.getters.fragenkatalog.find((f: Fragenkatalog) => f.id == props.longlist.frageid);
          return `${frage.nr}. ${frage.frageDE}`;
        } else if (props.longlist.themaid != null) {
          const thema = store.getters.unternehmensthemen.find((t: Unternehmensthema) => t.id == props.longlist.themaid);
          return `${thema.kategorie} - ${thema.thema}`;
        } else {
          return 'unbekannt';
        }
      });

      const bgColor = computed(() => {
        if (
          (props.longlist.insideOutBewertung == 1 && props.longlist.outsideInBewertung == 0) ||
          (props.longlist.insideOutBewertung == 0 && props.longlist.outsideInBewertung == 1) ||
          (props.longlist.insideOutBewertung == 1 && props.longlist.outsideInBewertung == 1)
        ) {
          return 'bg-positive';
        } else if (
          (props.longlist.insideOutBewertung == 2 && props.longlist.outsideInBewertung == 0) ||
          (props.longlist.insideOutBewertung == 0 && props.longlist.outsideInBewertung == 2) ||
          (props.longlist.insideOutBewertung == 2 && props.longlist.outsideInBewertung == 2)
        ) {
          return 'bg-negativ';
        } else if (
          (props.longlist.insideOutBewertung == 1 && props.longlist.outsideInBewertung == 2) ||
          (props.longlist.insideOutBewertung == 2 && props.longlist.outsideInBewertung == 1)
        ) {
          return 'bg-positiv-negativ';
        } else {
          return 'bg-white';
        }
      });

      return {
        store,
        titel,
        bgColor,
      };
    },

    mounted() {
      if (this.store.getters.fragenkatalog.length == 0) {
        this.store.dispatch(FragenkatalogActionTypes.GetFragenkatalog, undefined);
      }
    },
  });
</script>

<style scoped>
  .bg-positive {
    /* green */
    background-color: rgba(0, 128, 0, 1);
    color: white;
  }

  .bg-negativ {
    /* orangered */
    background-color: rgba(255, 0, 0, 1);
    color: white;
  }

  .bg-positiv-negativ {
    /* orange */
    background-color: rgba(255, 165, 0, 1);
  }
</style>
