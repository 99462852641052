import { Status } from "@/models/CommonModels"

export type State = {
  status: Status,
  language: string
}

export const state: State = {
  status: new Status(),
  language: localStorage.getItem("language") != null ? JSON.parse(localStorage.language) : "de",
}
