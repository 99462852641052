<template>
  <router-link :to="{ name: 'DetailsUnternehmen', params: { guid: unternehmen.guid } }" class="btn btn-outline-secondary mb-2"
    ><font-awesome-icon icon="chevron-left" /> Zurück</router-link
  >

  <!-- <h1 class="mb-0">{{ umfrage.titel }}</h1>
  <small>{{ unternehmen.firmenname }}</small>

  
  <div class="bg-white shadow-sm p-3 my-4">
    <p><strong>Anzahl Teilnehmer:</strong> {{ teilnehmerliste.length }}</p>
    <p><strong>Anzahl beantworteter Fragen:</strong> {{ teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.beantwortet == 1).length }} von {{ teilnehmerliste.length * fragenkatalog.length }} (Anzahl Teilnehmer {{ teilnehmerliste.length }} x Anzahl Fragen {{ fragenkatalog.length }})</p>
  </div>
  -->
  <div class="text-center mb-5">
    <small>{{ unternehmen.firmenname }}</small>
    <h1 class="mt-0">{{ umfrage.titel }}</h1>
    <p class="my-3"><font-awesome-icon icon="down-long" size="2x" /></p>

    <!-- <LoadingSpinner v-if="teilnehmerantwortenloading || loading" text="... Umfragedaten werden geladen ..." /> -->

    <div>
      <h3>Unternehmens- und Stakeholderthemen</h3>
      <div class="row">
        <div class="col-3 offset-3 text-end">
          <router-link
            :to="{ name: 'SelectUnternehmensthemen', params: { unternehmenguid: unternehmen.guid, umfrageguid: umfrage.guid } }"
            class="btn btn-outline-secondary w-100"
            >Themen bearbeiten</router-link
          >
        </div>

        <div class="col-3 text-start">
          <router-link :to="{ name: 'UmfrageLongList', params: { unternehmenguid: unternehmen.guid, umfrageguid: umfrage.guid } }" class="btn btn-primary w-100"
            >Themen ansehen</router-link
          >
        </div>
      </div>

      <p class="my-3"><font-awesome-icon icon="down-long" size="2x" /></p>

      <h3>Teilnehmer</h3>
      <div class="row">
        <div class="col-3 offset-3 text-end">
          <CreateTeilnehmerModal :umfrageguid="umfrage.guid" />
          <!-- <button class="btn btn-outline-secondary w-100 disabled">Teilnehmer einladen</button> -->
        </div>

        <div class="col-3 text-start">
          <router-link
            :to="{ name: 'UmfrageTeilnehmer', params: { unternehmenguid: unternehmen.guid, umfrageguid: umfrage.guid } }"
            class="btn btn-primary w-100"
            >Teilnehmer ansehen</router-link
          >
        </div>
      </div>

      <p class="my-3"><font-awesome-icon icon="down-long" size="2x" /></p>

      <h3>Umfrage</h3>
      <span>({{ new Date().toISOString() >= umfrage.enddatum ? 'beendet' : 'aktiv' }})</span>

      <p>
        <strong>{{ startdatumConverter }}</strong> bis <strong>{{ enddatumConverter }}</strong
        ><button class="btn text-muted" @click="showModal()"><font-awesome-icon icon="gear" /></button>
      </p>

      <div class="row">
        <div class="col-3 offset-3 text-end">
          <button class="btn btn-outline-secondary w-100" @click="umfrageBeenden()" :disabled="new Date().toISOString() >= umfrage.enddatum">
            Umfrage beenden
          </button>
        </div>

        <div class="col-3 text-start">
          <router-link :to="{ name: 'UmfrageERNA', params: { unternehmenguid: unternehmen.guid, umfrageguid: umfrage.guid } }" class="btn btn-primary w-100"
            >Umfrageergebnisse ansehen</router-link
          >
        </div>
      </div>

      <p class="my-3"><font-awesome-icon icon="down-long" size="2x" /></p>

      <h3>Long-List</h3>
      <div class="row">
        <div class="col-3 offset-3 text-end">
          <router-link
            :to="{ name: 'EditUmfrageLongList', params: { unternehmenguid: unternehmen.guid, umfrageguid: umfrage.guid } }"
            class="btn btn-outline-secondary w-100"
            >Long List bearbeiten</router-link
          >
        </div>

        <div class="col-3 text-start">
          <router-link :to="{ name: 'UmfrageLongList', params: { unternehmenguid: unternehmen.guid, umfrageguid: umfrage.guid } }" class="btn btn-primary w-100"
            >Long List ansehen</router-link
          >
        </div>
      </div>

      <p class="my-3"><font-awesome-icon icon="down-long" size="2x" /></p>

      <h3>Stakeholder</h3>
      <div class="row">
        <div class="col-3 offset-3 text-end">
          <router-link
            :to="{ name: 'EditUmfrageStakeholderZuordnung', params: { unternehmenguid: unternehmen.guid, umfrageguid: umfrage.guid } }"
            class="btn btn-outline-secondary w-100"
            >Stakeholder Zuordnung bearbeiten</router-link
          >
        </div>

        <div class="col-3 text-start">
          <router-link
            :to="{ name: 'UmfrageStakeholderZuordnung', params: { unternehmenguid: unternehmen.guid, umfrageguid: umfrage.guid } }"
            class="btn btn-primary w-100"
            >Stakeholder Zuordnung ansehen</router-link
          >
        </div>
      </div>

      <div class="mt-4">
        <p class="my-3"><font-awesome-icon icon="down-long" size="2x" /></p>

        <div class="row my-4">
          <div class="col">
            <h3>Inside-Out</h3>
            <router-link
              :to="{ name: 'EditThemenBewertungInsideOut', params: { unternehmenguid: unternehmen.guid, umfrageguid: umfrage.guid } }"
              class="btn btn-primary"
              >Themen bewerten</router-link
            >

            <p class="my-3"><font-awesome-icon icon="down-long" size="2x" /></p>

            <h3>Inside-Out Priorisierung</h3>
            <router-link
              :to="{ name: 'EditInsideOutThemenPriorisierung', params: { unternehmenguid: unternehmen.guid, umfrageguid: umfrage.guid } }"
              class="btn btn-primary"
              >Themen priorisieren</router-link
            >
          </div>

          <div class="col-1"></div>
          <div class="col-1"></div>

          <div class="col">
            <h3>Outside-In</h3>
            <router-link
              :to="{ name: 'EditThemenBewertungOutsideIn', params: { unternehmenguid: unternehmen.guid, umfrageguid: umfrage.guid } }"
              class="btn btn-primary"
              >Themen bewerten</router-link
            >

            <p class="my-3"><font-awesome-icon icon="down-long" size="2x" /></p>

            <h3>Outside-In Priorisierung</h3>
            <router-link
              :to="{ name: 'EditOutsideInThemenPriorisierung', params: { unternehmenguid: unternehmen.guid, umfrageguid: umfrage.guid } }"
              class="btn btn-primary"
              >Themen priorisieren</router-link
            >
          </div>
        </div>

        <p class="my-3"><font-awesome-icon icon="down-long" size="2x" /></p>

        <h3>Ergebnis</h3>
        <router-link
          :to="{ name: 'UnternehmenUmfrageErgebnis', params: { unternehmenguid: unternehmen.guid, umfrageguid: umfrage.guid } }"
          class="btn btn-primary"
          >Ergebnis ansehen</router-link
        >
      </div>
    </div>

    <!-- Umfrage Datum bearbeiten Modal -->
    <div
      class="modal fade"
      id="editUmfrageModal"
      tabindex="-1"
      aria-labelledby="editUmfrageModal"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      ref="editUmfrageModalRef"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editUmfrageModalLabel">{{ umfrage.titel }} - Umfragezeitraum bearbeiten</h5>
            <button type="button" class="btn-close" aria-label="Close" @click="closeModal()"></button>
          </div>

          <div class="modal-body text-start">
            <LoadingSpinner v-if="loading" />

            <div v-else-if="!loading && errorMsg != ''" class="alert alert-warning">
              {{ errorMsg }}
            </div>

            <form v-else @submit.prevent="updateUmfrage()">
              <div class="row mb-3">
                <div class="col">
                  <label for="startdatum" class="form-label">Startdatum</label>
                  <input type="date" class="form-control" id="startdatum" v-model="editStartDatumConvert" required />
                </div>

                <div class="col">
                  <label for="enddatum" class="form-label">Enddatum</label>
                  <input type="date" class="form-control" id="enddatum" v-model="editEndDatumConvert" required />
                </div>
              </div>

              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-secondary me-3" @click="closeModal()">Abbrechen</button>

                <button type="submit" class="btn btn-primary">Speichern</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <LoadingOverlay v-if="teilnehmerantwortenloading || loading" text="... Umfragedaten werden geladen ..." />
</template>

<script lang="ts">
  import { computed, defineComponent, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Modal } from 'bootstrap';

  import { ActionTypes as TeilnehmerAntwortActionTypes } from '@/store/modules/TeilnehmerAntwort/actions';
  import { ActionTypes as FragenkatalogActionTypes } from '@/store/modules/Fragenkatalog/actions';
  import { ActionTypes as UnternehmensthemaActionTypes } from '@/store/modules/Unternehmensthema/actions';
  import { ActionTypes as UmfrageActionTypes } from '@/store/modules/Umfrage/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import LoadingOverlay from '@/components/LoadingOverlay.vue';
  import UmfrageTeilnehmerRow from '@/components/Umfrage/UmfrageTeilnehmerRow.vue';
  import SummeUmfrageFrageRow from '@/components/Umfrage/SummeUmfrageFrageRow.vue';
  import CreateTeilnehmerModal from '@/components/Umfrage/CreateTeilnehmerModal.vue';

  import { Umfrage } from '@/models/UmfrageModels';
  import { Unternehmen } from '@/models/UnternehmenModels';
  import { Teilnehmer } from '@/models/TeilnehmerModels';
  import { TeilnehmerAntwort } from '@/models/TeilnehmerAntwortModels';

  export default defineComponent({
    name: 'UnternehmenUmfrageView',

    components: {
      LoadingOverlay,
      LoadingSpinner,
      UmfrageTeilnehmerRow,
      SummeUmfrageFrageRow,
      CreateTeilnehmerModal,
    },

    props: {
      unternehmenguid: {
        type: String,
        required: true,
      },

      umfrageguid: {
        type: String,
        required: true,
      },
    },

    setup(props) {
      const store = useStore();
      const router = useRouter();

      const loading = computed(
        () => store.getters.status.getLonglistLoading || store.getters.status.getUnternehmensthemaLoading || store.getters.status.updateUmfrageLoading
      );
      const errorMsg = computed(() => store.getters.status.umfrageErrorMsg);

      const unternehmen = computed(() => store.getters.unternehmenliste.find((u: Unternehmen) => u.guid == props.unternehmenguid));
      const umfrage = computed(() => store.getters.umfragen.find((u: Umfrage) => u.guid == props.umfrageguid));
      const teilnehmerliste = computed(() => store.getters.teilnehmerliste.filter((t: Teilnehmer) => t.umfrageGuid == props.umfrageguid));
      const teilnehmerantworten = computed(() =>
        store.getters.teilnehmerantworten.filter((ta: TeilnehmerAntwort) => teilnehmerliste.value.map((t: Teilnehmer) => t.guid).includes(ta.teilnehmerGuid))
      );
      const fragenkatalog = computed(() => store.getters.fragenkatalog);
      const unternehmensthemen = computed(() => store.getters.unternehmensthemen);

      const teilnehmerantwortenloading = ref(false);
      function getTeilnehmerantworten() {
        teilnehmerantwortenloading.value = true;
        store.dispatch(TeilnehmerAntwortActionTypes.ClearTeilnehmerantwort, undefined);
        const promises = [];
        for (var teilnehmer of teilnehmerliste.value) {
          const promise = store.dispatch(TeilnehmerAntwortActionTypes.GetTeilnehmerantwortByTeilnehmerguid, teilnehmer.guid);
          promises.push(promise);
        }

        Promise.all(promises).then(() => {
          // All requests have completed
          // console.log("All requests finished successfully.");
          teilnehmerantwortenloading.value = false;
        });
      }

      const startdatumConverter = computed(() => {
        if (umfrage.value.startdatum == null) {
          return '';
        } else {
          const date = new Date(umfrage.value.startdatum);
          return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
        }
      });

      const enddatumConverter = computed(() => {
        if (umfrage.value.enddatum == null) {
          return '';
        } else {
          const date = new Date(umfrage.value.enddatum);
          return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
        }
      });

      const editStartDatumConvert = computed({
        get() {
          if (umfrage.value.startdatum == null) {
            const date = new Date();
            return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          } else {
            const date = new Date(umfrage.value.startdatum);
            return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          }
        },

        set(value) {
          umfrage.value.startdatum = new Date(value);
        },
      });

      const editEndDatumConvert = computed({
        get() {
          if (umfrage.value.enddatum == null) {
            const date = new Date();
            return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          } else {
            const date = new Date(umfrage.value.enddatum);
            return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          }
        },

        set(value) {
          umfrage.value.enddatum = new Date(value);
        },
      });

      const editUmfrageModalRef = ref();
      function closeModal() {
        Modal.getInstance(editUmfrageModalRef.value)?.hide();
      }

      function showModal() {
        if (editUmfrageModalRef.value) {
          new Modal(editUmfrageModalRef.value).show();
        }
      }

      function updateUmfrage() {
        store.dispatch(UmfrageActionTypes.UpdateUmfrage, umfrage.value).then(() => {
          if (errorMsg.value == '') {
            closeModal();
          }
        });
      }

      function umfrageBeenden() {
        umfrage.value.enddatum = new Date();
        updateUmfrage();
      }

      return {
        store,
        router,

        loading,
        errorMsg,

        unternehmen,
        umfrage,

        teilnehmerliste,
        teilnehmerantworten,
        teilnehmerantwortenloading,
        fragenkatalog,
        getTeilnehmerantworten,
        unternehmensthemen,

        startdatumConverter,
        enddatumConverter,

        editUmfrageModalRef,
        closeModal,
        showModal,
        editStartDatumConvert,
        editEndDatumConvert,

        updateUmfrage,
        umfrageBeenden,
      };
    },

    mounted() {
      this.getTeilnehmerantworten();

      if (this.fragenkatalog.length == 0) {
        this.store.dispatch(FragenkatalogActionTypes.GetFragenkatalog, undefined);
      }

      this.store.dispatch(UnternehmensthemaActionTypes.GetUnternehmensthema, this.unternehmenguid);
      this.store.dispatch(UmfrageActionTypes.GetUmfrageLonglist, this.umfrageguid);
    },
  });
</script>
