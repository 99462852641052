import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
// import ApiService from "@/services/ApiService";
import { ApiService } from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { LongList, LongListStakeholder, SetLongListStakeholderObject, Umfrage } from '@/models/UmfrageModels';


export enum ActionTypes {
  GetUmfragen = "GETUMFRAGELISTE",
  GetUmfrageByGuid = "GETUMFRAGEBYGUID",
  GetUmfrageByUnternehmenguid = "GETUMFRAGEBYUNTERNEHMENGUID",
  GetActiveUmfragenByUnternehmenguid = "GETAKTIVEUMFRAGENBYUNTERNEHMENGUID",
  CreateUmfrage = "CREATEUMFRAGELISTE",
  UpdateUmfrage = "UPDATEUMFRAGELISTE",
  DeleteUmfrage = "DELETEUMFRAGELISTE",

  GetUmfrageLonglist = "GETUMFRAGELONGLIST",
  CreateLonglistRange = "CREATELONGLISTRANGE",
  UpdateLonglist = "UPDATELONGLIST",
  UpdateLonglistRange = "UPDATELONGLISTRANGE",
  DeleteLonglist = "DELETELONGLIST",

  CreateLongListStakeholderRange = "CREATELONGLISTSTAKEHOLDERRANGE",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetUmfragen](context: ActionArguments): Promise<void>
  [ActionTypes.GetUmfrageByGuid](context: ActionArguments, guid: string): Promise<void>
  [ActionTypes.GetUmfrageByUnternehmenguid](context: ActionArguments, guid: string): Promise<void>
  [ActionTypes.GetActiveUmfragenByUnternehmenguid](context: ActionArguments, guid: string): Promise<void>
  [ActionTypes.CreateUmfrage](context: ActionArguments, data: Umfrage): Promise<void>
  [ActionTypes.UpdateUmfrage](context: ActionArguments, data: Umfrage): Promise<void>
  [ActionTypes.DeleteUmfrage](context: ActionArguments, umfrageguid: string): Promise<void>

  [ActionTypes.GetUmfrageLonglist](context: ActionArguments, umfrageguid: string): Promise<void>
  [ActionTypes.CreateLonglistRange](context: ActionArguments, data: Array<LongList>): Promise<void>
  [ActionTypes.UpdateLonglist](context: ActionArguments, data: LongList): Promise<void>
  [ActionTypes.UpdateLonglistRange](context: ActionArguments, data: Array<LongList>): Promise<void>
  [ActionTypes.DeleteLonglist](context: ActionArguments, data: LongList): Promise<void>

  [ActionTypes.CreateLongListStakeholderRange](context: ActionArguments, data: SetLongListStakeholderObject): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  //#region Umfrage
  async [ActionTypes.GetUmfrageByGuid]({commit, dispatch, rootGetters}, guid) {
    const status = rootGetters.status;
    status.getUmfrageLoading = true;
    status.umfrageErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getUmfrageByGuid(guid)
      .then(res => {
        const data: Umfrage = res.data
        const datalist = new Array<Umfrage>();
        datalist.push(data);
        commit(MutationTypes.SetUmfragen, datalist)
      })
      .catch(error => {
        status.umfrageErrorMsg = error.response;
      })
      .finally(() => {
        status.getUmfrageLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetUmfrageByUnternehmenguid]({commit, dispatch, rootGetters}, guid) {
    const status = rootGetters.status;
    status.getUmfrageLoading = true;
    status.umfrageErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllUmfrageByUnternehmenguid(guid)
      .then(res => {
        const data: Array<Umfrage> = res.data
        commit(MutationTypes.SetUmfragen, data)
      })
      .catch(error => {
        status.umfrageErrorMsg = error.response;
      })
      .finally(() => {
        status.getUmfrageLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetActiveUmfragenByUnternehmenguid]({commit, dispatch, rootGetters}, guid) {
    const status = rootGetters.status;
    status.getUmfrageLoading = true;
    status.umfrageErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.GetActiveUmfragenByUnternehmenguid(guid)
      .then(res => {
        const data: Array<Umfrage> = res.data
        commit(MutationTypes.SetUmfragen, data)
      })
      .catch(error => {
        status.umfrageErrorMsg = error.response;
      })
      .finally(() => {
        status.getUmfrageLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetUmfragen]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getUmfrageLoading = true;
    status.umfrageErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllUmfrage()
      .then(res => {
        const data: Array<Umfrage> = res.data
        commit(MutationTypes.SetUmfragen, data)
      })
      .catch(error => {
        status.umfrageErrorMsg = error.response;
      })
      .finally(() => {
        status.getUmfrageLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateUmfrage]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createUmfrageLoading = true;
    status.umfrageErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.CreateUmfrage(data)
      .then(res => {
        const data: Umfrage = res.data
        commit(MutationTypes.AddUmfrage, data)
      })
      .catch(error => {
        status.umfrageErrorMsg = error.response;
      })
      .finally(() => {
        status.createUmfrageLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateUmfrage]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateUmfrageLoading = true;
    status.umfrageErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.UpdateUmfrage(data)
      .then(res => {
        const data: Umfrage = res.data
        commit(MutationTypes.UpdateUmfrage, data)
      })
      .catch(error => {
        status.umfrageErrorMsg = error.response;
      })
      .finally(() => {
        status.updateUmfrageLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteUmfrage]({commit, dispatch, rootGetters}, umfrageguid) {
    const status = rootGetters.status;
    status.deleteUmfrageLoading = true;
    status.umfrageErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.DeleteUmfrage(umfrageguid)
      .then(res => {
        commit(MutationTypes.DeleteUmfrage, umfrageguid)
      })
      .catch(error => {
        status.umfrageErrorMsg = error.response;
      })
      .finally(() => {
        status.deleteUmfrageLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region Longlist
  async [ActionTypes.GetUmfrageLonglist]({commit, dispatch, rootGetters}, umfrageguid) {
    const status = rootGetters.status;
    status.getLonglistLoading = true;
    status.longlistErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.GetUmfrageLonglist(umfrageguid)
      .then(res => {
        const data: Array<LongList> = res.data
        commit(MutationTypes.SetLonglist, data)
      })
      .catch(error => {
        status.longlistErrorMsg = error.response;
      })
      .finally(() => {
        status.getLonglistLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateLonglistRange]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createLonglistLoading = true;
    status.longlistErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.CreateLonglistRange(data)
      .then(res => {
        const data: Array<LongList> = res.data
        commit(MutationTypes.SetLonglist, data)
      })
      .catch(error => {
        status.longlistErrorMsg = error.response;
      })
      .finally(() => {
        status.createLonglistLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateLonglist]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateLonglistLoading = true;
    status.longlistErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.UpdateLonglist(data)
      .then(res => {
        const data: LongList = res.data
        commit(MutationTypes.UpdateLonglist, data)
      })
      .catch(error => {
        status.longlistErrorMsg = error.response;
      })
      .finally(() => {
        status.updateLonglistLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateLonglistRange]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateLonglistLoading = true;
    status.longlistErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.UpdateLonglistRange(data)
      .then(res => {
        const data: Array<LongList> = res.data
        commit(MutationTypes.SetLonglist, data)
      })
      .catch(error => {
        status.longlistErrorMsg = error.response;
      })
      .finally(() => {
        status.updateLonglistLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },


  async [ActionTypes.DeleteLonglist]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.deleteLonglistLoading = true;
    status.longlistErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.DeleteLonglist(data.id)
      .then(res => {
        commit(MutationTypes.DeleteLonglist, data)
      })
      .catch(error => {
        status.longlistErrorMsg = error.response;
      })
      .finally(() => {
        status.deleteLonglistLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region LonglistStakeholder
  async [ActionTypes.CreateLongListStakeholderRange]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createLonglistStakeholderLoading = true;
    status.longlistStakeholderErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.CreateLonglistStakeholderRange(data.stakeholder)
      .then(res => {
        const result: Array<LongListStakeholder> = res.data
        const dto = new SetLongListStakeholderObject(data.umfrageGuid, result);
        commit(MutationTypes.SetLonglistStakeholder, dto)
      })
      .catch(error => {
        status.longlistStakeholderErrorMsg = error.response;
      })
      .finally(() => {
        status.createLonglistStakeholderLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion
}