<template>
  <div class="row py-2 mx-1 bg-white shadow-sm rounded">
    <div class="col my-auto">{{ stakeholder.name }}</div>
    <div v-if="displayDetailsButton" class="col-1 my-auto text-end">
      <router-link :to="{name: 'DetailsStakeholder', params: { stakeholderid: stakeholder.id.toString()}}" class="btn btn-primary btn-sm">
        <font-awesome-icon icon="magnifying-glass-plus" />
      </router-link>
    </div>
  </div>
</template>
  
<script lang="ts">
  import { PropType, defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';

  import { Stakeholder } from '@/models/StakeholderModels';

  export default defineComponent({
    name: "StakeholderRow",
  
    props: {
      stakeholder: {
        type: Object as PropType<Stakeholder>,
        required: true
      },

      displayDetailsButton: {
        type: Boolean,
        default: true
      }
    },
  })
  </script>