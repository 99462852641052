import { createApp } from 'vue'

import App from './App.vue'
// import Emitter from 'tiny-emitter';
import router from './router'
import {store} from './store'
// import './registerServiceWorker'

import ApiService from '@/services/ApiService';
import { setupInterceptorsTo } from '@/services/Interceptors';

import { library } from "@fortawesome/fontawesome-svg-core";

import { faWindows } from '@fortawesome/free-brands-svg-icons'

import {
  faArrowDownLong,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faArrowUpLong,
  faBars,
  faBoxOpen,
  faBoxesStacked,
  faBuilding,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleInfo,
  faCopy,
  faDownLong,
  faEnvelope,
  faFileDownload,
  faGear,
  faListCheck,
  faLock,
  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faMinus,
  faPaperPlane,
  faPen,
  faPenToSquare,
  faPlus,
  faPrint,
  faRotate,
  faStar,
  faTableColumns,
  faTableList,
  faTimes,
  faTrash,
  faUsers,
  faUsersLine,
  faUsersViewfinder,
  faWarehouse
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faArrowDownLong,
  faArrowUpLong,
  faArrowRightFromBracket,
  faArrowRightToBracket,

  faBars,
  faBoxOpen,
  faBuilding,
  faBoxesStacked,
  
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleInfo,
  faCopy,

  faDownLong,

  faEnvelope,
  faFileDownload,

  faGear,

  faListCheck,
  faLock,

  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  
  faMinus,
  faPaperPlane,
  faPen,
  faPenToSquare,
  faPlus,
  faPrint,
  faRotate,

  faStar,

  faTableColumns,
  faTimes,
  faTableList,
  faTrash,

  faUsers,
  faUsersLine,
  faUsersViewfinder,
  
  faWarehouse,
  faWindows
);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const app = createApp(App);
// app.config.globalProperties.$msalInstance = {};
// app.config.globalProperties.$emitter = new Emitter();

app.use(store)
app.use(router)

// Set up interceptors after the store is initialized
setupInterceptorsTo(ApiService);

app.component("font-awesome-icon", FontAwesomeIcon)
app.mount('#app')

import "bootstrap"