import { RootState } from '@/store';
import { GetterTree } from "vuex"
import { State } from "./state"
import { AuthUser } from "@/models/AuthModels"


export type Getters = {
  authUser(state: State): AuthUser | null,
}

export const getters: GetterTree<State, RootState> & Getters = {
  authUser(state) {
    return state.authUser;
  },
}