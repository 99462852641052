<template>
  <div v-if="fragenkatalogid != '' && fragenkatalog == null" class="alert alert-danger">
    Die Frage konnte nicht gefunden werden.

    <button type="button" class="btn btn-secondary me-3" @click="cancel()">Zurück</button>
  </div>

  <div v-else>
    <h1 v-if="editFragenkatalog.id == 0">Neue Frage</h1>
    <h1 v-else>Frage bearbeiten</h1>
  </div>

  <hr class="my-4" />

  <div class="mt-3">
    <form @submit.prevent="saveFragenkatalog()">
      <div class="row mx-1 mb-2">
        <div class="col-6 bg-white shadow p-3 me-1">
          <div class="mb-3">
            <label for="frage-quelle" class="form-label">Quelle</label>
            <input type="text" class="form-control" id="frage-quelle" v-model="editFragenkatalog.quelle" />
          </div>

          <div class="mb-3">
            <label for="frage-nr" class="form-label">Nr.</label>
            <input type="number" class="form-control" id="frage-nr" v-model="editFragenkatalog.nr" />
          </div>
        </div>
      </div>

      <div class="row mx-1 mb-3">
        <div class="col bg-white shadow p-3 me-1">
          <h3>Deutsch</h3>

          <div class="mb-3">
            <label for="frage-aspektDE" class="form-label">Aspekt</label>
            <input type="text" class="form-control" id="frage-aspektDE" v-model="editFragenkatalog.aspektDE" />
          </div>

          <div class="mb-3">
            <label for="frage-frageDE" class="form-label">Frage</label>
            <input type="text" class="form-control" id="frage-frageDE" v-model="editFragenkatalog.frageDE" />
          </div>

          <div class="mb-3">
            <label for="frage-beschreibungDE" class="form-label">Beschreibung</label>
            <!-- <textarea type="text" class="form-control" id="frage-beschreibungDE" rows="3" v-model="editFragenkatalog.beschreibungDE"></textarea> -->
            <TextEditor v-model="editFragenkatalog.beschreibungDE" />
          </div>
        </div>

        <div class="col bg-white shadow p-3 ms-1">
          <h3>Englisch</h3>

          <div class="mb-3">
            <label for="frage-aspektEN" class="form-label">Aspekt</label>
            <input type="text" class="form-control" id="frage-aspektEN" v-model="editFragenkatalog.aspektEN" />
          </div>

          <div class="mb-3">
            <label for="frage-frageEN" class="form-label">Frage</label>
            <input type="text" class="form-control" id="frage-frageEN" v-model="editFragenkatalog.frageEN" />
          </div>

          <div class="mb-3">
            <label for="frage-beschreibungEN" class="form-label">Beschreibung</label>
            <!-- <textarea type="text" class="form-control" id="frage-beschreibungEN" rows="3" v-model="editFragenkatalog.beschreibungEN"></textarea> -->
            <TextEditor v-model="editFragenkatalog.beschreibungEN" />
          </div>
        </div>
      </div>

      <div v-if="errorMsg != ''" class="alert alert-danger my-3">Es ist ein Fehler aufgetreten. Bite probieren Sie es später erneut.</div>

      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-secondary me-3" @click="cancel()">Abbrechen</button>
        <button type="submit" class="btn btn-primary">Speichern</button>
      </div>
    </form>

    <hr class="my-4" />

    <button type="button" class="btn btn-danger" @click="deleteFragenkatalog()"><font-awesome-icon icon="trash" /> Frage löschen</button>
  </div>

  <LoadingOverlay v-if="loading" />
</template>

<script lang="ts">
  import { defineComponent, PropType, computed, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';

  import { Fragenkatalog } from '@/models/FragenkatalogModels';

  import { ActionTypes as FragenkatalogActionTypes } from '@/store/modules/Fragenkatalog/actions';

  import LoadingOverlay from '@/components/LoadingOverlay.vue';
  import TextEditor from '@/components/TextEditor/TextEditor.vue';

  export default defineComponent({
    name: 'EditFragenkatalogView',

    components: {
      LoadingOverlay,
      TextEditor,
    },

    props: {
      fragenkatalogid: {
        type: String as PropType<string>,
        default: '',
      },
    },

    setup(props) {
      const store = useStore();
      const router = useRouter();

      const loading = computed(
        () =>
          store.getters.status.updateFragenkatalogLoading || store.getters.status.createFragenkatalogLoading || store.getters.status.deleteFragenkatalogLoading
      );
      const errorMsg = computed(() => store.getters.status.fragenkatalogErrorMsg);

      const fragenkatalog = computed(() => store.getters.fragenkatalog.find((f: Fragenkatalog) => f.id == Number.parseInt(props.fragenkatalogid)));
      const editFragenkatalog = ref<Fragenkatalog>(
        fragenkatalog.value != null ? (JSON.parse(JSON.stringify(fragenkatalog.value)) as Fragenkatalog) : new Fragenkatalog()
      );

      function saveFragenkatalog() {
        if (editFragenkatalog.value.id == 0) {
          store.dispatch(FragenkatalogActionTypes.CreateFragenkatalog, editFragenkatalog.value).then(() => {
            if (errorMsg.value == '') {
              cancel();
            }
          });
        } else {
          store.dispatch(FragenkatalogActionTypes.UpdateFragenkatalog, editFragenkatalog.value).then(() => {
            if (errorMsg.value == '') {
              cancel();
            }
          });
        }
      }

      function deleteFragenkatalog() {
        store.dispatch(FragenkatalogActionTypes.DeleteFragenkatalog, editFragenkatalog.value.id).then(() => {
          if (errorMsg.value == '') {
            cancel();
          }
        });
      }

      function cancel() {
        router.back();
      }

      return {
        loading,
        errorMsg,

        fragenkatalog,
        editFragenkatalog,

        saveFragenkatalog,
        deleteFragenkatalog,
        cancel,
      };
    },
  });
</script>
