import { MutationTree } from "vuex"
import { State } from "./state"
import { Fragenkatalog } from "@/models/FragenkatalogModels"
import { Unternehmensthema } from "@/models/UnternehmensthemenModels"

export enum MutationTypes {
  SetUnternehmensthema = "SETUNTERNEHMENSTHAMA",
  AddUnternehmensthema = "ADDUNTERNEHMENSTHAMA",
  UpdateUnternehmensthema = "UPDATEUNTERNEHMENSTHAMA",
  DeleteUnternehmensthema = "DELETEUNTERNEHMENSTHAMA"
}

export type Mutations = {
  [MutationTypes.SetUnternehmensthema](state: State, unternehmensthema: Array<Unternehmensthema>): void
  [MutationTypes.AddUnternehmensthema](state: State, unternehmensthema: Unternehmensthema): void
  [MutationTypes.UpdateUnternehmensthema](state: State, unternehmensthema: Unternehmensthema): void
  [MutationTypes.DeleteUnternehmensthema](state: State, unternehmensthemaid: number): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetUnternehmensthema](state, unternehmensthema) {
    state.unternehmensthemen = unternehmensthema;
    sessionStorage.setItem("unternehmensthemen", JSON.stringify(state.unternehmensthemen));
  },

  [MutationTypes.AddUnternehmensthema](state, unternehmensthema) {
    state.unternehmensthemen.push(unternehmensthema);
    sessionStorage.setItem("unternehmensthemen", JSON.stringify(state.unternehmensthemen));
  },


  [MutationTypes.UpdateUnternehmensthema](state, unternehmensthema) {
    const oldunternehmensthema = state.unternehmensthemen.find(a => a.id === unternehmensthema.id)
    if (oldunternehmensthema != null) {
      const index = state.unternehmensthemen.indexOf(oldunternehmensthema)
      state.unternehmensthemen.splice(index, 1, unternehmensthema)
    }

    sessionStorage.setItem("unternehmensthemen", JSON.stringify(state.unternehmensthemen));
  },

  [MutationTypes.DeleteUnternehmensthema](state, unternehmensthemaid) {
    const oldunternehmensthema = state.unternehmensthemen.find(a => a.id === unternehmensthemaid)
    if (oldunternehmensthema != null) {
      const index = state.unternehmensthemen.indexOf(oldunternehmensthema)
      state.unternehmensthemen.splice(index, 1)
    }

    sessionStorage.setItem("unternehmensthemen", JSON.stringify(state.unternehmensthemen));
  }
}