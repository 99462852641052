<template>
  <div class="d-flex justify-content-between align-item-center">
    <button class="btn btn-outline-secondary mb-2" @click="router.back()"><font-awesome-icon icon="chevron-left" /> Zurück</button>

    <button v-if="umfrage != null || unternehmen != null" class="btn" @click="loadLonglist()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div v-if="umfrage == null || unternehmen == null" class="alert alert-danger">
    <p>Die Umfrage oder das Unternehmen konnte nicht gefunden werden.</p>
  </div>

  <div v-else>
    <div class="d-flex justify-content-between align-item-center">
      <div>
        <h1 class="mb-0">Stakeholder Zurordnung</h1>
        <small>{{ unternehmen.firmenname }} - {{ umfrage.titel }}</small>
      </div>
        
      <div class="dropdown">
        <button class="btn btn-outline-success dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><font-awesome-icon icon="file-arrow-down" class="me-1" /> Excel Export</button>
        <ul class="dropdown-menu">
          <li class="dropdown-item export-dropdown-item" @click="exportData('gesamt')">Alle Themen</li>
          <li class="dropdown-item export-dropdown-item" @click="exportData('insideout')">Inside-Out Themen</li>
          <li class="dropdown-item export-dropdown-item" @click="exportData('outsidein')">Outside-In Themen</li>
        </ul>
      </div>
    </div>

    <hr class="my-4">

    <div v-for="longlist in umfrage.longlist" :key="longlist.id" class="mb-3">
      <LongListStakeholderRow :longlist="longlist" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { writeFile as XLSXwriteFile,  utils as XLSXutils } from "xlsx";

import LongListStakeholderRow from '@/components/Umfrage/LongListStakeholderRow.vue';

import { ActionTypes as UmfrageActionTypes } from '@/store/modules/Umfrage/actions';

import { Umfrage, LongList } from '@/models/UmfrageModels';
import { Unternehmen } from '@/models/UnternehmenModels';
import { Stakeholder } from '@/models/StakeholderModels';
import { Fragenkatalog } from '@/models/FragenkatalogModels';
import { Unternehmensthema } from '@/models/UnternehmensthemenModels';

export default defineComponent({
  name: 'UmfrageStakeholderZuordnungView',

  components: {
    LongListStakeholderRow,
  },

  props: {
    unternehmenguid: {
      type: String as PropType<string>,
      required: true
    },

    umfrageguid: {
      type: String as PropType<string>,
      required: true
    },
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()

    const umfrage = computed<Umfrage>(() => store.getters.umfragen.find((u: Umfrage) => u.guid == props.umfrageguid))
    const unternehmen = computed<Unternehmen>(() => store.getters.unternehmenliste.find((u: Unternehmen) => u.guid == props.unternehmenguid))
    
    const fragenkatalog = computed<Array<Fragenkatalog>>(() => store.getters.fragenkatalog)
    const unternehmensthemen = computed<Array<Unternehmensthema>>(() => store.getters.unternehmensthemen)
    const stakeholder = computed<Array<Stakeholder>>(() => store.getters.stakeholder)

    function loadLonglist() {
      store.dispatch(UmfrageActionTypes.GetUmfrageLonglist, props.umfrageguid);
    }

    return {
      router,

      umfrage,
      unternehmen,
      
      fragenkatalog,
      unternehmensthemen,
      stakeholder,

      loadLonglist,
    };
  },

  methods: {
    exportAsExcel(rows: any, docNameSuffix: string = "data") {
      /* generate worksheet and workbook */
      const worksheet = XLSXutils.json_to_sheet(rows);
      const workbook = XLSXutils.book_new();
      XLSXutils.book_append_sheet(workbook, worksheet, "Data");

      /* create an XLSX file and try to save to Presidents.xlsx */
      XLSXwriteFile(workbook, `${this.umfrage.guid}-Longlist-Stakeholder-${docNameSuffix}.xlsx`);
    },

    exportData(value: string = "gesamt") {
      // value: "gesamt" | "insideout" | "outsidein"

      const rows = [];

      for (var item of this.umfrage.longlist) {
        const row = this.getExcelRow(item)
        rows.push(row)
      }

      if (value == "gesamt") {
        this.exportAsExcel(rows, "gesamt")
      }
      else if (value == "insideout") {
        const rowsInsideOut = rows.filter((row: any) => row.InsideOut > 0 || row.BothWays > 0)
        this.exportAsExcel(rowsInsideOut, "insideout")
      }
      else if (value == "outsidein") {
        const rowsOutsideIn = rows.filter((row: any) => row.OutsideIn > 0 || row.BothWays > 0)
        this.exportAsExcel(rowsOutsideIn, "outsidein")
      }
    },

    getExcelRow(item: LongList): any {
      let thema = "";
      let quelle = "";
      let aspekt = "";
      let kategorie = "";

      if (item.frageid != null) {
        const frage = this.fragenkatalog.find((f: any) => f.id == item.frageid)
        if (frage != null) {
          thema = frage.nr +  " " + frage.frageDE;
          quelle = frage.quelle;
          aspekt = frage.aspektDE;
          kategorie = frage.themaDE;
        }
      }
      else if (item.themaid != null) {
        const themaItem = this.unternehmensthemen.find((t: any) => t.id == item.themaid)
        if (themaItem != null) {
          thema = themaItem.thema;
          kategorie = themaItem.kategorie;
        }
      }

      const itemStakeholder = this.stakeholder.filter((s: Stakeholder) => item.stakeholder.map(is => is.stakeholderid).includes(s.id));

      const row = {
        Thema: thema,
        Quelle: quelle,
        Aspekt: aspekt,
        Kategorie: kategorie,
        Zustimmung: item.zustimmung,
        Relevant: item.relevant,
        NichtRelevant: item.nichtRelevant,
        InsideOut: item.insideOut,
        OutsideIn: item.outsideIn,
        BothWays: item.bothWays,
        RichtungDontKnow: item.richtungDontKnow,
        ShortTerm: item.shortTerm,
        MediumTerm: item.mediumTerm,
        LongTerm: item.longTerm,
        TermDontKnow: item.termDontKnow,
        Stakeholder: itemStakeholder.map(is => is.name).join(", ")
      }

      return row;
    }
  },
});
</script>

<style scoped>
.export-dropdown-item {
  cursor: pointer;
}
</style>