import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { Umfrage, LongList } from '@/models/UmfrageModels';


export type Getters = {
  umfragen(state: State): Array<Umfrage>,
  longlist(state: State): Array<LongList>
}

export const getters: GetterTree<State, RootState> & Getters = {
  umfragen(state) {
    return state.umfragen;
  },

  longlist(state) {
    return state.umfragen.map(a => a.longlist).flat();
  }
}