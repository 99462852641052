<template>
  <div class="mb-3 bg-white shadow rounded">
    <div class="row py-2 mx-1">
      <div class="col-6 my-auto border-end cut-text" data-bs-toggle="tooltip" data-bs-placement="top" :title="titel">{{ titel }}</div>

      <div class="col-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            :name="'bewertung-' + longlist.id"
            :id="'bewertung-inside-out-positiv-' + longlist.id"
            :value="1"
            v-model="longlist.outsideInBewertung"
            @change="resetBewertung()"
          />
          <label class="form-check-label" :for="'bewertung-inside-out-positiv-' + longlist.id"> Chance </label>
        </div>
      </div>

      <div class="col-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            :name="'bewertung-' + longlist.id"
            :id="'bewertung-inside-out-negativ-' + longlist.id"
            :value="2"
            v-model="longlist.outsideInBewertung"
            @change="resetBewertung()"
          />
          <label class="form-check-label" :for="'bewertung-inside-out-negativ-' + longlist.id"> Risiko </label>
        </div>
      </div>
    </div>

    <!-- CHANCE -->
    <div v-if="longlist.outsideInBewertung == 1" class="p-2 mx-1">
      <RadioSelect
        v-model="longlist.outsideInChanceAuswirkung"
        title="Auswirkung"
        :keyValues="oneToSixRadioSelect"
        :name="'auswirkung-chance-' + longlist.id"
        beschreibung="klein - groß"
      />
      <RadioSelect
        v-model="longlist.outsideInChanceWahrscheinlichkeit"
        title="Wahrscheinlichkeit"
        :keyValues="wahrscheinlichkeitSelect"
        :name="'wahrscheinlichkeit-chance-' + longlist.id"
        beschreibung="Die Wahrscheinlichkeit ist"
      />
    </div>

    <!-- RISIKO -->
    <div v-else-if="longlist.outsideInBewertung == 2" class="p-2 mx-1">
      <RadioSelect
        v-model="longlist.outsideInRisikoAuswirkung"
        title="Auswirkung"
        :keyValues="oneToSixRadioSelect"
        :name="'auswirkung-risiko-' + longlist.id"
        beschreibung="klein - groß"
      />
      <RadioSelect
        v-model="longlist.outsideInRisikoWahrscheinlichkeit"
        title="Wahrscheinlichkeit"
        :keyValues="wahrscheinlichkeitSelect"
        :name="'wahrscheinlichkeit-risiko-' + longlist.id"
        beschreibung="Die Wahrscheinlichkeit ist"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { PropType, computed, onMounted } from 'vue';
  import { useStore } from 'vuex';

  import { ActionTypes as FragenkatalogActionTypes } from '@/store/modules/Fragenkatalog/actions';

  import RadioSelect from '@/components/FormInputs/RadioSelect.vue';

  import { LongList } from '@/models/UmfrageModels';
  import { Fragenkatalog } from '@/models/FragenkatalogModels';
  import { Unternehmensthema } from '@/models/UnternehmensthemenModels';
  import RadioSelectKeyValue from '@/components/FormInputs/FormClasses';

  const props = defineProps({
    longlist: {
      type: Object as PropType<LongList>,
      required: true,
    },
  });

  const store = useStore();

  const titel = computed(() => {
    if (props.longlist.frageid != null) {
      const frage = store.getters.fragenkatalog.find((f: Fragenkatalog) => f.id == props.longlist.frageid);
      return `${frage.nr}. ${frage.frageDE}`;
    } else if (props.longlist.themaid != null) {
      const thema = store.getters.unternehmensthemen.find((t: Unternehmensthema) => t.id == props.longlist.themaid);
      return `${thema.kategorie} - ${thema.thema}`;
    } else {
      return 'unbekannt';
    }
  });

  function resetBewertung() {
    props.longlist.outsideInChanceAuswirkung = 0;
    props.longlist.outsideInChanceWahrscheinlichkeit = 0;

    props.longlist.outsideInRisikoAuswirkung = 0;
    props.longlist.outsideInRisikoWahrscheinlichkeit = 0;

    props.longlist.outsideInBewertungWert = 0;
  }

  const oneToSixRadioSelect = computed(() => {
    const options = new Array<RadioSelectKeyValue>();
    options.push(new RadioSelectKeyValue('1', 1));
    options.push(new RadioSelectKeyValue('2', 2));
    options.push(new RadioSelectKeyValue('3', 3));
    options.push(new RadioSelectKeyValue('4', 4));
    options.push(new RadioSelectKeyValue('5', 5));
    options.push(new RadioSelectKeyValue('6', 6));

    // options.push(new RadioSelectKeyValue('sehr gering', 1));
    // options.push(new RadioSelectKeyValue('gering', 2));
    // options.push(new RadioSelectKeyValue('moderat - begrenzte Wirkung', 3));
    // options.push(new RadioSelectKeyValue('obere Mitte - spürbare Wirkung', 4));
    // options.push(new RadioSelectKeyValue('hoch', 5));
    // options.push(new RadioSelectKeyValue('sehr hoch', 6));

    return options;
  });

  const wahrscheinlichkeitSelect = computed(() => {
    const options = new Array<RadioSelectKeyValue>();
    options.push(new RadioSelectKeyValue('gering', 0.2));
    options.push(new RadioSelectKeyValue('eventuell', 0.4));
    options.push(new RadioSelectKeyValue('wahrscheinlich', 0.6));
    options.push(new RadioSelectKeyValue('hoch', 0.8));
    options.push(new RadioSelectKeyValue('sehr hoch', 0.95));
    options.push(new RadioSelectKeyValue('sicher', 1));

    return options;
  });

  onMounted(() => {
    if (store.getters.fragenkatalog.length == 0) {
      store.dispatch(FragenkatalogActionTypes.GetFragenkatalog, undefined);
    }
  });
</script>
