<template>
  <div class="row py-2 mb-2 mx-1 rounded">
    <div class="col-4 bg-white shadow-sm p-3"><h3>{{ titel }}</h3></div>
    <div class="col border-top border-end border-bottom pt-2">

      <StakeholderRow v-for="stakeholder in longlist.stakeholder" :key="stakeholder.id" :stakeholder="getStakeholderById(stakeholder.stakeholderid)" class="mb-2"/>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

import { ActionTypes as FragenkatalogActionTypes } from '@/store/modules/Fragenkatalog/actions';

import StakeholderRow from '../Stakeholder/StakeholderRow.vue';

import { LongList } from '@/models/UmfrageModels';
import { Fragenkatalog } from '@/models/FragenkatalogModels';
import { Stakeholder } from '@/models/StakeholderModels';
import { Unternehmensthema } from '@/models/UnternehmensthemenModels';

export default defineComponent({
  name: "LongListRow",

  components: {
    StakeholderRow
  },

  props: {
    longlist: {
      type: Object as PropType<LongList>,
      required: true
    },
  },

  setup(props) {
    const store = useStore()

    const stakeholder = computed<Array<Stakeholder>>(() => store.getters.stakeholder)

    const titel = computed(() => {
      if (props.longlist.frageid != null) {
        const frage = store.getters.fragenkatalog.find((f: Fragenkatalog) => f.id == props.longlist.frageid);
        return `${frage.nr}. ${frage.frageDE}`;
      }
      else if (props.longlist.themaid != null) {
        const thema = store.getters.unternehmensthemen.find((t: Unternehmensthema) => t.id == props.longlist.themaid);
        return `${thema.kategorie} - ${thema.thema}`;
      }
      else {
        return "unbekannt";
      }
      
    })

    function getStakeholderById(stakeholderId: number): Stakeholder {
      const data = stakeholder.value.find((s: any) => s.id == stakeholderId)
      if (data != null) {
        return data;
      }
      else {
        const tmp = new Stakeholder();
        tmp.name = 'Unbekannt';
        return tmp;
      }
    }

    return {
      store,
      titel,
      getStakeholderById
    }
  },

  mounted () {
    if (this.store.getters.fragenkatalog.length == 0) {
      this.store.dispatch(FragenkatalogActionTypes.GetFragenkatalog, undefined);
    }
  }
})
</script>