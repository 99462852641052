import { MutationTree } from "vuex"
import { State } from "./state"
import { TeilnehmerAntwort } from "@/models/TeilnehmerAntwortModels"

export enum MutationTypes {
  SetTeilnehmerantworten = "SETTEILNEHMERANTWORTEN",
  AddTeilnehmerantworten = "ADDTEILNEHMERANTWORTEN",
  AddTeilnehmerantwortenRange = "ADDTEILNEHMERANTWORTENRANGE",
  UpdateTeilnehmerantworten = "UPDATETEILNEHMERANTWORTEN",
  UpdateOrAddTeilnehmerantwortenRange = "UPDATETEILNEHMERANTWORTENRANGE",
  DeleteTeilnehmerantworten = "DELETETEILNEHMERANTWORTEN",
  RemoveTeilnehmerantwortenByTeilnehmerguid = "REMOVETEILNEHMERANTWORTENBYTEILNEHMERGUID"
}

export type Mutations = {
  [MutationTypes.SetTeilnehmerantworten](state: State, teilnehmerantwort: Array<TeilnehmerAntwort>): void
  [MutationTypes.AddTeilnehmerantworten](state: State, teilnehmerantwort: TeilnehmerAntwort): void
  [MutationTypes.AddTeilnehmerantwortenRange](state: State, teilnehmerantwort: Array<TeilnehmerAntwort>): void
  [MutationTypes.UpdateTeilnehmerantworten](state: State, teilnehmerantwort: TeilnehmerAntwort): void
  [MutationTypes.UpdateOrAddTeilnehmerantwortenRange](state: State, teilnehmerantworten: Array<TeilnehmerAntwort>): void
  [MutationTypes.DeleteTeilnehmerantworten](state: State, teilnehmerantwortid: number): void
  [MutationTypes.RemoveTeilnehmerantwortenByTeilnehmerguid](state: State, teilnehmerguid: string): void
}

export const mutations: MutationTree<State> & Mutations = {

  [MutationTypes.SetTeilnehmerantworten](state, teilnehmerantwort) {
    state.teilnehmerantworten = teilnehmerantwort;
    sessionStorage.setItem("teilnehmerantworten", JSON.stringify(state.teilnehmerantworten));
  },

  [MutationTypes.AddTeilnehmerantworten](state, teilnehmerantwort) {
    state.teilnehmerantworten.push(teilnehmerantwort);
    sessionStorage.setItem("teilnehmerantworten", JSON.stringify(state.teilnehmerantworten));
  },

  [MutationTypes.AddTeilnehmerantwortenRange](state, teilnehmerantwort) {
    state.teilnehmerantworten.push(...teilnehmerantwort);
    sessionStorage.setItem("teilnehmerantworten", JSON.stringify(state.teilnehmerantworten));
  },

  [MutationTypes.UpdateTeilnehmerantworten](state, teilnehmerantwort) {
    const oldteilnehmerantwort = state.teilnehmerantworten.find(a => a.id === teilnehmerantwort.id)
    if (oldteilnehmerantwort != null) {
      const index = state.teilnehmerantworten.indexOf(oldteilnehmerantwort)
      state.teilnehmerantworten.splice(index, 1, teilnehmerantwort)
    }
    else {
      state.teilnehmerantworten.push(teilnehmerantwort)
    }

    sessionStorage.setItem("teilnehmerantworten", JSON.stringify(state.teilnehmerantworten));
  },

  [MutationTypes.UpdateOrAddTeilnehmerantwortenRange](state, teilnehmerantworten) {
    for (var teilnehmerantwort of teilnehmerantworten) {
      const oldteilnehmerantwort = state.teilnehmerantworten.find(a => a.id === teilnehmerantwort.id)
      if (oldteilnehmerantwort != null) {
        const index = state.teilnehmerantworten.indexOf(oldteilnehmerantwort)
        state.teilnehmerantworten.splice(index, 1, teilnehmerantwort)
      }
      else {
        state.teilnehmerantworten.push(teilnehmerantwort)
      }
    }

    sessionStorage.setItem("teilnehmerantworten", JSON.stringify(state.teilnehmerantworten));
  },

  [MutationTypes.DeleteTeilnehmerantworten](state, teilnehmerantwortid) {
    const oldteilnehmerantwort = state.teilnehmerantworten.find(a => a.id === teilnehmerantwortid)
    if (oldteilnehmerantwort != null) {
      const index = state.teilnehmerantworten.indexOf(oldteilnehmerantwort)
      state.teilnehmerantworten.splice(index, 1)
    }

    sessionStorage.setItem("teilnehmerantworten", JSON.stringify(state.teilnehmerantworten));
  },

  [MutationTypes.RemoveTeilnehmerantwortenByTeilnehmerguid](state, teilnehmerguid) {
    state.teilnehmerantworten = state.teilnehmerantworten.filter(ta => ta.teilnehmerGuid != teilnehmerguid);

    sessionStorage.setItem("teilnehmerantworten", JSON.stringify(state.teilnehmerantworten));
  }
}