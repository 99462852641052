<template>
<div class="row py-2 mb-2 mx-1 bg-white shadow-sm rounded">
    <div class="col-4 my-auto">{{ unternehmen.firmenname }}</div>
    <div class="col-3 my-auto">{{ unternehmen.firmennummer }}</div>
    <div class="col-3 my-auto">{{ unternehmen.ansprechpartner }}</div>
    <div class="col-2 my-auto">
      <router-link :to="{name: 'DetailsUnternehmen', params: { guid: unternehmen.guid }}" class="btn btn-primary btn-sm m-0 float-end">
        <font-awesome-icon icon="chevron-right"  />
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { Unternehmen } from '@/models/UnternehmenModels';

export default defineComponent({
  name: "UnternehmenRow",

  props: {
    unternehmen: {
      type: Object as PropType<Unternehmen>,
      required: true
    }
  },
})
</script>

<style scoped>
.unternehmen-row:hover {
  cursor: pointer;
  /* color: #2A579B; */
  background-color: #ebf5d6 !important;
}
</style>