<template>
  <div>
    <canvas ref="canvasRef"></canvas>
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, PropType, computed, ref } from 'vue';
  import { useStore } from '@/store';

  import { Chart, registerables } from 'chart.js';
  import { ChartData, ChartOptions } from 'chart.js';

  import { LongList } from '@/models/UmfrageModels';
  import { Fragenkatalog } from '@/models/FragenkatalogModels';
  import { Unternehmensthema } from '@/models/UnternehmensthemenModels';

  interface DataPoint {
    x: number;
    y: number;
    r: number;
    label: string;
  }

  interface Dataset {
    label: string;
    data: Array<DataPoint>;
    borderColor: string;
    backgroundColor: string;
  }

  const props = defineProps({
    data: {
      type: Array as PropType<Array<LongList>>,
      requiered: true,
    },
    title: {
      type: String as PropType<string>,
      default: 'Ergebnis',
    },
  });

  const store = useStore();

  function getTitel(item: LongList): string {
    if (item.frageid != null) {
      const frage = store.getters.fragenkatalog.find((f: Fragenkatalog) => f.id == item.frageid);

      if (frage == undefined) {
        return 'unbekannt';
      }

      return `${frage.nr}. ${frage.frageDE}`;
    } else if (item.themaid != null) {
      const thema = store.getters.unternehmensthemen.find((t: Unternehmensthema) => t.id == item.themaid);

      if (thema == undefined) {
        return 'unbekannt';
      }

      return `${thema.kategorie} - ${thema.thema}`;
    } else {
      return 'unbekannt';
    }
  }

  Chart.register(...registerables);

  // Chart instance
  const chartInstance = ref<Chart | null>(null);
  const canvasRef = ref<HTMLCanvasElement>();

  //#region Data
  const positivData = computed<Dataset>(() => {
    const dataset: Dataset = {
      label: 'Positiv/Chance',
      data: [],
      borderColor: 'rgba(0, 128, 0, 1)',
      backgroundColor: 'rgba(0, 128, 0, 0.5)',
    };

    props.data
      ?.filter(
        (l: LongList) =>
          (l.insideOutBewertung == 1 && l.outsideInBewertung == 0) ||
          (l.insideOutBewertung == 0 && l.outsideInBewertung == 1) ||
          (l.insideOutBewertung == 1 && l.outsideInBewertung == 1)
      )
      .forEach((l: LongList) => {
        dataset.data.push({
          x: l.insideOutBewertungWert,
          y: l.outsideInBewertungWert,
          r: l.zustimmung / 10,
          label: getTitel(l),
        });
      });

    return dataset;
  });

  const negativData = computed<Dataset>(() => {
    const dataset: Dataset = {
      label: 'Negativ/Risiko',
      data: [],
      borderColor: 'rgba(255, 0, 0, 1)',
      backgroundColor: 'rgba(255, 0, 0, 0.5)',
    };

    props.data
      ?.filter(
        (l: LongList) =>
          (l.insideOutBewertung == 2 && l.outsideInBewertung == 0) ||
          (l.insideOutBewertung == 0 && l.outsideInBewertung == 2) ||
          (l.insideOutBewertung == 2 && l.outsideInBewertung == 2)
      )
      .forEach((l: LongList) => {
        dataset.data.push({
          x: l.insideOutBewertungWert,
          y: l.outsideInBewertungWert,
          r: l.zustimmung / 10,
          label: getTitel(l),
        });
      });

    return dataset;
  });

  const positivAndNegativData = computed<Dataset>(() => {
    const dataset: Dataset = {
      label: 'Positiv & Risiko / Negativ & Chance',
      data: [],
      borderColor: 'rgba(255, 165, 0, 1)',
      backgroundColor: 'rgba(255, 165, 0, 0.5)',
    };

    props.data
      ?.filter((l: LongList) => (l.insideOutBewertung == 1 && l.outsideInBewertung == 2) || (l.insideOutBewertung == 2 && l.outsideInBewertung == 1))
      .forEach((l: LongList) => {
        dataset.data.push({
          x: l.insideOutBewertungWert,
          y: l.outsideInBewertungWert,
          r: l.zustimmung / 10,
          label: getTitel(l),
        });
      });

    return dataset;
  });
  //#endregion

  const data: ChartData<'bubble'> = {
    datasets: [positivData.value, negativData.value, positivAndNegativData.value],
  };

  const options: ChartOptions<'bubble'> = {
    responsive: false,
    scales: {
      x: {
        // min: 0,
        // max: 55,
        title: {
          display: true,
          text: 'Inside-Out',
        },
      },
      y: {
        // min: 0,
        // max: 30,
        title: {
          display: true,
          text: 'Outside-In',
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: props.title,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return (context?.raw as DataPoint).label;
          },
        },
      },
    },
  };

  onMounted(() => {
    if (canvasRef.value != null) {
      chartInstance.value = new Chart(canvasRef.value, {
        type: 'bubble',
        data,
        options,
      });
    }
  });
</script>

<style scoped>
  canvas {
    display: block;
    margin: auto;
    width: 800px;
    height: 400px;
  }
</style>
