<template>
  <button class="btn btn-outline-secondary mb-2" @click="cancel()"><font-awesome-icon icon="chevron-left" /> Zurück</button>

  <div v-if="umfrage == null" class="alert alert-danger">
    <p>Die Umfrage konnte nicht gefunden werden.</p>
  </div>

  <div v-else>
    <h1>Outside-In Themen Priorisierung</h1>

    <div class="mb-3">
      <label for="inside-out-schwellwert" class="form-label">Outside-In Schwellenwert</label>
      <input type="number" class="form-control" id="inside-out-schwellwert" v-model="schwellenwert" />
    </div>

    <hr class="my-4" />
    <h2>Ausgewählte Themen</h2>

    <div class="row mx-1">
      <div class="col-6 fw-bold">Thema</div>
      <div class="col-2 fw-bold">Zustimmung</div>
      <div class="col-4 fw-bold">Bewertung</div>
    </div>

    <OutsideInLongListRow
      v-for="thema in umfrage.longlist.filter((item: LongList) => item.outsideInBewertungWert >= schwellenwert && (item.outsideIn == 1 || item.bothWays == 1))"
      :key="thema.id"
      :longlist="thema"
    />

    <div v-if="umfrage.longlist.filter((i: LongList) => i.outsideInBewertungWert < schwellenwert && (i.outsideIn == 1 || i.bothWays == 1)).length > 0">
      <hr class="my-4" />
      <h2>Nicht-Ausgewählte Themen</h2>

      <div class="row mx-1">
        <div class="col-6 fw-bold">Thema</div>
        <div class="col-2 fw-bold">Zustimmung</div>
        <div class="col-4 fw-bold">Bewertung</div>
      </div>

      <OutsideInLongListRow
        v-for="thema in umfrage.longlist.filter((item: LongList) => item.outsideInBewertungWert < schwellenwert && (item.outsideIn == 1 || item.bothWays == 1))"
        :key="thema.id"
        :longlist="thema"
      />
    </div>

    <div class="position-fixed bottom-0 end-0 p-3 w-100 bg-white shadow border-top d-flex justify-content-end">
      <button @click="cancel()" class="btn btn-secondary">Abbrechen</button>
      <button @click="updateUmfrage()" class="btn btn-primary ms-3">Speichern</button>
    </div>
  </div>

  <LoadingOverlay v-if="loading" />
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, computed, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';

  import { LongList, Umfrage } from '@/models/UmfrageModels';

  import { ActionTypes as UmfrageActionTypes } from '@/store/modules/Umfrage/actions';

  import OutsideInLongListRow from '@/components/Umfrage/ThemenBewertung/OutsideInLongListRow.vue';
  import LoadingOverlay from '@/components/LoadingOverlay.vue';
  //#endregion

  const store = useStore();
  const router = useRouter();

  const props = defineProps({
    unternehmenguid: {
      type: String as PropType<string>,
      required: true,
    },

    umfrageguid: {
      type: String as PropType<string>,
      required: true,
    },
  });

  const loading = computed(() => store.getters.status.updateUmfrageLoading);
  const errorMsg = computed(() => store.getters.status.umfrageErrorMsg);

  const umfrage = computed(() => store.getters.umfragen.find((u: Umfrage) => u.guid == props.umfrageguid));
  const schwellenwert = ref(umfrage.value?.outsideInWert);

  async function updateUmfrage() {
    umfrage.value.outsideInWert = schwellenwert.value;

    store.dispatch(UmfrageActionTypes.UpdateUmfrage, umfrage.value).then(() => {
      if (errorMsg.value == '') {
        router.back();
      }
    });
  }

  function cancel() {
    router.back();
  }
</script>
