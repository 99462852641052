import { Unternehmen } from "@/models/UnternehmenModels"

export type State = {
  unternehmen: Unternehmen
  unternehmenliste: Array<Unternehmen>
}

export const state: State = {
  unternehmen: sessionStorage.getItem("unternehmen") != null ? JSON.parse(sessionStorage.unternehmen) : new Unternehmen(),
  unternehmenliste: localStorage.getItem("unternehmenliste") != null ? JSON.parse(localStorage.unternehmenliste) : new Array<Unternehmen>(),
}
