<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm umfrage-row rounded">
    <div class="col-3 my-auto">{{ thema.kategorie }}</div>
    <div class="col-3 my-auto">{{ thema.thema }}</div>
    <div class="col-6 my-auto text-end">
      <router-link :to="{name: 'DetailsUnternehmensthema', params: { unternehmensthemaid: thema.id.toString()}}" class="btn btn-primary btn-sm">
        <font-awesome-icon icon="magnifying-glass-plus" />
      </router-link>
    </div>
  </div>
</template>
  
<script lang="ts">
  import { PropType, defineComponent, computed } from 'vue';
import { Unternehmensthema } from '@/models/UnternehmensthemenModels';

  export default defineComponent({
    name: "UnternehmensthemaRow",
  
    props: {
      thema: {
        type: Object as PropType<Unternehmensthema>,
        required: true
      }
    },
  })
  </script>
  
  <style scoped>
  /* .umfrage-row:hover {
    cursor: pointer;
    background-color: #ebf5d6 !important;
  } */
  </style>