import {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import { AuthUser } from "@/models/AuthModels";

import { useStore } from '@/store'
import router from "@/router"

import { ErrorResponseData } from "@/models/CommonModels";

import { ActionTypes as AuthActionTypes } from "@/store/modules/Auth/actions";

let axiosInst: AxiosInstance;


const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  // console.info(`[request] [${JSON.stringify(config)}]`);

  if (config.headers === undefined) {
    config.headers = {};
  }

  const authUser: AuthUser = localStorage.getItem("authUser") != null ? JSON.parse(localStorage.authUser) : null;

  if (authUser != null && authUser.token != "") {
    config.headers.Authorization = `Bearer ${authUser.token}`;
  }

  return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
}

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}


// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];
// Flag to prevent multiple token refresh requests
let isRefreshing = false;

const onResponseError = async (error: AxiosError): Promise<any> => {
  // return Promise.reject(error);

  const originalRequest: AxiosRequestConfig = error.config;
  const store = useStore();

  console.log(error.response);
  if (error.response && error.response.status == 401) {
    if (error.response.data != "") {
      const err = error.response?.data as ErrorResponseData
      if (err != null && err.title.toLowerCase() == "ungültige anmeldedaten") {
        return Promise.reject(error);
      }
    }

    if (!isRefreshing) {
      isRefreshing = true;
      
      try {
        await store.dispatch(AuthActionTypes.Refresh, undefined)
        const authUser = store.getters.authUser;

        if (authUser  != null) {
          const newAccessToken = authUser.token;
          
          if (error.config.headers) {
            error.config.headers['Authorization'] = "Bearer " + newAccessToken
          }

          // Retry all requests in the queue with the new token
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            axiosInst
            .request(config)
            .then((response: AxiosResponse) => resolve(response))
            .catch((err: AxiosError) => reject(err));
          });
  
          // Clear the queue
          refreshAndRetryQueue.length = 0;
          
          return axiosInst(originalRequest)
        }
      }
      catch (error) {
        store.dispatch(AuthActionTypes.Logout, undefined)
        .then(() => {
          router.replace({ name: "Logout" })
          return true
        })
      }
      finally {
        isRefreshing = false;
      }
      isRefreshing = false;
    }

    // Add the original request to the queue
    return new Promise<any>((resolve, reject) => {
      refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
    });
  }

  else if (error.response?.status == 403) {
    const errorRespData = error.response.data as ErrorResponseData || null;
    if (errorRespData != null && errorRespData.detail == "Die Anmeldung ist abgelaufen") {
      store.dispatch(AuthActionTypes.Logout, undefined)
      .then(() => {
        router.replace({ name: "Logout" })
        return true;
      })
    }
  }

  return Promise.reject(error);
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  axiosInst = axiosInstance
  return axiosInstance;
}
