<template>
  <div class="d-flex justify-content-between">
    <h1>Unternehmen</h1>

    <button class="btn" @click="loadUnternehmen()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="d-flex">
    <router-link :to="{ name: 'EditUnternehmen' }" class="btn btn-secondary btn-sm"><font-awesome-icon icon="plus" /> Hinzufügen</router-link>
  </div>

  <div v-if="!loading && errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Laden der Unternehmen aufgetreten. Bitte probieren Sie es später erneut.
  </div>

  <div v-if="unternehmen.length > 0" class="mt-5">
    <div class="row mx-1 mb-2">
      <div class="col-4"><strong>Firmenname</strong></div>
      <div class="col-3"><strong>Firmennummer</strong></div>
      <div class="col-3"><strong>Ansprechpartner</strong></div>
      <div class="col-2"></div>
    </div>
    <div v-for="company in unternehmen" :key="company.guid">
      <UnternehmenRow :unternehmen="company" />
    </div>
  </div>

  <div v-else-if="!loading && errorMsg == '' && unternehmen.length == 0" class="alert alert-info mt-5">Es wurden keine Unternehmen gefunden.</div>

  <LoadingOverlay v-if="loading" text="... Unternehmen werden geladen ..." />
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import { ActionTypes as UnternehmenActionTypes } from '@/store/modules/Unternehmen/actions';
  import { ActionTypes as StakeholderActionTypes } from '@/store/modules/Stakeholder/actions';

  import UnternehmenRow from '@/components/Unternehmen/UnternehmenRow.vue';
  import LoadingOverlay from '@/components/LoadingOverlay.vue';

  export default defineComponent({
    name: 'Unternehmen',

    components: {
      LoadingOverlay,
      UnternehmenRow,
    },

    setup() {
      const store = useStore();

      const loading = computed(() => store.getters.status.getUnternehmenLoading || store.getters.status.getStakeholderLoading);
      const errorMsg = computed(() => store.getters.status.unternehmenErrorMsg);

      const unternehmen = computed(() => store.getters.unternehmenliste);

      function loadUnternehmen() {
        store.dispatch(UnternehmenActionTypes.GetUnternhemenliste, undefined);

        if (store.getters.stakeholder.length == 0) {
          store.dispatch(StakeholderActionTypes.GetStakeholder, undefined);
        }
      }

      return {
        store,
        loading,
        errorMsg,
        unternehmen,
        loadUnternehmen,
      };
    },

    mounted() {
      this.loadUnternehmen();
    },
  });
</script>
