import { MutationTree } from "vuex"
import { State } from "./state"
import { Unternehmen } from '@/models/UnternehmenModels';

export enum MutationTypes {
  SetUnternehmen = "SETUNTERNEHMEN",

  SetUnternehmenliste = "SETUNTERNEHMENLISTE",
  AddUnternehmenliste = "ADDUNTERNEHMENLISTE",
  UpdateUnternehmenliste = "UPDATEUNTERNEHMENLISTE",
  DeleteUnternehmenliste = "DELETEUNTERNEHMENLISTE"
}

export type Mutations = {
  [MutationTypes.SetUnternehmen](state: State, unternehmen: Unternehmen): void

  [MutationTypes.SetUnternehmenliste](state: State, unternehmenliste: Array<Unternehmen>): void
  [MutationTypes.AddUnternehmenliste](state: State, unternehmen: Unternehmen): void
  [MutationTypes.UpdateUnternehmenliste](state: State, unternehmen: Unternehmen): void
  [MutationTypes.DeleteUnternehmenliste](state: State, unternehmenguid: string): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetUnternehmen](state, unternehmen) {
    state.unternehmen = unternehmen;
    sessionStorage.setItem("unternehmen", JSON.stringify(state.unternehmen));
  },

  [MutationTypes.SetUnternehmenliste](state, unternehmenliste) {
    state.unternehmenliste = unternehmenliste;
    localStorage.setItem("unternehmenliste", JSON.stringify(state.unternehmenliste));
  },

  [MutationTypes.AddUnternehmenliste](state, unternehmen) {
    state.unternehmenliste.push(unternehmen);
    localStorage.setItem("unternehmenliste", JSON.stringify(state.unternehmenliste));
  },

  [MutationTypes.UpdateUnternehmenliste](state, unternehmen) {
    const oldunternehmen = state.unternehmenliste.find(a => a.guid === unternehmen.guid)
    if (oldunternehmen != null) {
      const index = state.unternehmenliste.indexOf(oldunternehmen)
      state.unternehmenliste.splice(index, 1, unternehmen)
    }

    localStorage.setItem("unternehmenliste", JSON.stringify(state.unternehmenliste));
  },

  [MutationTypes.DeleteUnternehmenliste](state, unternehmenguid) {
    const oldunternehmen = state.unternehmenliste.find(a => a.guid === unternehmenguid)
    if (oldunternehmen != null) {
      const index = state.unternehmenliste.indexOf(oldunternehmen)
      state.unternehmenliste.splice(index, 1)
    }

    localStorage.setItem("unternehmenliste", JSON.stringify(state.unternehmenliste));
  }
}