<template>
  <button class="btn btn-outline-secondary mb-2" @click="router.back()"><font-awesome-icon icon="chevron-left" /> Zurück</button>

  <h1 class="mb-0">Long-List erstellen</h1>
  <small>{{ unternehmen.firmenname }} - {{ umfrage.titel }}</small>

  <hr class="my-4" />

  <div>
    <div class="bg-white shadow-sm p-3 mb-5">
      <p><strong>Long-List generieren</strong></p>

      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="select-alle-fragen"
          id="select-alle-fragen"
          :value="1"
          v-model="longlistSelect"
          @change="setLonglistProzent(0)"
        />
        <label class="form-check-label" for="select-alle-fragen"> Alle Fragen übernehmen </label>
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="select-prozent-fragen"
          id="select-prozent-fragen"
          :value="2"
          v-model="longlistSelect"
          @change="setLonglistProzent(100)"
        />
        <label class="form-check-label" for="select-prozent-fragen"> Nur Fragen mit mindestens x% Zustimmung übernehmen </label>
      </div>

      <div v-if="longlistSelect == 2" class="input-group mt-3 ms-4 w-25">
        <input type="number" class="form-control" id="long-list-prozent" v-model="longlistProzent" />
        <span id="long-list-prozent" class="input-group-text">%</span>
      </div>

      <button class="btn btn-primary mt-3" @click="longlistGenerieren()">Long-List generieren</button>
    </div>

    <div v-if="errorMsg != ''" class="alert alert-danger mb-5">Es ist ein Fehler aufgetreten, bitte probieren Sie es später erneut.</div>

    <div class="row">
      <div class="col-5">
        <strong>Umfrage Ergebnisse</strong>
        <div class="row py-2 mb-2 mx-1">
          <div class="col-5 my-auto">Frage</div>
          <div class="col-2 my-auto">Zustimmung</div>
          <div class="col-2 my-auto">Relevant</div>
          <div class="col-3 my-auto">Nicht Relevant</div>
        </div>

        <LongListRow
          v-for="(item, index) in notSelectedLongListItems.sort((a, b) => b.zustimmung - a.zustimmung)"
          :key="index"
          :longlist="item"
          class="long-list-row-selecte"
          @click="addToSelected(item)"
        />
      </div>

      <div class="col-6 offset-1">
        <strong>Long-List</strong>
        <div class="d-flex justify-content-between align-items-center">
          <div class="row py-2 mb-2 mx-1 flex-fill">
            <div class="col-5 my-auto">Frage</div>
            <div class="col-2 my-auto">Zustimmung</div>
            <div class="col-2 my-auto">Relevant</div>
            <div class="col-3 my-auto">Nicht Relevant</div>
          </div>

          <button type="button" class="btn" @click="resetSelected()" data-bs-toggle="tooltip" data-bs-placement="top" title="Long-List löschen">
            <font-awesome-icon icon="times" />
          </button>
        </div>

        <div
          v-for="(item, index) in selectedLongListItems.sort((a, b) => b.zustimmung - a.zustimmung)"
          :key="index"
          class="d-flex justify-content-between align-items-center"
        >
          <LongListRow :longlist="item" class="flex-fill" />
          <button type="button" class="btn" @click="deleteFromSelected(index)"><font-awesome-icon icon="trash" /></button>
        </div>
      </div>
    </div>

    <pre>{{ deleteLonglistItemsList }}</pre>

    <div
      v-if="selectedLongListItems.length > 0 || deleteLonglistItemsList.length > 0"
      class="position-fixed bottom-0 end-0 p-3 w-100 bg-white shadow border-top d-flex justify-content-end"
    >
      <button class="btn btn-outline-secondary me-2" @click="cancel()">Abbrechen</button>
      <button class="btn btn-primary" @click="saveLongList()">Speichern</button>
    </div>
  </div>

  <LoadingOverlay v-if="teilnehmerantwortenloading || loading" />
</template>

<script lang="ts">
  import { computed, defineComponent, ref, PropType } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { writeFile as XLSXwriteFile, utils as XLSXutils } from 'xlsx';

  import { ActionTypes as UmfrageActionTypes } from '@/store/modules/Umfrage/actions';
  import { ActionTypes as TeilnehmerAntwortActionTypes } from '@/store/modules/TeilnehmerAntwort/actions';
  import { ActionTypes as FragenkatalogActionTypes } from '@/store/modules/Fragenkatalog/actions';

  import LoadingOverlay from '@/components/LoadingOverlay.vue';
  import LongListRow from '@/components/Umfrage/LongListRow.vue';

  import { TeilnehmerAntwort } from '@/models/TeilnehmerAntwortModels';
  import { Umfrage, LongList } from '@/models/UmfrageModels';
  import { Unternehmen } from '@/models/UnternehmenModels';

  export default defineComponent({
    name: 'EditUmfrageLongListView',

    components: {
      LoadingOverlay,
      LongListRow,
    },

    props: {
      unternehmenguid: {
        type: String as PropType<string>,
        required: true,
      },

      umfrageguid: {
        type: String as PropType<string>,
        required: true,
      },
    },

    setup(props) {
      const store = useStore();
      const router = useRouter();

      const loading = computed(() => store.getters.status.createLonglistLoading);
      const errorMsg = computed(() => store.getters.status.longlistErrorMsg);

      const unternehmen = computed(() => store.getters.unternehmenliste.find((u: Unternehmen) => u.guid == props.unternehmenguid));
      const umfrage = computed(() => store.getters.umfragen.find((u: Umfrage) => u.guid == props.umfrageguid));
      const teilnehmerliste = computed(() => store.getters.teilnehmerliste);
      const teilnehmerantworten = computed(() => store.getters.teilnehmerantworten);
      const fragenkatalog = computed(() => store.getters.fragenkatalog);

      const teilnehmerantwortenloading = ref(false);

      function getTeilnehmerantworten() {
        teilnehmerantwortenloading.value = true;
        store.dispatch(TeilnehmerAntwortActionTypes.ClearTeilnehmerantwort, undefined);
        const promises = [];
        for (var teilnehmer of teilnehmerliste.value) {
          const promise = store.dispatch(TeilnehmerAntwortActionTypes.GetTeilnehmerantwortByTeilnehmerguid, teilnehmer.guid);
          promises.push(promise);
        }

        Promise.all(promises).then(() => {
          // All requests have completed
          // console.log("All requests finished successfully.");
          teilnehmerantwortenloading.value = false;
        });
      }

      const availableLongListItems = computed<Array<LongList>>(() => {
        const result = new Array<LongList>();
        for (let frage of fragenkatalog.value) {
          const antworten = teilnehmerantworten.value.filter((ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.beantwortet == 1);

          if (antworten.length > 0) {
            result.push(createLongListItem(antworten, frage.id));
          }
        }

        return result;
      });

      function createLongListItem(antworten: Array<TeilnehmerAntwort>, frageid: number) {
        const anzahlAntworten = antworten.filter((ta: TeilnehmerAntwort) => ta.beantwortet == 1).length;
        const countNichtRelevant = antworten.filter((ta: TeilnehmerAntwort) => ta.nichtRelevant == 1 && ta.beantwortet == 1).length;
        const countRelevant = anzahlAntworten - countNichtRelevant;
        const countInsideOut = antworten.filter((ta: TeilnehmerAntwort) => ta.insideOut == 1 && ta.beantwortet == 1).length;
        const countOutsideIn = antworten.filter((ta: TeilnehmerAntwort) => ta.outsideIn == 1 && ta.beantwortet == 1).length;
        const countBothWays = antworten.filter((ta: TeilnehmerAntwort) => ta.bothWays == 1 && ta.beantwortet == 1).length;
        const countRichtungDontKnow = antworten.filter((ta: TeilnehmerAntwort) => ta.richtungDontKnow == 1 && ta.beantwortet == 1).length;

        const countShortTerm = antworten.filter((ta: TeilnehmerAntwort) => ta.shortTerm == 1 && ta.beantwortet == 1).length;
        const countMediumTerm = antworten.filter((ta: TeilnehmerAntwort) => ta.mediumTerm == 1 && ta.beantwortet == 1).length;
        const countLongTerm = antworten.filter((ta: TeilnehmerAntwort) => ta.longTerm == 1 && ta.beantwortet == 1).length;
        const countTermDontKnow = antworten.filter((ta: TeilnehmerAntwort) => ta.termDontKnow == 1 && ta.beantwortet == 1).length;
        const zustimmung = Math.floor((countRelevant / antworten.length) * 100);

        const longListItem = new LongList(props.umfrageguid, frageid, null, zustimmung);

        longListItem.beantwortet = anzahlAntworten;
        longListItem.relevant = countRelevant;
        longListItem.nichtRelevant = countNichtRelevant;

        longListItem.insideOut = countInsideOut;
        longListItem.outsideIn = countOutsideIn;
        longListItem.bothWays = countBothWays;
        longListItem.richtungDontKnow = countRichtungDontKnow;

        longListItem.shortTerm = countShortTerm;
        longListItem.mediumTerm = countMediumTerm;
        longListItem.longTerm = countLongTerm;
        longListItem.termDontKnow = countTermDontKnow;

        return longListItem;
      }

      const notSelectedLongListItems = computed(() =>
        availableLongListItems.value
          .filter((item: LongList) => !selectedLongListItems.value.map((l) => l.frageid).includes(item.frageid))
          .sort((a, b) => b.zustimmung - a.zustimmung)
      );

      const selectedLongListItems = ref<Array<LongList>>(new Array<LongList>());

      if (umfrage.value != null && umfrage.value.longlist.length > 0) {
        for (var item of umfrage.value.longlist.filter((l: LongList) => l.themaid == null)) {
          if (selectedLongListItems.value.find((s) => s.frageid == item.frageid) == null) {
            addToSelected(item);
          }
          // selectedUnternehmensthemen.value.push(item);
        }
      }

      const deleteLonglistItemsList = ref(new Array<LongList>());

      const longlistSelect = ref(2);
      const longlistProzent = ref(100);

      function setLonglistProzent(prozent: number) {
        longlistProzent.value = prozent;
      }

      function longlistGenerieren() {
        // selectedLongListItems.value = [];
        resetSelected();
        const selectedItems = availableLongListItems.value.filter((item: LongList) => item.zustimmung >= longlistProzent.value);
        selectedLongListItems.value.push(...selectedItems);
      }

      function addToSelected(item: LongList) {
        selectedLongListItems.value.push(item);
      }

      function deleteFromSelected(index: number) {
        var item = selectedLongListItems.value[index];
        if (item.id != 0) {
          deleteLonglistItemsList.value.push(item);
        }

        selectedLongListItems.value.splice(index, 1);
      }

      function resetSelected() {
        for (let item of selectedLongListItems.value) {
          if (item.id != 0) {
            deleteLonglistItemsList.value.push(item);
          }
        }

        selectedLongListItems.value = [];
      }

      async function saveLongList() {
        for (var item of deleteLonglistItemsList.value) {
          await store.dispatch(UmfrageActionTypes.DeleteLonglist, item);
        }

        const requestData = selectedLongListItems.value.filter((l: LongList) => l.id == 0);
        if (requestData.length > 0) {
          store.dispatch(UmfrageActionTypes.CreateLonglistRange, requestData).then(() => {
            if (errorMsg.value == '') {
              cancel();
              // router.push({name: "UnternehmenUmfrage", params: {unternehmenguid: props.unternehmenguid, umfrageguid: props.umfrageguid}})
            }
          });
        } else {
          cancel();
        }
      }

      function cancel() {
        selectedLongListItems.value = [];
        deleteLonglistItemsList.value = [];

        if (umfrage.value != null && umfrage.value.longlist.length > 0) {
          for (var item of umfrage.value.longlist.filter((l: LongList) => l.themaid == null)) {
            if (selectedLongListItems.value.find((s) => s.frageid == item.frageid) == null) {
              addToSelected(item);
            }
            // selectedUnternehmensthemen.value.push(item);
          }
        }

        router.back();
      }

      return {
        store,
        router,

        loading,
        errorMsg,

        unternehmen,
        umfrage,
        teilnehmerliste,
        teilnehmerantworten,
        teilnehmerantwortenloading,
        fragenkatalog,
        getTeilnehmerantworten,

        notSelectedLongListItems,
        selectedLongListItems,
        deleteLonglistItemsList,

        longlistSelect,
        longlistProzent,
        setLonglistProzent,
        longlistGenerieren,

        addToSelected,
        deleteFromSelected,
        resetSelected,

        saveLongList,
        cancel,
      };
    },

    methods: {
      exportAsExcel() {
        const rows = [];

        for (var frage of this.fragenkatalog) {
          const anzahlAntworten = this.teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.beantwortet == 1).length;
          const countNichtRelevant = this.teilnehmerantworten.filter(
            (ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.nichtRelevant == 1 && ta.beantwortet == 1
          ).length;
          const countRelevant = anzahlAntworten - countNichtRelevant;
          const countInsideOut = this.teilnehmerantworten.filter(
            (ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.insideOut == 1 && ta.beantwortet == 1
          ).length;
          const countOutsideIn = this.teilnehmerantworten.filter(
            (ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.outsideIn == 1 && ta.beantwortet == 1
          ).length;
          const countBothWays = this.teilnehmerantworten.filter(
            (ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.bothWays == 1 && ta.beantwortet == 1
          ).length;
          const countRichtungDontKnow = this.teilnehmerantworten.filter(
            (ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.richtungDontKnow == 1 && ta.beantwortet == 1
          ).length;

          const countShortTerm = this.teilnehmerantworten.filter(
            (ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.shortTerm == 1 && ta.beantwortet == 1
          ).length;
          const countMediumTerm = this.teilnehmerantworten.filter(
            (ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.mediumTerm == 1 && ta.beantwortet == 1
          ).length;
          const countLongTerm = this.teilnehmerantworten.filter(
            (ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.longTerm == 1 && ta.beantwortet == 1
          ).length;
          const countTermDontKnow = this.teilnehmerantworten.filter(
            (ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.termDontKnow == 1 && ta.beantwortet == 1
          ).length;

          const row = {
            Id: frage.id,
            Frage: frage.frageDE,
            Relevant: countRelevant,
            NichtRelevant: countNichtRelevant,
            InsideOut: countInsideOut,
            OutsideIn: countOutsideIn,
            BothWays: countBothWays,
            RichtungDontKnow: countRichtungDontKnow,
            ShortTerm: countShortTerm,
            MediumTerm: countMediumTerm,
            LongTerm: countLongTerm,
            TermDontKnow: countTermDontKnow,
            AnzahlAntworten: anzahlAntworten,
          };

          rows.push(row);
        }

        /* generate worksheet and workbook */
        const worksheet = XLSXutils.json_to_sheet(rows);
        const workbook = XLSXutils.book_new();
        XLSXutils.book_append_sheet(workbook, worksheet, 'Data');

        /* create an XLSX file and try to save to Presidents.xlsx */
        XLSXwriteFile(workbook, `${this.umfrage.guid}.xlsx`);
      },
    },

    mounted() {
      if (this.teilnehmerantworten.length == 0) {
        this.getTeilnehmerantworten();
      }

      if (this.fragenkatalog.length == 0) {
        this.store.dispatch(FragenkatalogActionTypes.GetFragenkatalog, undefined);
      }
    },
  });
</script>

<style scoped>
  .long-list-row-selecte:hover {
    cursor: pointer;
    background: #1b3c7d2d !important;
  }
</style>
