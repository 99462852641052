import { MutationTree } from "vuex"
import { State } from "./state"
import { Fragenkatalog } from "@/models/FragenkatalogModels"

export enum MutationTypes {
  SetFragenkatalog = "SETFRAGENKATALOG",
  AddFragenkatalog = "ADDFRAGENKATALOG",
  AddFragenkatalogRange = "ADDFRAGENKATALOGRANGE",
  UpdateFragenkatalog = "UPDATEFRAGENKATALOG",
  DeleteFragenkatalog = "DELETEFRAGENKATALOG"
}

export type Mutations = {
  [MutationTypes.SetFragenkatalog](state: State, fragenkatalog: Array<Fragenkatalog>): void
  [MutationTypes.AddFragenkatalog](state: State, fragenkatalog: Fragenkatalog): void
  [MutationTypes.AddFragenkatalogRange](state: State, fragenkatalog: Array<Fragenkatalog>): void
  [MutationTypes.UpdateFragenkatalog](state: State, fragenkatalog: Fragenkatalog): void
  [MutationTypes.DeleteFragenkatalog](state: State, fragenkatalogid: number): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetFragenkatalog](state, fragenkatalog) {
    state.fragenkatalog = fragenkatalog;
    sessionStorage.setItem("fragenkatalog", JSON.stringify(state.fragenkatalog));
  },

  [MutationTypes.AddFragenkatalog](state, fragenkatalog) {
    state.fragenkatalog.push(fragenkatalog);
    sessionStorage.setItem("fragenkatalog", JSON.stringify(state.fragenkatalog));
  },

  [MutationTypes.AddFragenkatalogRange](state, fragenkatalog) {
    state.fragenkatalog.push(...fragenkatalog);
    sessionStorage.setItem("fragenkatalog", JSON.stringify(state.fragenkatalog));
  },

  [MutationTypes.UpdateFragenkatalog](state, fragenkatalog) {
    const oldfragenkatalog = state.fragenkatalog.find(a => a.id === fragenkatalog.id)
    if (oldfragenkatalog != null) {
      const index = state.fragenkatalog.indexOf(oldfragenkatalog)
      state.fragenkatalog.splice(index, 1, fragenkatalog)
    }

    sessionStorage.setItem("fragenkatalog", JSON.stringify(state.fragenkatalog));
  },

  [MutationTypes.DeleteFragenkatalog](state, fragenkatalogid) {
    const oldfragenkatalog = state.fragenkatalog.find(a => a.id === fragenkatalogid)
    if (oldfragenkatalog != null) {
      const index = state.fragenkatalog.indexOf(oldfragenkatalog)
      state.fragenkatalog.splice(index, 1)
    }

    sessionStorage.setItem("fragenkatalog", JSON.stringify(state.fragenkatalog));
  }
}