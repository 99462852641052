import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
// import ApiService from "@/services/ApiService";
import { ApiService } from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { Fragenkatalog } from '@/models/FragenkatalogModels';


export enum ActionTypes {
  GetFragenkatalog = "GETFRAGENKATALOG",
  CreateFragenkatalog = "CREATEFRAGENKATALOG",
  CreateFragenkatalogRange = "CREATEFRAGENKATALOGRANGE",
  UpdateFragenkatalog = "UPDATEFRAGENKATALOG",
  UpdateFragenkatalogRange = "UPDATEFRAGENKATALOGRANGE",
  DeleteFragenkatalog = "DELETEFRAGENKATALOG",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetFragenkatalog](context: ActionArguments): Promise<void>
  [ActionTypes.CreateFragenkatalog](context: ActionArguments, data: Fragenkatalog): Promise<void>
  [ActionTypes.CreateFragenkatalogRange](context: ActionArguments, data: Array<Fragenkatalog>): Promise<void>
  [ActionTypes.UpdateFragenkatalog](context: ActionArguments, data: Fragenkatalog): Promise<void>
  [ActionTypes.UpdateFragenkatalogRange](context: ActionArguments, data: Array<Fragenkatalog>): Promise<void>
  [ActionTypes.DeleteFragenkatalog](context: ActionArguments, fragenkatalogid: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetFragenkatalog]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getFragenkatalogLoading = true;
    status.fragenkatalogErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllFragenkatalog()
      .then(res => {
        const data: Array<Fragenkatalog> = res.data
        commit(MutationTypes.SetFragenkatalog, data)
      })
      .catch(error => {
        status.fragenkatalogErrorMsg = error.response;
      })
      .finally(() => {
        status.getFragenkatalogLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateFragenkatalog]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createFragenkatalogLoading = true;
    status.fragenkatalogErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.CreateFragenkatalog(data)
      .then(res => {
        const data: Fragenkatalog = res.data
        commit(MutationTypes.AddFragenkatalog, data)
      })
      .catch(error => {
        status.fragenkatalogErrorMsg = error.response;
      })
      .finally(() => {
        status.createFragenkatalogLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateFragenkatalogRange]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createFragenkatalogLoading = true;
    status.fragenkatalogErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.CreateFragenkatalogRange(data)
      .then(res => {
        const data: Array<Fragenkatalog> = res.data
        commit(MutationTypes.AddFragenkatalogRange, data)
      })
      .catch(error => {
        status.fragenkatalogErrorMsg = error.response;
      })
      .finally(() => {
        status.createFragenkatalogLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateFragenkatalog]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateFragenkatalogLoading = true;
    status.fragenkatalogErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.UpdateFragenkatalog(data)
      .then(res => {
        const data: Fragenkatalog = res.data
        commit(MutationTypes.UpdateFragenkatalog, data)
      })
      .catch(error => {
        status.fragenkatalogErrorMsg = error.response;
      })
      .finally(() => {
        status.updateFragenkatalogLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateFragenkatalogRange]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateFragenkatalogLoading = true;
    status.fragenkatalogErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.UpdateFragenkatalogRange(data)
      .then(res => {
        const data: Array<Fragenkatalog> = res.data
        commit(MutationTypes.SetFragenkatalog, data)
      })
      .catch(error => {
        status.fragenkatalogErrorMsg = error.response;
      })
      .finally(() => {
        status.updateFragenkatalogLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteFragenkatalog]({commit, dispatch, rootGetters}, fragenkatalogid) {
    const status = rootGetters.status;
    status.deleteFragenkatalogLoading = true;
    status.fragenkatalogErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.DeleteFragenkatalog(fragenkatalogid)
      .then(res => {
        commit(MutationTypes.DeleteFragenkatalog, fragenkatalogid)
      })
      .catch(error => {
        status.fragenkatalogErrorMsg = error.response;
      })
      .finally(() => {
        status.deleteFragenkatalogLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
}