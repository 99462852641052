<template>
  <div class="row py-2 mb-1 mx-1 bg-white shadow-sm rounded">
    <div class="col-5">
      <div class="row">
        <div class="col my-auto cut-text" data-bs-toggle="tooltip" data-bs-placement="top" :title="frage.frageDE">{{ frage.nr }}. {{ frage.frageDE }}</div>
        <div class="col-3 my-auto border-end">{{ frage.quelle }}</div>
        <div class="col-2 my-auto">{{ countRelevant }}</div>
        <div class="col-3 my-auto border-end">{{ countNichtRelevant }}</div>
      </div>
    </div>

    <div class="col-3">
      <div class="row">
        <div class="col my-auto">{{ countInsideOut }}</div>
        <div class="col my-auto">{{ countOutsideIn }}</div>
        <div class="col my-auto">{{ countBothWays }}</div>
        <div class="col my-auto border-end">{{ countRichtungDontKnow }}</div>
      </div>
    </div>

    <div class="col-3">
      <div class="row">
        <div class="col my-auto">{{ countShortTerm }}</div>
        <div class="col my-auto">{{ countMediumTerm }}</div>
        <div class="col my-auto">{{ countLongTerm }}</div>
        <div class="col my-auto border-end">{{ countTermDontKnow }}</div>
      </div>
    </div>
    
    <div class="col-1 my-auto">{{ anzahlAntworten }}</div>
  </div>
</template>
  
<script lang="ts">
  import { PropType, defineComponent, computed } from 'vue';
  import { TeilnehmerAntwort } from '@/models/TeilnehmerAntwortModels';
  import { Fragenkatalog } from '@/models/FragenkatalogModels';
  
  export default defineComponent({
    name: "SummeUmfrageFrageRow",
  
    props: {
      frage: {
        type: Object as PropType<Fragenkatalog>,
        required: true
      },

      antworten: {
        type: Object as PropType<Array<TeilnehmerAntwort>>,
        required: true
      }
    },

    setup (props) {
      const anzahlAntworten = computed(() => props.antworten.filter((ta: TeilnehmerAntwort) => ta.beantwortet == 1).length)
      const countNichtRelevant = computed(() => props.antworten.filter((ta: TeilnehmerAntwort) => ta.nichtRelevant == 1 && ta.beantwortet == 1).length)
      const countRelevant = computed(() => anzahlAntworten.value - countNichtRelevant.value)
      const countInsideOut = computed(() => props.antworten.filter((ta: TeilnehmerAntwort) => ta.insideOut == 1 && ta.beantwortet == 1).length)
      const countOutsideIn = computed(() => props.antworten.filter((ta: TeilnehmerAntwort) => ta.outsideIn == 1 && ta.beantwortet == 1).length)
      const countBothWays = computed(() => props.antworten.filter((ta: TeilnehmerAntwort) => ta.bothWays == 1 && ta.beantwortet == 1).length)
      const countRichtungDontKnow = computed(() => props.antworten.filter((ta: TeilnehmerAntwort) => ta.richtungDontKnow == 1 && ta.beantwortet == 1).length)

      const countShortTerm = computed(() => props.antworten.filter((ta: TeilnehmerAntwort) => ta.shortTerm == 1 && ta.beantwortet == 1).length)
      const countMediumTerm = computed(() => props.antworten.filter((ta: TeilnehmerAntwort) => ta.mediumTerm == 1 && ta.beantwortet == 1).length)
      const countLongTerm = computed(() => props.antworten.filter((ta: TeilnehmerAntwort) => ta.longTerm == 1 && ta.beantwortet == 1).length)
      const countTermDontKnow = computed(() => props.antworten.filter((ta: TeilnehmerAntwort) => ta.termDontKnow == 1 && ta.beantwortet == 1).length)

      return {
        countNichtRelevant,
        countInsideOut,
        countOutsideIn,
        countBothWays,
        countRichtungDontKnow,
        countShortTerm,
        countMediumTerm,
        countLongTerm,
        countTermDontKnow,
        anzahlAntworten,
        countRelevant
      }
    }
  })
</script>

<style>
.cut-text {
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
}
</style>