<template>
  <button class="btn btn-outline-secondary mb-2" @click="cancel()"><font-awesome-icon icon="chevron-left" /> Zurück</button>

  <div v-if="umfrage == null" class="alert alert-danger">
    <p>Die Umfrage konnte nicht gefunden werden.</p>
  </div>

  <div v-else>
    <h1>Outside-In Themen Bewertung</h1>

    <!-- <LoadingSpinner v-if="loading" text="... Daten werden geladen .." /> -->

    <div v-if="editThemen.length == 0" class="alert alert-info">
      Es sind keine Themen für die Bewertung vorhanden.
    </div>

    <div v-else>
      <OutsideInThemaBewertungRow v-for="thema in editThemen" :key="thema.id" :longlist="thema" />

      <hr class="my-4">

      <div v-if="errorMsg != ''" class="alert alert-danger">
        {{ errorMsg }}
      </div>

      <div class="position-fixed bottom-0 end-0 p-3 w-100 bg-white shadow border-top d-flex justify-content-end">
        <button @click="cancel()" class="btn btn-secondary">Abbrechen</button>
        <button @click="saveBewertung()" class="btn btn-primary ms-3">Speichern</button>
      </div>
    </div>
  </div>

  <LoadingOverlay v-if="loading" />

</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

import { ActionTypes as UmfrageActionTypes } from '@/store/modules/Umfrage/actions';

import OutsideInThemaBewertungRow from '@/components/Umfrage/ThemenBewertung/OutsideInThemaBewertungRow.vue';
// import LoadingSpinner from '@/components/LoadingSpinner.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';

// import { Unternehmen } from '@/models/UnternehmenModels';
import { LongList, Umfrage } from '@/models/UmfrageModels';

export default defineComponent({
  name: 'EditThemenBewertungOutsideInView',

  components: {
    OutsideInThemaBewertungRow,
    LoadingOverlay
  },

  props: {
    unternehmenguid: {
      type: String as PropType<string>,
      required: true
    },

    umfrageguid: {
      type: String as PropType<string>,
      required: true
    },

    richtung: {
      type: String as PropType<string>, // 'inside-out' | 'outside-in'
      default: 'inside-out'
    },
  },

  setup(props) {
    const store = useStore()
    const router = useRouter()

    const loading = computed(() => store.getters.status.updateLonglistLoading)
    const errorMsg = computed(() => store.getters.status.longlistErrorMsg)

    const umfrage = computed(() => store.getters.umfragen.find((u: Umfrage) => u.guid == props.umfrageguid))
    const editThemen = ref(JSON.parse(JSON.stringify(umfrage.value?.longlist?.filter((item) => item.outsideIn == 1 || item.bothWays == 1))) as Array<LongList>)

    function calcOutsideInBewertungWert() {
      editThemen.value.forEach((thema) => {
        if (thema.outsideInBewertung == 1) {
          thema.outsideInBewertungWert = thema.outsideInChanceAuswirkung * thema.outsideInChanceWahrscheinlichkeit;
        }
        else if (thema.outsideInBewertung == 2) {
          thema.outsideInBewertungWert = thema.outsideInRisikoAuswirkung * thema.outsideInRisikoWahrscheinlichkeit;
        }
      })
    }

    async function saveBewertung() {
      calcOutsideInBewertungWert();
      
      store.dispatch(UmfrageActionTypes.UpdateLonglistRange, editThemen.value)
      .then(() => {
        if (errorMsg.value == "") {
          cancel()
        }
      })
    }

    function cancel() {
      router.back()
    }

    return {
      router,

      loading,
      errorMsg,

      umfrage,
      editThemen,

      saveBewertung,
      cancel
    }
  }
});
</script>