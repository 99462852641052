import { createStore, createLogger } from 'vuex';
// import createPersistedState from 'vuex-persistedstate';

import { store as auth, AuthStore, State as AuthState} from '@/store/modules/Auth';
import { store as benutzer, BenutzerStore, State as BenutzerState} from '@/store/modules/Benutzer';
import { store as common, CommonStore, State as CommonState} from '@/store/modules/Common';
import { store as fragenkatalog, FragenkatalogStore, State as FragenkatalogState} from '@/store/modules/Fragenkatalog';
import { store as stakeholder, StakeholderStore, State as StakeholderState} from '@/store/modules/Stakeholder';
import { store as teilnehmer, TeilnehmerStore, State as TeilnehmerState} from '@/store/modules/Teilnehmer';
import { store as teilnehmerAntwort, TeilnehmerAntwortStore, State as TeilnehmerAntwortState} from '@/store/modules/TeilnehmerAntwort';
import { store as umfrage, UmfrageStore, State as UmfrageState} from '@/store/modules/Umfrage';
import { store as unternehmen, UnternehmenStore, State as UnternehmenState} from '@/store/modules/Unternehmen';
import { store as unternehmensthema, UnternehmensthemaStore, State as UnternehmensthemaState} from '@/store/modules/Unternehmensthema';

export type RootState = {
  auth: AuthState;
  common: CommonState;
  fragenkatalog: FragenkatalogState;
  benutzer: BenutzerState;
  stakeholder: StakeholderState;
  teilnehmer: TeilnehmerState;
  teilnehmerAntwort: TeilnehmerAntwortState;
  umfrage: UmfrageState;
  unternehmen: UnternehmenState;
  unternehmensthema: UnternehmensthemaState;
};

export type Store = AuthStore<Pick<RootState, 'auth'>>
  & FragenkatalogStore<Pick<RootState, "fragenkatalog">>
  & CommonStore<Pick<RootState, 'common'>>
  & BenutzerStore<Pick<RootState, 'benutzer'>>
  & StakeholderStore<Pick<RootState, 'stakeholder'>>
  & TeilnehmerStore<Pick<RootState, 'teilnehmer'>>
  & TeilnehmerAntwortStore<Pick<RootState, 'teilnehmerAntwort'>>
  & UmfrageStore<Pick<RootState, 'umfrage'>>
  & UnternehmenStore<Pick<RootState, 'unternehmen'>>
  & UnternehmensthemaStore<Pick<RootState, 'unternehmensthema'>>

const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

// plugins.push(createPersistedState({ storage: window.sessionStorage }));

export const store = createStore({
  plugins,
  modules: {
    auth: auth,
    fragenkatalog: fragenkatalog,
    common: common,
    benutzer: benutzer,
    stakeholder: stakeholder,
    teilnehmer: teilnehmer,
    teilnehmerAntwort: teilnehmerAntwort,
    umfrage: umfrage,
    unternehmen: unternehmen,
    unternehmensthema: unternehmensthema,
  },
});

export function useStore(): Store {
  return store as Store;
}