<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm rounded">
    <div class="col-4 my-auto">{{ benutzer.vorname }} {{ benutzer.name }}</div>
    <div class="col-3 my-auto">{{ benutzer.email }}</div>

    <div class="col-4 my-auto" v-if="user?.benutzerrolleId == 1">{{ getBenutzerrolle(benutzer.benutzerrolleId) }}</div>
    <div class="col-4" v-else></div>
    
    <div class="col-1">
      <button class="btn btn-primary m-0 py-1 float-end dropdown-toggle"  data-bs-toggle="dropdown" aria-expanded="false">
        <span v-if="deleteLoading">
          <div class="spinner-border spinner-border-sm text-light" role="status">
            <span class="sr-only"></span>
          </div>
        </span>

        <font-awesome-icon v-else icon="pen-to-square" class="fa-sm" />
      </button>

      <ul class="dropdown-menu m-0 p-0 rounded-0">
        <li class="dropdown-item" role="button" @click="showNeuesPasswortModal()">
          Passwort ändern
        </li>
        <li class="dropdown-item" role="button" @click="showDeleteBenutzerModal()">
          Löschen
        </li>
      </ul>
    </div>
  </div>

  <!-- Neues Passwort Modal -->
  <div
    class="modal fade"
    :id="'neuesPasswortModal-' + benutzer.id"
    tabindex="-1"
    aria-labelledby="neuesPasswortModal"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="neuesPasswortModalRef"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="neuesPasswortModalLabel">
            Passwort ändern
          </h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="closeNeuesPasswortModal()"
          ></button>
        </div>

        <div class="modal-body">
          <LoadingSpinner v-if="updateLoading" />

          <form v-else @submit.prevent="setNeuesPasswort()">
            <div class="mb-3">
              <label :for="'neues-passwort' + benutzer.id" class="form-label">Neues Passwort</label>
              <input type="password" class="form-control" :id="'neues-passwort' + benutzer.id" v-model="neuesPasswort" autocomplete="new-password" required>
            </div>
            
            <div v-if="!updateLoading && errorMsg != ''" class="alert alert-danger">
              {{ errorMsg }}
            </div>

            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary me-3"
                @click="closeNeuesPasswortModal()"
                >
                Abbrechen
              </button>
              
              <button
                type="submit"
                class="btn btn-primary"
                >
                Speichern
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


  <!-- Delete Benutzer Modal -->
  <div
    class="modal fade"
    :id="'deleteBenutzerModal-' + benutzer.id"
    tabindex="-1"
    aria-labelledby="deleteBenutzerModal"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="deleteBenutzerModalRef"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-danger py-1 text-white">
          <h5 class="modal-title" id="deleteBenutzerModalLabel">
            Benutzer löschen
          </h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="closeDeleteBenutzerModal()"
          ></button>
        </div>

        <div class="modal-body">
          <p>
            Der Benutzer wird <strong class="text-danger">engültig gelöscht</strong>. Geben Sie zur Bestätigung die E-Mail-Adresse ({{ benutzer.email }}) des Benutzers ein.
          </p>

          <LoadingSpinner v-if="deleteLoading" />

          <form v-else @submit.prevent="deleteBenutzer()">
            <div class="mb-3">
              <input type="email" class="form-control" :id="'email-' + benutzer.id" v-model="deleteBenutzerEmail" autocomplete="false" required>
            </div>
            
            <div v-if="!deleteLoading && errorMsg != ''" class="alert alert-danger">
              {{ errorMsg }}
            </div>

            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary me-3"
                @click="closeDeleteBenutzerModal()"
                >
                Abbrechen
              </button>
              
              <button
                type="submit"
                class="btn btn-danger"
                :disabled="disableDeleteBenutzerButton"
                >
                Benutzer engültig löschen
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  </template>
  
  <script lang="ts">
  import { computed, PropType, defineComponent, ref } from 'vue';
  import { Benutzer, BenutzerRolle, UpdateBenutzerPasswortRequest } from '@/models/BenutzerModels';
  import { useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { ActionTypes as BenutzerActionTypes } from "@/store/modules/Benutzer/actions"
  import { Modal } from 'bootstrap';

  import LoadingSpinner from "@/components/LoadingSpinner.vue"

  export default defineComponent({
    name: "BenutzerRow",

    components: {
      LoadingSpinner,
    },
  
    props: {
      benutzer: {
        type: Object as PropType<Benutzer>,
        required: true
      }
    },
  
    setup(props) {
      const store = useStore();

      const updateLoading = computed(() => store.getters.status.updateBenutzerLoading);
      const deleteLoading = computed(() => store.getters.status.deleteBenutzerLoading);
      const errorMsg = computed(() => store.getters.status.benutzerErrorMsg);

      const user = computed(() => store.getters.authUser?.benutzer)
      const benutzerrollen = computed(() => store.getters.benutzerrollen)

      function getBenutzerrolle(benutzerrolleId: number): string {
        const rolle = benutzerrollen.value.find((x: BenutzerRolle) => x.id == benutzerrolleId);
        return rolle != undefined ? rolle.titel : "-";
      }


      const neuesPasswort = ref("");
      const neuesPasswortModalRef = ref();
      function closeNeuesPasswortModal() {
        neuesPasswort.value = "";
        Modal.getInstance(neuesPasswortModalRef.value)?.hide();
      }

      function showNeuesPasswortModal() {
        if (neuesPasswortModalRef.value) {
          new Modal(neuesPasswortModalRef.value).show();
        }
      }

      function setNeuesPasswort() {
        var data = new UpdateBenutzerPasswortRequest(props.benutzer.id, neuesPasswort.value)
        store.dispatch(BenutzerActionTypes.UpdateBenutzerPasswort, data)
          .then(() => {
            if (errorMsg.value == "") {
              closeNeuesPasswortModal()
            }
          })
      }

      const deleteBenutzerModalRef = ref();
      function closeDeleteBenutzerModal() {
        deleteBenutzerEmail.value = "";
        Modal.getInstance(deleteBenutzerModalRef.value)?.hide();
      }

      function showDeleteBenutzerModal() {
        if (deleteBenutzerModalRef.value) {
          // neuerBenutzer.value = new CreateBenutzerRequest()
          new Modal(deleteBenutzerModalRef.value).show();
        }
      }

      const deleteBenutzerEmail = ref("")
      const disableDeleteBenutzerButton = computed(() => deleteBenutzerEmail.value != props.benutzer.email)

      function deleteBenutzer() {
        closeDeleteBenutzerModal()
        store.dispatch(BenutzerActionTypes.DeleteBenutzer, props.benutzer.id)
      }

      return {
        updateLoading,
        deleteLoading,
        errorMsg,

        user,

        benutzerrollen,
        getBenutzerrolle,

        neuesPasswort,
        neuesPasswortModalRef,
        showNeuesPasswortModal,
        closeNeuesPasswortModal,
        setNeuesPasswort,
        deleteBenutzerModalRef,
        showDeleteBenutzerModal,
        closeDeleteBenutzerModal,
        deleteBenutzer,
        deleteBenutzerEmail,
        disableDeleteBenutzerButton
      }
    },
  
  
  })
  </script>
  
  <style scoped>
  .unternehmen-row:hover {
    cursor: pointer;
    /* color: #2A579B; */
    background-color: #ebf5d6 !important;
  }
  </style>