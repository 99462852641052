<template>
<button class="btn btn-outline-secondary w-100" @click="showModal()">Teilnehmer einladen</button>

<!-- Create Teilnehmer Modal -->
<div
  class="modal fade"
  id="createTeilnehmerModal"
  tabindex="-1"
  aria-labelledby="createTeilnehmerModal"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  ref="createTeilnehmerModalRef"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="createTeilnehmerModalLabel">
          Teilnehmer einladen
        </h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="closeModal()"
        ></button>
      </div>

      <div class="modal-body text-start">
        <LoadingSpinner v-if="loading" />

        <form v-else @submit.prevent="createTeilnehmer()">
          <div class="mb-3">
            <label for="teilnehmer-name" class="form-label">Name</label>
            <input type="text" class="form-control" id="teilnehmer-name" v-model="teilnehmer.name" required>
          </div>

          <div class="mb-3">
            <label for="teilnehmer-email" class="form-label">E-Mail</label>
            <input type="email" class="form-control" id="teilnehmer-email" v-model="teilnehmer.email" required>
          </div>

          <div class="mb-3">
            <label for="teilnehmer-tel" class="form-label">Telefon (optional)</label>
            <input type="text" class="form-control" id="teilnehmer-tel" v-model="teilnehmer.tel">
          </div>

          <div v-if="teilnehmerErrorMsg != ''" class="alert alert-danger">
            {{ teilnehmerErrorMsg }}
          </div>
          
          <div v-if="teilnehmerAntwortErrorMsg != ''" class="alert alert-danger">
            {{ teilnehmerAntwortErrorMsg }}
          </div>
          

          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-secondary me-3"
              @click="closeModal()"
              >
              Abbrechen
            </button>
            
            <button
              type="submit"
              class="btn btn-primary"
              >
              Speichern
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { useStore } from 'vuex';
import { Modal } from 'bootstrap';

import LoadingSpinner from '@/components/LoadingSpinner.vue';

import { ActionTypes as TeilnehmerActionTypes } from '@/store/modules/Teilnehmer/actions';
import { ActionTypes as TeilnehmerAntwortActionTypes } from '@/store/modules/TeilnehmerAntwort/actions';

import { Teilnehmer, TeilnehmerEmail } from '@/models/TeilnehmerModels';
import { TeilnehmerAntwort } from '@/models/TeilnehmerAntwortModels';
import { Umfrage } from '@/models/UmfrageModels';
import { Unternehmen } from '@/models/UnternehmenModels';

export default defineComponent({
  name: "CreateTeilnehmerModal",

  components: {
    LoadingSpinner
  },

  props: {
    umfrageguid: {
      type: String as PropType<string>,
      required: true
    }
  },

  setup(props) {
    const store = useStore()

    const loading = computed(() => store.getters.status.createTeilnehmerLoading || store.getters.status.createTeilnehmerAntwortLoading || store.getters.status.emailTeilnehmerLoading)
    const teilnehmerErrorMsg = computed(() => store.getters.status.teilnehmerErrorMsg)
    const teilnehmerAntwortErrorMsg = computed(() => store.getters.status.teilnehmerAntwortErrorMsg)

    const fragenkatalog = computed(() => store.getters.fragenkatalog)
    
    const teilnehmer = ref(new Teilnehmer())

    async function createTeilnehmer() {

      teilnehmer.value.umfrageGuid = props.umfrageguid;

      await store.dispatch(TeilnehmerActionTypes.CreateTeilnehmerliste, teilnehmer.value)
      if (teilnehmerErrorMsg.value != "") {
        return;
      }

      await createTeilnehmerAntworten()
      if (teilnehmerAntwortErrorMsg.value != "") {
        return;
      }

      sendTeilnehmerEmail()
      
      closeModal()
    }

    const createdTeilnehmer = computed(() => store.getters.teilnehmerliste.find((t: Teilnehmer) => t.umfrageGuid == props.umfrageguid && t.email == teilnehmer.value.email)) 

    async function createTeilnehmerAntworten(): Promise<boolean> {
      const data = new Array<TeilnehmerAntwort>()

      for (var frage of fragenkatalog.value) {
        var ta = new TeilnehmerAntwort();
        ta.fragenkatalogid = frage.id;
        ta.frageDE = frage.frageDE;
        ta.teilnehmerGuid = createdTeilnehmer.value.guid;

        data.push(ta);
      }

      return store.dispatch(TeilnehmerAntwortActionTypes.CreateTeilnehmerantwortRange, data)
    }


    const teilnehmerAntworten = computed(() => store.getters.teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.teilnehmerGuid == createdTeilnehmer.value.guid))
    const umfrage = computed(() => store.getters.umfragen.find((u: Umfrage) => u.guid == props.umfrageguid))
    const unternehmen = computed(() => store.getters.unternehmenliste.find((u: Unternehmen) => u.guid == umfrage.value?.unternehmenGuid));

    const teilnehmerLink = computed(() => {
      const questionNr = teilnehmerAntworten.value?.filter((ta: TeilnehmerAntwort) => ta.beantwortet == 1).length || 0;
      return window.location.origin + `/umfrage?firma=${unternehmen.value?.firmennummer}&umfrage=${umfrage.value.guid}&teilnehmer=${createdTeilnehmer.value.guid}&section=3&question=${questionNr}&language=de`;
    })

    async function sendTeilnehmerEmail(): Promise<boolean> {
      const data = new TeilnehmerEmail();
      data.guid = createdTeilnehmer.value.guid;
      data.subject = `${unternehmen.value.name} hat Sie zur Teilnahme an der Umfrage zur Wesentlichkeitsanalyse eingeladen`;
      data.message = `
        <p>Hallo ${createdTeilnehmer.value.name}</p>
        <p>Hiermit erhalten Sie Ihren persönlichen Link zur Teilnahme:</p>
        <p><a href="${teilnehmerLink.value}" style="color: #1a73e8; text-decoration: none;">Zur Umfrage</a></p>
        <p>Wir freuen uns auf Ihre Rückmeldungen und danken Ihnen im Voraus für Ihre Zeit und Unterstützung.</p>
        <p>Sollten Sie Fragen zur Umfrage haben, zögern Sie bitte nicht, Ihren Verantwortlichen zu kontaktieren.</p>
        <p>Mit freundlichen Grüßen,<br>Ihr 1.A Nachhaltigkeits Team</p>
      `;

      return await store.dispatch(TeilnehmerActionTypes.SendTeilnehmerEmail, data)
    }

    const createTeilnehmerModalRef = ref();
    function closeModal() {
      Modal.getInstance(createTeilnehmerModalRef.value)?.hide();
    }

    function showModal() {
      if (createTeilnehmerModalRef.value) {
        teilnehmer.value = new Teilnehmer();
        new Modal(createTeilnehmerModalRef.value).show();
      }
    }
    return {

      loading,
      teilnehmerErrorMsg,
      teilnehmerAntwortErrorMsg,

      teilnehmer,
      createTeilnehmer,

      createTeilnehmerModalRef,
      showModal,
      closeModal
    }
  }
})
</script>