import { MutationTree } from "vuex"
import { State } from "./state"
import { Unternehmen } from '@/models/UnternehmenModels';
import { Stakeholder } from "@/models/StakeholderModels";

export enum MutationTypes {
  SetStakeholder = "SETSTAKEHOLDER",
  AddStakeholder = "ADDSTAKEHOLDER",
  UpdateStakeholder = "UPDATESTAKEHOLDER",
  DeleteStakeholder = "DELETESTAKEHOLDER"
}

export type Mutations = {
  [MutationTypes.SetStakeholder](state: State, stakeholder: Array<Stakeholder>): void
  [MutationTypes.AddStakeholder](state: State, stakeholder: Stakeholder): void
  [MutationTypes.UpdateStakeholder](state: State, stakeholder: Stakeholder): void
  [MutationTypes.DeleteStakeholder](state: State, stakeholderid: number): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetStakeholder](state, stakeholder) {
    state.stakeholder = stakeholder;
    localStorage.setItem("stakeholder", JSON.stringify(state.stakeholder));
  },

  [MutationTypes.AddStakeholder](state, stakeholder) {
    state.stakeholder.push(stakeholder);
    localStorage.setItem("stakeholder", JSON.stringify(state.stakeholder));
  },

  [MutationTypes.UpdateStakeholder](state, stakeholder) {
    const oldstakeholder = state.stakeholder.find(a => a.id === stakeholder.id)
    if (oldstakeholder != null) {
      const index = state.stakeholder.indexOf(oldstakeholder)
      state.stakeholder.splice(index, 1, stakeholder)
    }

    localStorage.setItem("stakeholder", JSON.stringify(state.stakeholder));
  },

  [MutationTypes.DeleteStakeholder](state, stakeholderid) {
    const oldstakeholder = state.stakeholder.find(a => a.id === stakeholderid)
    if (oldstakeholder != null) {
      const index = state.stakeholder.indexOf(oldstakeholder)
      state.stakeholder.splice(index, 1)
    }

    localStorage.setItem("stakeholder", JSON.stringify(state.stakeholder));
  }
}