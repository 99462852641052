import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
// import ApiService from "@/services/ApiService";
import { ApiService } from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { Stakeholder } from '@/models/StakeholderModels';


export enum ActionTypes {
  GetStakeholder = "GETSTAKEHOLDER",
  CreateStakeholder = "CREATESTAKEHOLDER",
  UpdateStakeholder = "UPDATESTAKEHOLDER",
  DeleteStakeholder = "DELETESTAKEHOLDER",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetStakeholder](context: ActionArguments): Promise<void>
  [ActionTypes.CreateStakeholder](context: ActionArguments, data: Stakeholder): Promise<void>
  [ActionTypes.UpdateStakeholder](context: ActionArguments, data: Stakeholder): Promise<void>
  [ActionTypes.DeleteStakeholder](context: ActionArguments, stakeholderid: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetStakeholder]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getStakeholderLoading = true;
    status.stakeholderErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.GetAllStakeholder()
      .then(res => {
        const data: Array<Stakeholder> = res.data
        commit(MutationTypes.SetStakeholder, data)
      })
      .catch(error => {
        status.stakeholderErrorMsg = error.response;
      })
      .finally(() => {
        status.getStakeholderLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateStakeholder]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createStakeholderLoading = true;
    status.stakeholderErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.CreateStakeholder(data)
      .then(res => {
        const data: Stakeholder = res.data
        commit(MutationTypes.AddStakeholder, data)
      })
      .catch(error => {
        if (error.response.status == 400) {
          status.stakeholderErrorMsg = error.response.data.detail;
        }
        else {
          status.stakeholderErrorMsg = error.response;
        }
      })
      .finally(() => {
        status.createStakeholderLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateStakeholder]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateStakeholderLoading = true;
    status.stakeholderErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.UpdateStakeholder(data)
      .then(res => {
        const data: Stakeholder = res.data
        commit(MutationTypes.UpdateStakeholder, data)
      })
      .catch(error => {
        status.stakeholderErrorMsg = error.response;
      })
      .finally(() => {
        status.updateStakeholderLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteStakeholder]({commit, dispatch, rootGetters}, stakeholderid) {
    const status = rootGetters.status;
    status.deleteStakeholderLoading = true;
    status.stakeholderErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.DeleteStakeholder(stakeholderid)
      .then(res => {
        commit(MutationTypes.DeleteStakeholder, stakeholderid)
      })
      .catch(error => {
        status.stakeholderErrorMsg = error.response;
      })
      .finally(() => {
        status.deleteStakeholderLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
}