import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
// import ApiService from "@/services/ApiService";
import { ApiService } from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { Benutzer, BenutzerRolle, BenutzerUnternehmen, BeraterUnternehmenRequest, CreateBenutzerRequest, UpdateBenutzerPasswortRequest } from '@/models/BenutzerModels';
import { ErrorResponseData } from '@/models/CommonModels';
import { Axios, AxiosError } from 'axios';

export enum ActionTypes {
  GetBenutzer = "GETBENUTZER",
  CreateBenutzer = "CREATEBENUTZER",
  UpdateBenutzer = "UPDATEBENUTZER",
  UpdateBenutzerPasswort = "UPDATEBENUTZERPASSWORT",
  DeleteBenutzer = "DELETEBENUTZER",

  GetBenutzerrollen = "GETBENUTZERROLLEN",

  AddBenutzerUnternehmen = "ADDBENUTZERUNTERNEHMEN",
  AddBeraterUnternehmen  = "ADDBERATERUNTERNEHMEN",
  RemoveBenutzerUnternehmen = "REMOVEBENUTZERUNTERNEHMEN",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetBenutzer](context: ActionArguments, unternehmenGuid: string | undefined): Promise<void>
  [ActionTypes.CreateBenutzer](context: ActionArguments, data: CreateBenutzerRequest): Promise<void>
  [ActionTypes.UpdateBenutzer](context: ActionArguments, data: Benutzer): Promise<void>
  [ActionTypes.UpdateBenutzerPasswort](context: ActionArguments, data: UpdateBenutzerPasswortRequest): Promise<void>
  [ActionTypes.DeleteBenutzer](context: ActionArguments, benutzerid: number): Promise<void>
  
  [ActionTypes.GetBenutzerrollen](context: ActionArguments): Promise<void>
  
  [ActionTypes.AddBenutzerUnternehmen](context: ActionArguments, data: BenutzerUnternehmen): Promise<void>
  [ActionTypes.AddBeraterUnternehmen](context: ActionArguments, data: BeraterUnternehmenRequest): Promise<void>
  [ActionTypes.RemoveBenutzerUnternehmen](context: ActionArguments, data: BenutzerUnternehmen): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  //#region Benutzer
  async [ActionTypes.GetBenutzer]({commit, dispatch, rootGetters}, unternehmenGuid) {
    const status = rootGetters.status;
    status.getBenutzerLoading = true;
    status.benutzerErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.GetAllBenutzer(unternehmenGuid)
      .then(res => {
        const data: Array<Benutzer> = res.data
        commit(MutationTypes.SetBenutzer, data)
        dispatch(ActionTypes.GetBenutzerrollen, undefined)
      })
      .catch(error => {
        status.benutzerErrorMsg = error.response;
      })
      .finally(() => {
        status.getBenutzerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateBenutzer]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createBenutzerLoading = true;
    status.benutzerErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    try {
      const res = await ApiService.CreateBenutzer(data)
      const benutzer: Benutzer = res.data
      commit(MutationTypes.AddBenutzer, benutzer)
  
      // if (rootGetters.authUser.benutzer.benutzerrolleId == 3) {
      //   const benutzerUnternehmen = new BenutzerUnternehmen(benutzer.id, rootGetters.authUser.benutzer.benutzerUnternehmen[0].unternehmenGuid)
      //   console.log(benutzerUnternehmen);
      //   await dispatch(ActionTypes.AddBenutzerUnternehmen, benutzerUnternehmen)
      // }
    }
    catch (error: any) {
      console.log(error);
      if (error.response.status == 400) {
        const err = error.response.data as ErrorResponseData
        console.log(err);
        status.benutzerErrorMsg = err.title;
      }
      else {
        status.benutzerErrorMsg = error.response;
      }
    }
    finally {
      status.createBenutzerLoading = false;
      dispatch(CommonActionTypes.SetStatus, status);
    }

    return 


    // .then(res => {
    //   })
    //   .catch(error => {
    //     console.log(error.response);
    //     if (error.response.status == 400) {
    //       const err = error.response.data as ErrorResponseData
    //       console.log(err);
    //       status.benutzerErrorMsg = err.title;
    //     }
    //     else {
    //       status.benutzerErrorMsg = error.response;
    //     }
    //   })
    //   .finally(() => {
    //     status.createBenutzerLoading = false;
    //     dispatch(CommonActionTypes.SetStatus, status);
    //   })
  },

  async [ActionTypes.UpdateBenutzer]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateBenutzerLoading = true;
    status.benutzerErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.UpdateBenutzer(data)
      .then(res => {
        const data: Benutzer = res.data
        commit(MutationTypes.UpdateBenutzer, data)
      })
      .catch(error => {
        status.benutzerErrorMsg = error.response;
      })
      .finally(() => {
        status.updateBenutzerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateBenutzerPasswort]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateBenutzerLoading = true;
    status.benutzerErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.UpdateBenutzerPasswort(data)
      .then(res => {
      })
      .catch(error => {
        status.benutzerErrorMsg = error.response;
      })
      .finally(() => {
        status.updateBenutzerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteBenutzer]({commit, dispatch, rootGetters}, benutzerid) {
    const status = rootGetters.status;
    status.deleteBenutzerLoading = true;
    status.benutzerErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.DeleteBenutzer(benutzerid)
      .then(res => {
        commit(MutationTypes.DeleteBenutzer, benutzerid)
      })
      .catch(error => {
        status.benutzerErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteBenutzerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region Benutzerrollen
  async [ActionTypes.GetBenutzerrollen]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getBenutzerrolleLoading = true;
    status.benutzerrolleErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.GetAllBenutzerrollen()
      .then(res => {
        const data: Array<BenutzerRolle> = res.data
        commit(MutationTypes.SetBenutzerrollen, data)
      })
      .catch(error => {
        status.benutzerrolleErrorMsg = error.response;
      })
      .finally(() => {
        status.getBenutzerrolleLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion


  //#region BenutzerUnternehmen
  async [ActionTypes.AddBenutzerUnternehmen]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createBenutzerUnternehmenLoading = true;
    status.benutzerUnternehmenErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.CreateBenutzerUnternehmen(data)
      .then(res => {
        const data: BenutzerUnternehmen = res.data
        commit(MutationTypes.AddBenutzerUnternehmen, data)
      })
      .catch(error => {
        status.benutzerUnternehmenErrorMsg = error.response;
      })
      .finally(() => {
        status.createBenutzerUnternehmenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.AddBeraterUnternehmen]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createBenutzerUnternehmenLoading = true;
    status.benutzerUnternehmenErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    console.log(data);

    return ApiService.AddBeraterUnternehmen(data)
      .then(res => {
        // const response: BenutzerUnternehmen = res.data
        // commit(MutationTypes.AddBenutzerUnternehmen, response)
        // console.log(response);
        console.log(data);
        dispatch(ActionTypes.GetBenutzer, data.unternehmenGuid)
      })
      .catch(error => {
        status.benutzerUnternehmenErrorMsg = error.response;
      })
      .finally(() => {
        status.createBenutzerUnternehmenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.RemoveBenutzerUnternehmen]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.deleteBenutzerUnternehmenLoading = true;
    status.benutzerUnternehmenErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    console.log(data);

    return ApiService.RemoveBenutzerUnternehmen(data.id)
      .then(() => {
        commit(MutationTypes.RemoveBenutzerUnternehmen, data)
        dispatch(ActionTypes.GetBenutzer, data.unternehmenGuid)
      })
      .catch(error => {
        status.benutzeUnternehmenrErrorMsg = error.response;
      })
      .finally(() => {
        status.deleteBenutzerUnternehmenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion
}