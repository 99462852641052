export class Stakeholder {
  id: number;
  name: string;
  beschreibung: string;
  isDeleted: number;

  constructor () {
    this.id = 0;
    this.name = "";
    this.beschreibung = "";
    this.isDeleted = 0;
  }
}