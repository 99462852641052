import { Teilnehmer } from "@/models/TeilnehmerModels"

export type State = {
  teilnehmer: Teilnehmer
  teilnehmerliste: Array<Teilnehmer>
}

export const state: State = {
  teilnehmer: sessionStorage.getItem("teilnehmer") != null ? JSON.parse(sessionStorage.teilnehmer) : new Teilnehmer(),
  teilnehmerliste: localStorage.getItem("teilnehmerliste") != null ? JSON.parse(localStorage.teilnehmerliste) : new Array<Teilnehmer>(),
}
