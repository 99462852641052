export class Teilnehmer {
  guid: string;
  name: string;
  email: string;
  tel: string;
  umfrageGuid: string;
  umfragedauer: number;
  isDeleted: number;

  constructor () {
    this.guid = "";
    this.name = "";
    this.email = "";
    this.tel = "";
    this.umfrageGuid = "";
    this.umfragedauer = 0;
    this.isDeleted = 0;
  }
}

export class TeilnehmerEmail {
  guid: string;
  subject: string;
  message: string;

  constructor () {
    this.guid = "";
    this.subject = "";
    this.message = "";
  }
}