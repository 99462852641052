import { MutationTree } from "vuex"
import { State } from "./state"
import { Frage } from "@/models/FragenModels"
import { Benutzer, BenutzerRolle, BenutzerUnternehmen } from "@/models/BenutzerModels"

export enum MutationTypes {
  SetBenutzer = "SETBENUTZER",
  AddBenutzer = "ADDBENUTZER",
  UpdateBenutzer = "UPDATEBENUTZER",
  DeleteBenutzer = "DELETEBENUTZER",

  SetBenutzerrollen = "SETBENUTZERROLLEN",

  AddBenutzerUnternehmen = "ADDBENUTZERUNTERNEHMEN",
  RemoveBenutzerUnternehmen = "REMOVEBENUTZERUNTERNHEMEN",
}

export type Mutations = {
  [MutationTypes.SetBenutzer](state: State, benutzer: Array<Benutzer>): void
  [MutationTypes.AddBenutzer](state: State, benutzer: Benutzer): void
  [MutationTypes.UpdateBenutzer](state: State, benutzer: Benutzer): void
  [MutationTypes.DeleteBenutzer](state: State, benutzerid: number): void

  [MutationTypes.SetBenutzerrollen](state: State, benutzer: Array<BenutzerRolle>): void

  [MutationTypes.AddBenutzerUnternehmen](state: State, benutzerUnternehmen: BenutzerUnternehmen): void
  [MutationTypes.RemoveBenutzerUnternehmen](state: State, benutzerUnternehmen: BenutzerUnternehmen): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetBenutzer](state, benutzer) {
    state.benutzer = benutzer;
    localStorage.setItem("benutzer", JSON.stringify(state.benutzer));
  },

  [MutationTypes.AddBenutzer](state, benutzer) {
    state.benutzer.push(benutzer);
    localStorage.setItem("benutzer", JSON.stringify(state.benutzer));
  },

  [MutationTypes.UpdateBenutzer](state, benutzer) {
    const oldbenutzer = state.benutzer.find(a => a.id === benutzer.id)
    if (oldbenutzer != null) {
      const index = state.benutzer.indexOf(oldbenutzer)
      state.benutzer.splice(index, 1, benutzer)
    }

    localStorage.setItem("benutzer", JSON.stringify(state.benutzer));
  },

  [MutationTypes.DeleteBenutzer](state, benutzerid) {
    const oldbenutzer = state.benutzer.find(a => a.id === benutzerid)
    if (oldbenutzer != null) {
      const index = state.benutzer.indexOf(oldbenutzer)
      state.benutzer.splice(index, 1)
    }
    
    localStorage.setItem("benutzer", JSON.stringify(state.benutzer));
  },


  [MutationTypes.SetBenutzerrollen](state, benutzerrollen) {
    state.benutzerrollen = benutzerrollen;
    localStorage.setItem("benutzerrollen", JSON.stringify(state.benutzerrollen));
  },

  [MutationTypes.AddBenutzerUnternehmen](state, benutzerUnternehmen) {
    const benutzer = state.benutzer.find(a => a.id === benutzerUnternehmen.benutzerId)
    if (benutzer != null) {
      const idx = benutzer.benutzerUnternehmen.findIndex(a => a.id == benutzerUnternehmen.id)
      if (idx == -1) {
        benutzer.benutzerUnternehmen.push(benutzerUnternehmen)
      }
      else {
        benutzer.benutzerUnternehmen.splice(idx, 1, benutzerUnternehmen)
      }

      localStorage.setItem("benutzer", JSON.stringify(state.benutzer));
    }
  },


  [MutationTypes.RemoveBenutzerUnternehmen](state, benutzerUnternehmen) {
    const benutzer = state.benutzer.find(a => a.id === benutzerUnternehmen.benutzerId)
    if (benutzer != null) {
      const idx = benutzer.benutzerUnternehmen.findIndex(a => a.id == benutzerUnternehmen.id)
      if (idx != -1) {
        benutzer.benutzerUnternehmen.splice(idx, 1)

        localStorage.setItem("benutzer", JSON.stringify(state.benutzer));
      }

    }
  },

}