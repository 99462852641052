export class Unternehmensthema {
  id: number;
  kategorie: string;
  thema: string;
  beschreibung: string;
  unternehmenGuid: string | null;

  constructor () {
    this.id = 0;
    this.kategorie = "";
    this.thema = "";
    this.beschreibung = "";
    this.unternehmenGuid = null;
  }
}