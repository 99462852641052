import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { Fragenkatalog } from '@/models/FragenkatalogModels';


export type Getters = {
  fragenkatalog(state: State): Array<Fragenkatalog>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  fragenkatalog(state) {
    return state.fragenkatalog;
  },
}