import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { TeilnehmerAntwort } from "@/models/TeilnehmerAntwortModels"

export type Getters = {
  teilnehmerantworten(state: State): Array<TeilnehmerAntwort>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  teilnehmerantworten(state) {
    return state.teilnehmerantworten;
  },
}