<template>
  <div class="d-flex justify-content-center align-items-center mt-5">
    <LoadingSpinner v-if="loading" text="...Anmeldedaten werden geprüft..."/>

    <div v-else class="d-flex-col">

      <div class="mb-5 text-center">
        <img src="@/assets/icons/logo.png" alt="Nachhaltigkeit Logo" width="64" />
        <h1>Nachhaltigkeit</h1>
      </div>
      
      <form class="form-signin p-0" @submit.prevent="login">
        <div class="form-floating mb-3">
          <input
            type="email"
            class="form-control"
            id="floatingInput"
            placeholder="Anmeldename"
            v-model="user.email"
            autocomplete="email"
            required
          />
          <label for="floatingInput">E-Mail</label>
        </div>
        
        <div class="form-floating mb-3">
          <input
            type="password"
            class="form-control"
            id="floatingPassword"
            placeholder="Password"
            v-model="user.passwort"
            autocomplete="current-password"
            required
          />
          <label for="floatingPassword">Passwort</label>
        </div>

        <div v-if="showErrorMsg" class="alert alert-danger" role="alert">
          {{ displayErrorMsg }}
          <!-- Ungültige Anmeldedaten -->
        </div>
        <button type="submit" class="w-100 btn btn-lg btn-primary">
          <font-awesome-icon icon="arrow-right-to-bracket" class="me-3" />
          Anmelden
        </button>

      </form>
    </div>  

  </div>  
</template>

<script lang="ts">
import { LoginRequest } from '@/models/AuthModels';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { defineComponent, computed, ref } from 'vue';

import { ActionTypes as AuthActionTypes } from "@/store/modules/Auth/actions"

export default defineComponent({
  name: "Login",

  components: {
    LoadingSpinner
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const loading = computed(() => store.getters.status.authLoginLoading)
    const errorMsg = computed(() => store.getters.status.authErrorMsg)

    const user = ref(new LoginRequest())

    const authUser = computed(() => store.getters.authUser);

    const displayErrorMsg = ref("");
    const showErrorMsg = ref(false)

    function login() {
      displayErrorMsg.value = "";
      showErrorMsg.value = false;


      if (user.value.email == "" || user.value.passwort == "") {
        displayErrorMsg.value = "Anmeldedaten sind unvollständig";
        showErrorMsg.value = false;
      }
      else {
        store.dispatch(AuthActionTypes.Login, user.value)
          .then(() => {
            if (errorMsg.value != "") {
              displayErrorMsg.value = errorMsg.value;
              showErrorMsg.value = true;
            }
            else {
              displayErrorMsg.value = "";
              showErrorMsg.value = false;

              console.log(authUser);
              if (authUser.value?.benutzer.benutzerrolleId == 1) {
                router.push({name: "Dashboard"})
              }
              else if (authUser.value?.benutzer.benutzerrolleId == 2) {
                router.push({name: "Unternehmen"})
              }
              else if (authUser.value?.benutzer.benutzerrolleId == 3 && authUser.value?.benutzer.benutzerUnternehmen.length > 0) {
                console.log(authUser.value);
                console.log("DetailsUnternehmen");
                router.push({name: "DetailsUnternehmen", params: {guid: authUser.value?.benutzer.benutzerUnternehmen[0].unternehmenGuid}})
              }
              else {
                console.log("Else");
                displayErrorMsg.value = "Sie sind angemeldet, haben aber keine Berechtigung";
                showErrorMsg.value = true;
              }
            }
          })
      }

    }

    return {
      store,
      loading,
      user,
      login,
      displayErrorMsg,
      showErrorMsg
    }
  }
})
</script>