import { MutationTree } from "vuex"
import { State } from "./state"
import { AuthUser } from "@/models/AuthModels"

export enum MutationTypes {
  SetAuthUser = "SETAUTHUSER",
  ClearAuthUser = "CLEARAUTHUSER"

}

export type Mutations = {
  [MutationTypes.SetAuthUser](state: State, authUser: AuthUser): void
  [MutationTypes.ClearAuthUser](state: State): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetAuthUser](state, authUser) {
    console.log("setAuthUser");
    state.authUser = authUser;
    localStorage.setItem("authUser", JSON.stringify(state.authUser));
    console.log("authUser set");
  },

  [MutationTypes.ClearAuthUser](state) {
    state.authUser = null;
    sessionStorage.removeItem("authUser");
  },
}