import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { Benutzer, BenutzerRolle } from '@/models/BenutzerModels';


export type Getters = {
  benutzer(state: State): Array<Benutzer>,
  benutzerrollen(state: State): Array<BenutzerRolle>
}

export const getters: GetterTree<State, RootState> & Getters = {
  benutzer(state) {
    return state.benutzer;
  },
  
  benutzerrollen(state) {
    return state.benutzerrollen;
  },
}