import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { Stakeholder } from '@/models/StakeholderModels';


export type Getters = {
  stakeholder(state: State): Array<Stakeholder>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  stakeholder(state) {
    return state.stakeholder;
  },
}