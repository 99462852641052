<template>
  <div class="container">
    <section v-if="displaySection == 0">
      <div class="row">
        <div class="col col-md-6 offset-md-3 bg-white rounded shadow p-3">
          <LoadingSpinner v-if="unternehmenLoading" text="...Unternehmensdaten werden geladen..." />
          <LoadingSpinner v-else-if="umfrageLoading" text="...Umfragedaten werden geladen..." />
          
          <form v-else @submit.prevent="getUnternehmen()">
            <strong v-if="language == 'en'" class="mb-2">Please enter your company number:</strong>
            <strong v-else class="mb-2">Bitte geben Sie Ihre Firmennummer ein:</strong>
            <input type="text" class="form-control mb-3" id="firemennummer" v-model="firmennummer">

            <div v-if="unternehmenErrorMsg != ''" class="alert alert-danger">
              <p>{{ unternehmenErrorMsg }}</p>
              <p>Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.</p>
            </div>
            
            <div v-if="umfrageErrorMsg != ''" class="alert alert-danger">
              {{ umfrageErrorMsg }}
              Es wuden keine Umfragen gefunden
            </div>

            <button type="submit" v-if="language == 'en'" class="btn btn-primary float-end">Next</button>
            <button type="submit" v-else class="btn btn-primary float-end">Weiter</button>
          </form>
        </div>
      </div>
    </section>

    <section v-else-if="displaySection == 1 && unternehmen.guid != ''">
      <div class="row">
        <div class="col col-md-6 offset-md-3 bg-white rounded shadow p-3">
          <h3>Umfrage auswählen</h3>

          <div v-if="umfragen.length == 0" class="alert alert-warning">
            <p>Es wurden keine Umfragen gefunden.</p>
            <button @click="goBackToFirmennummer()" class="btn btn-outline-secondary">Zurück</button> 
          </div>

          <div v-for="(umfrage, index) in umfragen" :key="index" @click="selectUmfurage(umfrage)" class="d-flex justify-content-between align-items-center bg-white shadow p-2 mb-2 select-umfrage">
            <span>{{ umfrage.titel }}</span> <button class="btn btn-sm btn-primary"><font-awesome-icon icon="chevron-right" /></button>
          </div>
        </div>
      </div>
    </section>

    <section v-else-if="displaySection == 2">
      <div class="row">
        <div class="col col-md-6 offset-md-3 bg-white rounded shadow p-3">
          <span v-if="language == 'en'">
            <p><strong>Company:</strong> {{ unternehmen.firmenname }}</p>
            <p><strong>Survey:</strong> {{ selectedUmfrage?.titel }}</p>
          </span>
          
          <span v-else>
            <p><strong>Unternehmen:</strong> {{ unternehmen.firmenname }}</p>
            <p><strong>Umfrage:</strong> {{ selectedUmfrage?.titel }}</p>
          </span>
          <hr class="my-3">

          <LoadingSpinner v-if="teilnehmerLoading" text="...Ihre Daten werden erzeugt..." />
          <LoadingSpinner v-else-if="teilnehmerAntwortLoading" text="...Fragen werden geladen..." />

          <div v-else-if="!teilnehmerLoading && !teilnehmerAntwortLoading">
            <p v-if="language == 'en'" class="fw-bold">Please enter your data:</p>
            <p v-else class="fw-bold">Bitte geben Sie Ihre Daten ein:</p>
            <form @submit.prevent="createTeilnehmer()">
              <div class="mb-3">
                <label for="teilnehmer-name" class="form-label">Name</label>
                <input type="text" class="form-control" id="teilnehmer-name" v-model="neuerTeilnehmer.name" required>
              </div>

              <div class="mb-3">
                <label for="teilnehmer-email" class="form-label">E-Mail</label>
                <input type="email" class="form-control" id="teilnehmer-email" v-model="neuerTeilnehmer.email" required>
              </div>

              <div class="mb-3">
                <label v-if="language == 'en'" for="teilnehmer-tel" class="form-label">Phone (optional)</label>
                <label v-else for="teilnehmer-tel" class="form-label">Telefon (optional)</label>
                <input type="text" class="form-control" id="teilnehmer-tel" v-model="neuerTeilnehmer.tel">
              </div>

              <div v-if="teilnehmerErrorMsg != ''" class="alert alert-danger">
                {{ teilnehmerErrorMsg }}
              </div>
              
              <div v-if="teilnehmerAntwortErrorMsg != ''" class="alert alert-danger">
                {{ teilnehmerAntwortErrorMsg }}
              </div>
              
              <button v-if="language == 'en'" type="submit" class="btn btn-primary float-end">Next</button>
              <button v-else type="submit" class="btn btn-primary float-end">Weiter</button>
            </form>
          </div>

        </div>
      </div>
    </section>

    <section v-else-if="displaySection == 3 && language == 'en'">
      <div class="row mb-5" v-for="(frage, index) in displayTeilnehmerAntworten" :key="index" v-show="displayFrage == index">
        <div class="col col-md-6 offset-md-3 p-0 bg-white rounded shadow">
          <div class="d-felx flex-column">
            <div class="py-2 px-3 rounded-top" :class="questionBg(frage.aspektDE)">
              {{ frage.fragenkatalogid }} | {{ frage.aspektEN }}
            </div>
            <div class="py-2 px-3 border-bottom border-primary">
              {{ frage.themaEN }}
            </div>
            <div class="py-2 px-3">
              <strong>{{ frage.frageEN }}</strong><br>
              Please assess this topic in relation to your company and the supply chain.
            </div>
            <div class="py-2 px-3">
              <div v-html="frage.beschreibungEN"></div>
              <!-- {{ frage.beschreibungEN }} -->
            </div>

            <hr class="my-3">

            <div class="pb-2 px-3 mb-3">
              <div class="d-flex justify-content-between">
                <span>Please assess the <strong>direction of impact</strong> of the topic:</span>

                <!-- <button type="button" class="btn text-muted" data-bs-toggle="collapse" data-bs-target="#collapseWirkungsrichtung" aria-expanded="false" aria-controls="collapseWirkungsrichtung"><font-awesome-icon icon="circle-info" /></button> -->
              </div>
<!-- 
              <div class="collapse mb-4 p-3 border border-primary rounded" id="collapseWirkungsrichtung">
                <strong>Hinweise Wirkungsrichtung:</strong> <br>
                - Inside-Out => wir verursachen eine positive oder negative Wirkung <br>
                - Outside-In => das Thema hat eine Auswirkung auf unser Unternehmen (+/-) und oder es entstehen (finanzielle) Chancen und/oder Risiken 
              </div> -->

              <div>
                <div class="form-check mb-2">
                  <input class="form-check-input" type="radio" :value="2" :id="'insideout-' + frage.id" v-model="frage.wirkungsrichtung">
                  <label class="form-check-label" :for="'insideout-' + frage.id">
                    <span>We cause a positive or negative effect (I-O)</span>
                  </label>
                </div>

                <div class="form-check mb-2">
                  <input class="form-check-input" type="radio" :value="1" :id="'outsiedin-' + frage.id" v-model="frage.wirkungsrichtung">
                  <label class="form-check-label" :for="'outsiedin-' + frage.id">
                    <span>The topic has a positive or negative effect on our company (O-I)</span>
                  </label>
                </div>

                <div class="form-check mb-2">
                  <input class="form-check-input" type="radio" :value="3" :id="'bothways-' + frage.id" v-model="frage.wirkungsrichtung">
                  <label class="form-check-label" :for="'bothways-' + frage.id">
                    <span>Both directions</span>
                  </label>
                </div>

                <div class="form-check mb-2">
                  <input class="form-check-input" type="radio" :value="4" :id="'not-relevant-' + frage.id" v-model="frage.wirkungsrichtung">
                  <label class="form-check-label" :for="'not-relevant-' + frage.id">
                    <span>Not relevant to our company</span>
                  </label>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="radio" :value="5" :id="'richtung-dont-' + frage.id" v-model="frage.wirkungsrichtung">
                  <label class="form-check-label" :for="'richtung-dont-' + frage.id">
                    <span>Cannot assess</span>
                  </label>
                </div>
              </div>
            </div>

            <hr class="my-3">
            
            <div v-if="frage.wirkungsrichtung > 0 && frage.wirkungsrichtung < 4" class="py-2 px-3">
              <div class="d-flex justify-content-between mb-4">
                <strong>When or over what time period can the impact be expected?</strong>

                <!-- <button type="button" class="btn text-muted" data-bs-toggle="collapse" data-bs-target="#collapseWirkungszeitraum" aria-expanded="false" aria-controls="collapseWirkungszeitraum"><font-awesome-icon icon="circle-info" /></button> -->
              </div>

              <!-- <div class="collapse mb-4 p-3 border border-primary rounded" id="collapseWirkungszeitraum">
                Kurfristig: bis zu 12 Monate <br>
                Mittelfristig: bis zu 5 Jahre  <br>
                Lanfristig: mehr als 5 Jahre 
              </div> -->

              <div>
                <div class="form-check mb-2">
                  <input class="form-check-input" type="checkbox" value="" :id="'shortterm-' + frage.id" v-model="frage.shortTerm">
                  <label class="form-check-label" :for="'shortterm-' + frage.id">
                    <span>Short-term: up to 12 months</span>
                  </label>
                </div>
                      
                <div class="form-check mb-2">
                  <input class="form-check-input" type="checkbox" value="" :id="'mediumterm-' + frage.id" v-model="frage.mediumTerm">
                  <label class="form-check-label" :for="'mediumterm-' + frage.id">
                    <span>Medium-term: up to 5 years</span>
                  </label>
                </div>

                <div class="form-check mb-2">
                  <input class="form-check-input" type="checkbox" value="" :id="'longterm-' + frage.id" v-model="frage.longTerm">
                  <label class="form-check-label" :for="'longterm-' + frage.id">
                    <span>Long-term: more than 5 years</span>
                  </label>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" :id="'zeitraum-dont-' + frage.id" v-model="frage.termDontKnow">
                  <label class="form-check-label" :for="'zeitraum-dont-' + frage.id">
                    <span>Cannot assess</span>
                  </label>
                </div>
              </div>
            </div>
            
            <hr class="my-3">

            <div class="row my-3 px-3">
              <div class="col">
                <button class="btn btn-primary w-100" @click="goFrageBack()" :disabled="displayFrage == 0">
                  <span>Back</span>
                </button>
              </div>

              <div class="col">
                <!-- <button class="btn btn-info w-100" @click="goBackToStart()" disabled> -->
                <button class="btn btn-outline-secondary w-100" @click="displaySection = 4">
                  <span>Pause</span>
                </button>
              </div>

              <div class="col">
                <button class="btn btn-primary w-100" @click="goFrageNext()" :disabled="disableNextButton">
                  <span>Next</span>
                </button>
              </div>
            </div>

            <div class="row my-3 px-3">
              <div class="col">
                <div class="progress" role="progressbar" aria-label="Default striped example" :aria-valuenow="index" :aria-valuemin="0" :aria-valuemax="100">
                  <div class="progress-bar progress-bar-striped" :style="'width: ' + getPercent(index, displayTeilnehmerAntworten.length) + '%'"></div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section v-else-if="displaySection == 3 && language != 'en'">
      <div class="row mb-5" v-for="(frage, index) in displayTeilnehmerAntworten" :key="index" v-show="displayFrage == index">
        <div class="col col-md-6 offset-md-3 p-0 bg-white rounded shadow">
          <div class="d-felx flex-column">
            <div class="py-2 px-3 rounded-top" :class="questionBg(frage.aspektDE)">
              {{ frage.fragenkatalogid }} | {{ frage.aspektDE }}
            </div>
            <div class="py-2 px-3 border-bottom border-primary">
              {{ frage.themaDE }}
            </div>
            <div class="py-2 px-3">
              <strong>{{ frage.frageDE }}</strong><br>
            </div>
            <div class="py-2 px-3">
              <!-- {{ frage.beschreibungDE }} -->
              <div v-html="frage.beschreibungDE"></div>
            </div>

            <hr class="my-3">

            <div class="pb-2 px-3 mb-3">
              <div class="d-flex justify-content-between mb-4">
                <span>Bitte bewerten Sie die <strong>Wirkungsrichtung(en)</strong>:</span>

                <!-- <button type="button" class="btn text-muted" data-bs-toggle="collapse" data-bs-target="#collapseWirkungsrichtung" aria-expanded="false" aria-controls="collapseWirkungsrichtung"><font-awesome-icon icon="circle-info" /></button> -->
              </div>
<!-- 
              <div class="collapse mb-4 p-3 border border-primary rounded" id="collapseWirkungsrichtung">
                <strong>Hinweise Wirkungsrichtung:</strong> <br>
                - Inside-Out => wir verursachen eine positive oder negative Wirkung <br>
                - Outside-In => das Thema hat eine Auswirkung auf unser Unternehmen (+/-) und oder es entstehen (finanzielle) Chancen und/oder Risiken 
              </div> -->

              <div>
                <div class="form-check mb-2">
                  <input class="form-check-input" type="radio" :value="2" :id="'insideout-' + frage.id" v-model="frage.wirkungsrichtung">
                  <label class="form-check-label" :for="'insideout-' + frage.id">
                    <span>Inside – Out (Materiell)</span>
                  </label>
                </div>

                <div class="form-check mb-2">
                  <input class="form-check-input" type="radio" :value="1" :id="'outsiedin-' + frage.id" v-model="frage.wirkungsrichtung">
                  <label class="form-check-label" :for="'outsiedin-' + frage.id">
                    <span>Outside – In (Finanziell)</span>
                  </label>
                </div>

                <div class="form-check mb-2">
                  <input class="form-check-input" type="radio" :value="3" :id="'bothways-' + frage.id" v-model="frage.wirkungsrichtung">
                  <label class="form-check-label" :for="'bothways-' + frage.id">
                    <span>In beiden Richtungen</span>
                  </label>
                </div>

                <div class="form-check mb-2">
                  <input class="form-check-input" type="radio" :value="4" :id="'not-relevant-' + frage.id" v-model="frage.wirkungsrichtung">
                  <label class="form-check-label" :for="'not-relevant-' + frage.id">
                    <span>Betrifft uns nicht</span>
                  </label>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="radio" :value="5" :id="'richtung-dont-' + frage.id" v-model="frage.wirkungsrichtung">
                  <label class="form-check-label" :for="'richtung-dont-' + frage.id">
                    <span>Ich weiß nicht</span>
                  </label>
                </div>
              </div>
            </div>

            <hr class="my-3">

            <div v-if="frage.wirkungsrichtung > 0 && frage.wirkungsrichtung < 4" class="py-2 px-3">
              <div class="d-flex justify-content-between mb-4">
                <strong>Wann bzw. über welchen Zeitraum ist die Wirkung zu erwarten?</strong>

                <!-- <button type="button" class="btn text-muted" data-bs-toggle="collapse" data-bs-target="#collapseWirkungszeitraum" aria-expanded="false" aria-controls="collapseWirkungszeitraum"><font-awesome-icon icon="circle-info" /></button> -->
              </div>

              <!-- <div class="collapse mb-4 p-3 border border-primary rounded" id="collapseWirkungszeitraum">
                Kurfristig: bis zu 12 Monate <br>
                Mittelfristig: bis zu 5 Jahre  <br>
                Lanfristig: mehr als 5 Jahre 
              </div> -->

              <div>
                <div class="form-check mb-2">
                  <input class="form-check-input" type="checkbox" value="" :id="'shortterm-' + frage.id" v-model="frage.shortTerm">
                  <label class="form-check-label" :for="'shortterm-' + frage.id">
                    <span>Kurzfristig: bis zu 12 Monate</span>
                  </label>
                </div>
                      
                <div class="form-check mb-2">
                  <input class="form-check-input" type="checkbox" value="" :id="'mediumterm-' + frage.id" v-model="frage.mediumTerm">
                  <label class="form-check-label" :for="'mediumterm-' + frage.id">
                    <span>Mittelfristig: bis zu 5 Jahre</span>
                  </label>
                </div>

                <div class="form-check mb-2">
                  <input class="form-check-input" type="checkbox" value="" :id="'longterm-' + frage.id" v-model="frage.longTerm">
                  <label class="form-check-label" :for="'longterm-' + frage.id">
                    <span>Langfristig: mehr als 5 Jahre</span>
                  </label>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" :id="'zeitraum-dont-' + frage.id" v-model="frage.termDontKnow">
                  <label class="form-check-label" :for="'zeitraum-dont-' + frage.id">
                    <span>Kann ich nicht beurteilen</span>
                  </label>
                </div>
              </div>
            </div>
            
            <hr class="my-3">

            <div class="row my-3 px-3">
              <div class="col">
                <button class="btn btn-primary w-100" @click="goFrageBack()" :disabled="displayFrage == 0">
                  <span>Zurück</span>
                </button>
              </div>

              <div class="col">
                <!-- <button class="btn btn-info w-100" @click="goBackToStart()" disabled> -->
                <button class="btn btn-outline-secondary w-100" @click="displaySection = 4">
                  <span>Pause</span>
                </button>
              </div>

              <div class="col">
                <button class="btn btn-primary w-100" @click="goFrageNext()" :disabled="disableNextButton">
                  <span>Weiter</span>
                </button>
              </div>
            </div>

            <div class="row my-3 px-3">
              <div class="col">
                <div class="progress" role="progressbar" aria-label="Default striped example" :aria-valuenow="index" :aria-valuemin="0" :aria-valuemax="100">
                  <div class="progress-bar progress-bar-striped" :style="'width: ' + getPercent(index, displayTeilnehmerAntworten.length) + '%'"></div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section v-else-if="displaySection == 4">
      <div class="row">
        <div class="col col-md-6 offset-md-3 bg-white rounded shadow p-3">
          <LoadingSpinner v-if="updateTeilnehmerantwortenLoading" text="...Ihre Antworten werden gespeichert..." />

          <div v-else>
            <h3 v-if="language == 'en'">Save Answers</h3>
            <h3 v-else>Antworten speichern</h3>
            
            <p v-if="language == 'en'">If you want to continue the survey at a later time, use the following link:</p>
            <p v-else>Falls Sie zu einem späteren Zeitpunkt mit der Umfrage fortfahren möchten, verwenden Sie folgenden Link:</p>

            <div class="input-group mb-3">
              <input type="text" class="form-control" aria-label="Umfrage Link" aria-describedby="button-addon" :value="currentUrl" disabled>
              <button class="btn btn-outline-secondary" type="button" id="umfrage-link-button" @click="copyToClipboard()"><font-awesome-icon icon="copy" class="fs-sm" /></button>
            </div>

            <div v-if="teilnehmerAntwortErrorMsg != ''" class="alert alert-danger">
              <span v-if="language == 'en'">An error occurred while saving your answers.</span>
              <span v-else>Es ist ein Fehler beim speichern Ihrer Antworten aufgetreten.</span>
              {{ teilnehmerAntwortErrorMsg }}
            </div>
            
            <!-- <button class="btn btn-primary float-end" type="button" @click="saveTeilnehmerAntworten()">Save and Finish Answers</button> -->
            <router-link v-if="language == 'en'" :to="{name: 'Home'}" class="btn btn-primary float-end" type="button" >Save Answers and Finish</router-link>
            <router-link v-else :to="{name: 'Home'}" class="btn btn-primary float-end" type="button" >Antworten speichern und beenden</router-link>
          </div>
        </div>
      </div>
    </section>

    <div v-else class="alert alert-danger">
      Es ist etwas schiefgelaufen. Bitte probieren Sie es später erneut.
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import { ActionTypes as UnternehmenActionTypes } from '@/store/modules/Unternehmen/actions';
import { ActionTypes as UmfrageActionTypes } from '@/store/modules/Umfrage/actions';
import { ActionTypes as TeilnehmerActionTypes } from '@/store/modules/Teilnehmer/actions';
import { ActionTypes as TeilnehmerAntwortActionTypes } from '@/store/modules/TeilnehmerAntwort/actions';
import { ActionTypes as FragenkatalogActionTypes } from '@/store/modules/Fragenkatalog/actions';
import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions"

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { Teilnehmer, TeilnehmerEmail } from '@/models/TeilnehmerModels';
import { Umfrage } from '@/models/UmfrageModels';
import { TeilnehmerAntwort, DisplayTeilnehmerAntwort } from '@/models/TeilnehmerAntwortModels';
import { Fragenkatalog } from '@/models/FragenkatalogModels';

export default defineComponent({
  name: "Umfrage",

  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const language = computed((() => store.getters.language))

    const displaySection = ref(0)
    const displayFrage = ref(0)

    const currentUrl = computed(() => {
      return window.location.href
    })

    function goFrageBack() {
      if (displayFrage.value > 0) {
        displayFrage.value--;
        router.replace({name: "Umfrage", query: {firma: firmennummer.value, umfrage: selectedUmfrage.value?.guid, teilnehmer: teilnehmer.value.guid, section: 3, question: displayFrage.value, language: language.value}})
      }
    }

    function goFrageNext() {
      displayTeilnehmerAntworten.value[displayFrage.value].gesehen = true
      displayTeilnehmerAntworten.value[displayFrage.value].beantwortet = true
      if (displayFrage.value < displayTeilnehmerAntworten.value.length - 1) {
        displayFrage.value++;
        router.replace({name: "Umfrage", query: {firma: firmennummer.value, umfrage: selectedUmfrage.value?.guid, teilnehmer: teilnehmer.value.guid, section: 3, question: displayFrage.value, language: language.value}})
      }
      else {
        displaySection.value = 4
        router.replace({name: "Umfrage", query: {firma: firmennummer.value, umfrage: selectedUmfrage.value?.guid, teilnehmer: teilnehmer.value.guid, section: 4, question: displayFrage.value, language: language.value}})
      }
    }

    const disableNextButton = computed(() => {
      var frage = displayTeilnehmerAntworten.value[displayFrage.value]
      if (frage.wirkungsrichtung == 4) {
        return false
      }
      if (frage.wirkungsrichtung == 5) {
        return false
      }
      if ((frage.shortTerm == true || frage.mediumTerm == true || frage.longTerm == true || frage.termDontKnow == true) && (frage.wirkungsrichtung > 0 && frage.wirkungsrichtung < 4)) {
        return false
      }
      else {
        return true
      }
    })

    const fragenkatalog = computed(() => store.getters.fragenkatalog)
    
    async function loadFragenkatalog (): Promise<void> {
      return await store.dispatch(FragenkatalogActionTypes.GetFragenkatalog, undefined)
    }

    const firmennummer = ref("")
    const unternehmen = computed(() => store.getters.unternehmen)

    const unternehmenLoading = computed(() => store.getters.status.getUnternehmenLoading)
    const unternehmenErrorMsg = computed(() => store.getters.status.unternehmenErrorMsg)

    const umfragen = computed(() => store.getters.umfragen.filter((u: Umfrage) => u.unternehmenGuid == unternehmen.value.guid))
    const umfrageLoading = computed(() => store.getters.status.getUmfrageLoading)
    const umfrageErrorMsg = computed(() => store.getters.status.umfrageErrorMsg)
    const umfrageGuid = ref("");
    const selectedUmfrage = ref<Umfrage>();

    async function getUnternehmen() {
      
      await store.dispatch(UnternehmenActionTypes.GetUnternehmenByFirmennummer, firmennummer.value.trim())
      if (unternehmenErrorMsg.value == "") {
        await store.dispatch(UmfrageActionTypes.GetActiveUmfragenByUnternehmenguid, unternehmen.value.guid.trim())
        
        if (umfrageErrorMsg.value == "") {
          // route.query.firemennummer = firmennummer.value

          router.replace({name: "Umfrage", query: {firma: firmennummer.value, section: 1, language: language.value}})

          if (umfrageGuid.value != "") {
            selectedUmfrage.value = umfragen.value.find((u: Umfrage) => u.guid == umfrageGuid.value)
            router.replace({name: "Umfrage", query: {firma: firmennummer.value, umfrage: selectedUmfrage.value?.guid, section: 2, language: language.value}})
            
            displaySection.value = 2;
          }
          if (umfragen.value.length == 1 && selectedUmfrage.value == null) {
            selectedUmfrage.value = umfragen.value[0]
            router.replace({name: "Umfrage", query: {firma: firmennummer.value, umfrage: selectedUmfrage.value?.guid, section: 2, language: language.value}})

            displaySection.value = 2;
          }
          else {
            displaySection.value = 1;
          }
        }
      }
    }

    function selectUmfurage(umfrage: Umfrage) {
      selectedUmfrage.value = umfrage;
      router.replace({name: "Umfrage", query: {firma: firmennummer.value, umfrage: selectedUmfrage.value?.guid, section: 2, language: language.value}})
      displaySection.value = 2;
    }
    
    function goBackToFirmennummer() {
      router.replace({name: "Umfrage", query: {language: language.value}})
      firmennummer.value = "";
      displaySection.value = 0;
    }

    const teilnehmerLoading = computed(() => store.getters.status.createTeilnehmerLoading);
    // const getTeilnehmerLoading = computed(() => store.getters.status.getTeilnehmerLoading);
    const teilnehmerErrorMsg = computed(() => store.getters.status.teilnehmerErrorMsg);

    const teilnehmer = computed(() => store.getters.teilnehmer);

    function getTeilnehmerByGuid(guid: string) {
      store.dispatch(TeilnehmerActionTypes.GetTeilnehmer, guid)
        .then(() => {
          if (teilnehmerErrorMsg.value == "") {

            getTeilnehmerAntworten(teilnehmer.value.guid)
              .then(() => {
                if (displayTeilnehmerAntworten.value.length > 0) {
                  displaySection.value = 3
                  router.replace({name: "Umfrage", query: {firma: firmennummer.value, umfrage: selectedUmfrage.value?.guid, teilnehmer: teilnehmer.value.guid, section: 3, question: displayFrage.value, language: language.value}})
                }
                else {
                  createTeilnehmerAntworten()
                    .then(res => {
                      displaySection.value = 3
                      router.replace({name: "Umfrage", query: {firma: firmennummer.value, umfrage: selectedUmfrage.value?.guid, teilnehmer: teilnehmer.value.guid, section: 3, question: 0, language: language.value}})
                    })
                }
              })
            }
        })
    }

    const neuerTeilnehmer = ref(new Teilnehmer())
    async function createTeilnehmer() {
      neuerTeilnehmer.value.umfrageGuid = selectedUmfrage.value?.guid || "";

      store.dispatch(TeilnehmerActionTypes.CreateTeilnehmer, neuerTeilnehmer.value)
        .then(() => {
          if (teilnehmerErrorMsg.value == "") {
            createTeilnehmerAntworten()
              .then(() => {
                displaySection.value = 3
                router.replace({name: "Umfrage", query: {firma: firmennummer.value, umfrage: selectedUmfrage.value?.guid, teilnehmer: teilnehmer.value.guid, section: 3, question: 0, language: language.value}})
              })
            }
        })
      
    }

    const teilnehmerantworten = computed(() => store.getters.teilnehmerantworten)
    const teilnehmerAntwortLoading = computed(() => store.getters.status.createTeilnehmerAntwortenLoading)
    const updateTeilnehmerantwortenLoading = computed(() => store.getters.status.updateTeilnehmerAntwortLoading)
    const teilnehmerAntwortErrorMsg = computed(() => store.getters.status.teilnehmerAntwortErrorMsg)

    async function createTeilnehmerAntworten(): Promise<boolean> {
      const data = new Array<TeilnehmerAntwort>()

      for (var frage of fragenkatalog.value) {
        var ta = new TeilnehmerAntwort();
        ta.fragenkatalogid = frage.id;
        ta.frageDE = frage.frageDE;
        ta.teilnehmerGuid = teilnehmer.value.guid;

        data.push(ta);
      }

      return store.dispatch(TeilnehmerAntwortActionTypes.CreateTeilnehmerantwortRange, data)
        .then(() => {
          if (teilnehmerAntwortErrorMsg.value == "") {
            for (var antwort of teilnehmerantworten.value) {
              displayTeilnehmerAntworten.value.push(new DisplayTeilnehmerAntwort(antwort, getFragenkatalogFrage(antwort.fragenkatalogid)))
            }

            return true
          }
          else {
            return false
          }
        })
    }

    async function getTeilnehmerAntworten(guid: string): Promise<boolean> {
      store.dispatch(TeilnehmerAntwortActionTypes.ClearTeilnehmerantwort, undefined);
      return store.dispatch(TeilnehmerAntwortActionTypes.GetTeilnehmerantwortByTeilnehmerguid, guid)
        .then(() => {
          if (teilnehmerAntwortErrorMsg.value == "") {
            for (var antwort of teilnehmerantworten.value) {
              displayTeilnehmerAntworten.value.push(new DisplayTeilnehmerAntwort(antwort, getFragenkatalogFrage(antwort.fragenkatalogid)))
            }

            return true
          }
          else {
            return false
          }
        })
    }

    const displayTeilnehmerAntworten = ref(new Array<DisplayTeilnehmerAntwort>())

    function getFragenkatalogFrage(id: number) {
      return fragenkatalog.value.find((f: Fragenkatalog) => f.id == id)
    }

    const teilnehmerLink = computed(() => {
      const questionNr = teilnehmerantworten.value.filter((ta: TeilnehmerAntwort) => ta.beantwortet == 1).length
      return window.location.origin + `/umfrage?firma=${unternehmen.value?.firmennummer}&umfrage=${umfrageGuid.value}&teilnehmer=${teilnehmer.value.guid}&section=3&question=${questionNr}&language=de`;
    })
    
    async function sendTeilnehmerEmail() {

      const data = new TeilnehmerEmail();
      data.guid = teilnehmer.value.guid;
      data.subject = "Ihr persönlicher Link zur Wesentlichkeitsanalyse Umfrage";
      data.message = `
        <p>Hallo ${teilnehmer.value.name}</p>
        <p>Vielen Dank für Ihre Teilnahme!</p>
        <p>Falls Sie zu einem späteren Zeitpunkt mit der Umfrage fortfahren möchten, klicken Sie auf "Zur Umfrage":</p>
        <p><a href="${teilnehmerLink.value}" style="color: #1a73e8; text-decoration: none;">Zur Umfrage</a></p>
        <p>Wir freuen uns auf Ihre Rückmeldungen und danken Ihnen für Ihre Zeit und Unterstützung.</p>
        <p>Sollten Sie Fragen zur Umfrage haben, zögern Sie bitte nicht, Ihren Verantwortlichen zu kontaktieren.</p>
        <p>Mit freundlichen Grüßen,<br>Ihr 1.A Nachhaltigkeits Team</p>
      `;

      await store.dispatch(TeilnehmerActionTypes.SendTeilnehmerEmail, data)
    }

    async function saveTeilnehmerAntworten() {
      await sendTeilnehmerEmail();

      const ende = new Date()
      const timespan = Math.round((ende.getTime() - start.value.getTime()) / (1000 * 60));
      teilnehmer.value.umfragedauer += timespan;
      store.dispatch(TeilnehmerActionTypes.UpdateTeilnehmer, teilnehmer.value)

      // remove start from sessionStorage
      sessionStorage.removeItem("umfragestart")

      const data = new Array<TeilnehmerAntwort>();

      for (var frage of displayTeilnehmerAntworten.value) {
        var ta = new TeilnehmerAntwort();
        ta.setValuesFromDisplayAntowrt(frage);
        data.push(ta);
      }

      store.dispatch(TeilnehmerAntwortActionTypes.UpdateTeilnehmerantwortRange, data)
        .then(() => {
          if (teilnehmerAntwortErrorMsg.value == "") {
            if (displaySection.value == 4) {
              router.push({name: "Home"})
              sessionStorage.clear();
            }
          }
        })
    }

    async function readURLQueryParams() {
      umfrageGuid.value = route.query.umfrage != null ? route.query.umfrage.toString() : "";

      // var firma = route.query.firma != null ? route.query.firma : "";
      if (route.query.firma != null && route.query.firma != "") {
        firmennummer.value = route.query.firma.toString();
        await getUnternehmen()
      }
      
      if (route.query.teilnehmer != null && route.query.teilnehmer != "") {
        getTeilnehmerByGuid(route.query.teilnehmer.toString())
      }
      
      if (route.query.language != null && route.query.language != "" && route.query.language != language.value) {
        store.dispatch(CommonActionTypes.SetLanguage, route.query.language.toString())
      }

      displaySection.value = route.query.section != null ? Number(route.query.section) : 0;
      displayFrage.value = route.query.question != null ? Number(route.query.question) : 0;
    }

    function copyToClipboard() {
      navigator.clipboard.writeText(currentUrl.value);
    }

    const start = ref(new Date())

    return {
      route,
      router,

      language,
      
      fragenkatalog,
      loadFragenkatalog,
      
      displaySection,
      displayFrage,
      goFrageBack,
      goFrageNext,
      disableNextButton,

      firmennummer,
      
      unternehmenLoading,
      unternehmenErrorMsg,
      getUnternehmen,
      unternehmen,
      
      umfragen,
      selectUmfurage,
      goBackToFirmennummer,
      selectedUmfrage,
      umfrageLoading,
      umfrageErrorMsg,

      neuerTeilnehmer,
      teilnehmer,
      teilnehmerLoading,
      teilnehmerErrorMsg,
      createTeilnehmer,
      teilnehmerantworten,
      teilnehmerAntwortLoading,
      updateTeilnehmerantwortenLoading,
      teilnehmerAntwortErrorMsg,
      displayTeilnehmerAntworten,

      saveTeilnehmerAntworten,

      readURLQueryParams,
      currentUrl,
      copyToClipboard,
      start
    }
  },

  methods: {
    questionBg(titel: string): string {
      if (titel.toLowerCase() == "umwelt") {
        return "bg-umwelt"
      }
      else if (titel.toLowerCase() == "sozial") {
        return "bg-sozial text-white"
      }
      else if (titel.toLowerCase() == "governance") {
        return "bg-gov text-white"
      }
      else {
        return "bg-primary"
      }
    },

    getPercent(index: number, length: number): number {
      return (index / length) * 100;
    }
  },

  mounted () {
    if (sessionStorage.getItem("umfragestart") != null) {
      this.start = new Date(JSON.parse(sessionStorage.umfragestart))
    }
    else {
      sessionStorage.setItem("umfragestart", JSON.stringify(this.start));
    }

    this.loadFragenkatalog()
      .then(() => {
        this.readURLQueryParams();
      })
  },

  beforeUnmount() {
    if (this.displayTeilnehmerAntworten.length > 0) {
      this.saveTeilnehmerAntworten();
    }
    else {
      sessionStorage.clear();
    }
  }
})
</script>

<style lang="scss">

.bg-umwelt {
  background-color: #95C92C;
}

.bg-sozial {
  background-color: #C9452C;
}

.bg-gov {
  background-color: #2C60C9;
}

.select-umfrage:hover {
  cursor: pointer;
  background: #1b3c7d18 !important;
}

</style>