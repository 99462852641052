<template>
  <router-link :to="{name: 'DetailsUnternehmen', params: { guid: guid}}" class="btn btn-outline-secondary mb-2"><font-awesome-icon icon="chevron-left" /> Zurück</router-link>

  <h1>Settings</h1>

  
  <div class="bg-white shadow-sm p-3">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="border-bottom flex-fill">{{ unternehmen?.firmenname }}</h1>
      
      <router-link v-if="unternehmen != null" :to="{name: 'EditUnternehmen', query: {unternehmenguid: guid}}" class="btn"><font-awesome-icon icon="pen" /></router-link>
    </div>

    <p><strong>Firmennummer:</strong> {{ unternehmen?.firmennummer }}</p> 

    <div class="row">
      <div class="col">
        <p><strong>Strase:</strong> {{ unternehmen?.strasse }}</p> 
        <p><strong>Plz/Ort:</strong> {{ unternehmen?.plz }} {{ unternehmen?.ort }}</p> 
        <p><strong>Land:</strong> {{ unternehmen?.land }}</p> 
      </div>

      <div class="col">
        <p><strong>Ansprechpartner:</strong> {{ unternehmen?.ansprechpartner }}</p> 
        <p><strong>Ansprechpartner E-Mail:</strong> {{ unternehmen?.ansprechpartnerEmail }}</p> 
        <p><strong>Ansprechpartner Telefon:</strong> {{ unternehmen?.ansprechpartnerTel }}</p> 
      </div>
    </div>
  </div>
    
  <hr class="my-5">

  
  
  <div v-if="berater.length > 0">
    <h1>Berater</h1>
    
    <div v-for="b in berater" :key="b.id" class="row ms-2">
      <div v-for="b in berater" :key="b.id" class="col-5 row bg-white shadow my-auto py-2">
        <div class="col fw-bold">
          {{ b.vorname }} {{ b.name }}
        </div>
        <div class="col">
          {{ b.email }}
        </div>
      </div>

      <div class="col-1 my-auto">
        <button class="btn m-0" @click="removeBerater(b)"><font-awesome-icon icon="trash" /></button>
      </div>
    </div>
    
  </div>
  
  <div v-else class="bg-white shadow-sm p-3">
    <div class="w-50">
      <h2>Berater hinzufügen</h2>

      <label for="berater-email">Berater E-Mail</label>
      <div class="input-group mb-3">
        <input type="text" class="form-control" aria-label="Berater E-Mail" aria-describedby="berater-email" v-model="beraterEmail">
        <button class="btn btn-primary" type="button" id="berater-email-button" @click="addBerater()">Hinzufügen</button>
      </div>
    </div>

    <div v-if="errorMsg != ''" class="alert alert-danger mt-3">
      Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
    </div>
  </div>


  <hr class="my-5">

  <BenutzerView />

  <LoadingOverlay v-if="loading" />
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { ActionTypes as BenutzerActionTypes } from '@/store/modules/Benutzer/actions';

import { Benutzer, BeraterUnternehmenRequest } from '@/models/BenutzerModels';

import BenutzerView from '../BenutzerView.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';

export default defineComponent({
  name: "UnternehmenSettingsView",

  components: {
    LoadingOverlay,
    BenutzerView,
  },

  props: {
    guid: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const loading = computed(() => store.getters.status.createBenutzerUnternehmenLoading || store.getters.status.deleteBenutzerUnternehmenLoading);
    const errorMsg = computed(() => store.getters.status.benutzerUnternehmenErrorMsg);

    const user = computed(() => store.getters.authUser?.benutzer);
    const unternehmen = computed(() => store.getters.unternehmenliste.find((u: any) => u.guid == props.guid))

    const berater = computed<Array<Benutzer>>(() => store.getters.benutzer.filter((b: Benutzer) => b.benutzerrolleId == 2 || b.benutzerrolleId == 1));

    const beraterEmail = ref("");

    function addBerater() {
      const berater = new BeraterUnternehmenRequest(beraterEmail.value, props.guid);
      store.dispatch(BenutzerActionTypes.AddBeraterUnternehmen, berater)
      .then(() => {
        beraterEmail.value = "";
      });
    }

    function removeBerater(benutzer: Benutzer) {
      const data = benutzer.benutzerUnternehmen.find((bu) => bu.unternehmenGuid == props.guid);
      if (data != undefined) {
        store.dispatch(BenutzerActionTypes.RemoveBenutzerUnternehmen, data)
        .then(() => {
          if (user.value.benutzerrolleId == 2) {
            router.push({name: 'Unternehmen'})
          }
        });
      }
    }

    function loadBenutzer() {
      store.dispatch(BenutzerActionTypes.GetBenutzer, props.guid);
    }

    return {
      loading,
      errorMsg,

      user,
      unternehmen,

      berater,
      beraterEmail,

      addBerater,
      removeBerater,

      loadBenutzer
    }
  },

  mounted() {
    this.loadBenutzer();
  }
})
</script>