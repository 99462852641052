import { MutationTree } from "vuex"
import { State } from "./state"
import { LongList, SetLongListStakeholderObject, Umfrage } from "@/models/UmfrageModels"

export enum MutationTypes {
  SetUmfragen = "SETUMFRAGEN",
  AddUmfrage = "ADDUMFRAGE",
  UpdateUmfrage = "UPDATEUMFRAGE",
  DeleteUmfrage = "DELETEUMFRAGE",

  SetLonglist = "SETLONGLIST",
  UpdateLonglist = "UPDATELONGLIST",
  DeleteLonglist = "DELETELONGLIST",

  SetLonglistStakeholder = "SETLONGLISTSTAKEHOLDER",
  DeleteLonglistStakeholder = "DELETELONGLISTSTAKEHOLDER",
}

export type Mutations = {
  [MutationTypes.SetUmfragen](state: State, umfrageliste: Array<Umfrage>): void
  [MutationTypes.AddUmfrage](state: State, umfrage: Umfrage): void
  [MutationTypes.UpdateUmfrage](state: State, umfrage: Umfrage): void
  [MutationTypes.DeleteUmfrage](state: State, umfrageguid: string): void

  [MutationTypes.SetLonglist](state: State, longlist: Array<LongList>): void
  [MutationTypes.UpdateLonglist](state: State, longlist: LongList): void
  [MutationTypes.DeleteLonglist](state: State, longlist: LongList): void

  [MutationTypes.SetLonglistStakeholder](state: State, data: SetLongListStakeholderObject): void

}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetUmfragen](state, umfrageliste) {
    state.umfragen = umfrageliste;
    sessionStorage.setItem("umfragen", JSON.stringify(state.umfragen));
  },

  [MutationTypes.AddUmfrage](state, umfrage) {
    state.umfragen.push(umfrage);
    sessionStorage.setItem("umfragen", JSON.stringify(state.umfragen));
  },

  [MutationTypes.UpdateUmfrage](state, umfrage) {
    const oldumfrage = state.umfragen.find(a => a.guid === umfrage.guid)
    if (oldumfrage != null) {
      const index = state.umfragen.indexOf(oldumfrage)
      state.umfragen.splice(index, 1, umfrage)
    }

    sessionStorage.setItem("umfragen", JSON.stringify(state.umfragen));
  },

  [MutationTypes.DeleteUmfrage](state, umfrageguid) {
    const oldumfrage = state.umfragen.find(a => a.guid === umfrageguid)
    if (oldumfrage != null) {
      const index = state.umfragen.indexOf(oldumfrage)
      state.umfragen.splice(index, 1)
    }

    sessionStorage.setItem("umfragen", JSON.stringify(state.umfragen));
  },

  [MutationTypes.SetLonglist](state, longlist) {
    if (longlist.length > 0) {
      const umfrage = state.umfragen.find(a => a.guid === longlist[0].umfrageGuid)
      if (umfrage != null) {
        umfrage.longlist = longlist;
      } 

      sessionStorage.setItem("umfragen", JSON.stringify(state.umfragen));
    }
  },

  [MutationTypes.UpdateLonglist](state, longlist) {
    const umfrage = state.umfragen.find(a => a.guid === longlist.umfrageGuid)
    if (umfrage != null) {
      const index = umfrage.longlist.findIndex(a => a.id === longlist.id)
      if (index != -1) {
        umfrage.longlist.splice(index, 1, longlist)
      }
    }

    sessionStorage.setItem("umfragen", JSON.stringify(state.umfragen));
  },
  
  [MutationTypes.DeleteLonglist](state, longlist) {
    const umfrage = state.umfragen.find(a => a.guid === longlist.umfrageGuid)
    if (umfrage != null) {
      const index = umfrage.longlist.findIndex(a => a.id === longlist.id)
      if (index != -1) {
        umfrage.longlist.splice(index, 1)
      }
    }

    sessionStorage.setItem("umfragen", JSON.stringify(state.umfragen));
  },

  [MutationTypes.SetLonglistStakeholder](state, data) {
    const umfrage = state.umfragen.find(a => a.guid === data.umfrageGuid)
    if (umfrage != null) {
      for (let longlist of umfrage.longlist) {
        const stakeholder = data.stakeholder.filter(a => a.longlistid === longlist.id);
        if (stakeholder.length > 0) {
          longlist.stakeholder = stakeholder
        }
      }
    }
  }
}