import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
// import ApiService from "@/services/ApiService";
import { ApiService } from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"
import { TeilnehmerAntwort } from "@/models/TeilnehmerAntwortModels"
import { ErrorResponseData } from '@/models/CommonModels';

export enum ActionTypes {
  GetTeilnehmerantwort = "GETTEILNEHMERANTWORT",
  GetTeilnehmerantwortByTeilnehmerguid = "GETTEILNEHMERANTWORTBYTEILNEHMERGUID",
  CreateTeilnehmerantwort = "CREATETEILNEHMERANTWORT",
  CreateTeilnehmerantwortRange = "CREATETEILNEHMERANTWORTRANGE",
  UpdateTeilnehmerantwort = "UPDATETEILNEHMERANTWORT",
  UpdateTeilnehmerantwortRange = "UPDATETEILNEHMERANTWORTRANGE",
  DeleteTeilnehmerantwort = "DELETETEILNEHMERANTWORT",
  ClearTeilnehmerantwort = "CLEARTEILNEHMERANTWORT",
  RemoveTeilnehmerantwortenByTeilnehmerguid = "REMOVETEILNEHMERANTWORTENBYTEILNEHMERGUID"
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetTeilnehmerantwort](context: ActionArguments): Promise<void>
  [ActionTypes.GetTeilnehmerantwortByTeilnehmerguid](context: ActionArguments, guid: string): Promise<void>
  [ActionTypes.CreateTeilnehmerantwort](context: ActionArguments, data: TeilnehmerAntwort): Promise<void>
  [ActionTypes.CreateTeilnehmerantwortRange](context: ActionArguments, data: Array<TeilnehmerAntwort>): Promise<void>
  [ActionTypes.UpdateTeilnehmerantwort](context: ActionArguments, data: TeilnehmerAntwort): Promise<void>
  [ActionTypes.UpdateTeilnehmerantwortRange](context: ActionArguments, data: Array<TeilnehmerAntwort>): Promise<void>
  [ActionTypes.DeleteTeilnehmerantwort](context: ActionArguments, teilnehmerantwortid: number): Promise<void>
  [ActionTypes.ClearTeilnehmerantwort](context: ActionArguments): void
  [ActionTypes.RemoveTeilnehmerantwortenByTeilnehmerguid](context: ActionArguments, teilnehmerguid: string): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.ClearTeilnehmerantwort]({commit}) {
    commit(MutationTypes.SetTeilnehmerantworten, new Array<TeilnehmerAntwort>())
  },

  async [ActionTypes.GetTeilnehmerantwort]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getTeilnehmerAntwortLoading = true;
    status.teilnehmerAntwortErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllTeilnehmerAntwort()
      .then(res => {
        const data: Array<TeilnehmerAntwort> = res.data
        commit(MutationTypes.SetTeilnehmerantworten, data)
      })
      .catch(error => {
        status.teilnehmerAntwortErrorMsg = error.response;
      })
      .finally(() => {
        status.getTeilnehmerAntwortLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetTeilnehmerantwortByTeilnehmerguid]({commit, dispatch, rootGetters}, guid) {
    const status = rootGetters.status;
    status.getTeilnehmerAntwortLoading = true;
    status.teilnehmerAntwortErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllTeilnehmerAntwortByTeilnehmerguid(guid)
      .then(res => {
        const data: Array<TeilnehmerAntwort> = res.data
        commit(MutationTypes.UpdateOrAddTeilnehmerantwortenRange, data)
      })
      .catch(error => {
        status.teilnehmerAntwortErrorMsg = error.response;
      })
      .finally(() => {
        status.getTeilnehmerAntwortLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateTeilnehmerantwort]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createTeilnehmerAntwortLoading = true;
    status.teilnehmerAntwortErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.CreateTeilnehmerAntwort(data)
      .then(res => {
        const data: TeilnehmerAntwort = res.data
        commit(MutationTypes.AddTeilnehmerantworten, data)
      })
      .catch(error => {
        status.teilnehmerAntwortErrorMsg = error.response;
      })
      .finally(() => {
        status.createTeilnehmerAntwortLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateTeilnehmerantwortRange]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createTeilnehmerAntwortLoading = true;
    status.teilnehmerAntwortErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.CreateTeilnehmerAntwortRange(data)
      .then(res => {
        const data: Array<TeilnehmerAntwort> = res.data
        commit(MutationTypes.UpdateOrAddTeilnehmerantwortenRange, data)
      })
      .catch(error => {
        if (error.response.status === 400) {
          const err = error.response.data as ErrorResponseData;
          status.teilnehmerAntwortErrorMsg = err.title;
        }
        else {
          status.teilnehmerAntwortErrorMsg = error.response;
        }
      })
      .finally(() => {
        status.createTeilnehmerAntwortLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateTeilnehmerantwort]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateTeilnehmerAntwortLoading = true;
    status.teilnehmerAntwortErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.UpdateTeilnehmerAntwort(data)
      .then(res => {
        const data: TeilnehmerAntwort = res.data
        commit(MutationTypes.UpdateTeilnehmerantworten, data)
      })
      .catch(error => {
        status.teilnehmerAntwortErrorMsg = error.response;
      })
      .finally(() => {
        status.updateTeilnehmerAntwortLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateTeilnehmerantwortRange]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateTeilnehmerAntwortLoading = true;
    status.teilnehmerAntwortErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.UpdateTeilnehmerAntwortRange(data)
      .then(res => {
        const data: Array<TeilnehmerAntwort> = res.data
        commit(MutationTypes.UpdateOrAddTeilnehmerantwortenRange, data)
      })
      .catch(error => {
        if (error.response.status === 400) {
          const err = error.response.data as ErrorResponseData;
          status.teilnehmerAntwortErrorMsg = err.title;
        }
        else {
          status.teilnehmerAntwortErrorMsg = error.response;
        }

      })
      .finally(() => {
        status.updateTeilnehmerAntwortLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteTeilnehmerantwort]({commit, dispatch, rootGetters}, teilnehmerantwortid) {
    const status = rootGetters.status;
    status.deleteTeilnehmerAntwortLoading = true;
    status.teilnehmerAntwortErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.DeleteTeilnehmerAntwort(teilnehmerantwortid)
      .then(res => {
        commit(MutationTypes.DeleteTeilnehmerantworten, teilnehmerantwortid)
      })
      .catch(error => {
        status.teilnehmerAntwortErrorMsg = error.response;
      })
      .finally(() => {
        status.deleteTeilnehmerAntwortLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  [ActionTypes.RemoveTeilnehmerantwortenByTeilnehmerguid]({commit}, teilnehmerguid) {
    commit(MutationTypes.RemoveTeilnehmerantwortenByTeilnehmerguid, teilnehmerguid)
  },
}