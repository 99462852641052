<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm rounded">
    <div class="col-6 my-auto border-end cut-text" data-bs-toggle="tooltip" data-bs-placement="top" :title="titel">{{ titel }}</div>
    <div class="col-2 my-auto border-end">{{ longlist.zustimmung }}%</div>
    <div class="col-2 my-auto">{{ longlist.insideOutBewertung == 0 ? "-" : longlist.insideOutBewertung == 1 ? "Positiv" : "Negativ" }}</div>
    <div class="col-2 my-auto">{{ longlist.insideOutBewertungWert }}</div>
  </div>
</template>

<script lang="ts" setup>
//#region Imports
import { PropType, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

import { ActionTypes as FragenkatalogActionTypes } from '@/store/modules/Fragenkatalog/actions';

import { LongList } from '@/models/UmfrageModels';
import { Fragenkatalog } from '@/models/FragenkatalogModels';
import { Unternehmensthema } from '@/models/UnternehmensthemenModels';
//#endregion

const props = defineProps({
  longlist: {
    type: Object as PropType<LongList>,
    required: true
  },
})

const store = useStore()

const titel = computed(() => {
  if (props.longlist.frageid != null) {
    const frage = store.getters.fragenkatalog.find((f: Fragenkatalog) => f.id == props.longlist.frageid);
    return `${frage.nr}. ${frage.frageDE}`;
  }
  else if (props.longlist.themaid != null) {
    const thema = store.getters.unternehmensthemen.find((t: Unternehmensthema) => t.id == props.longlist.themaid);
    return `${thema.kategorie} - ${thema.thema}`;
  }
  else {
    return "unbekannt";
  }
  
})

onMounted(() => {
  if (store.getters.fragenkatalog.length == 0) {
    store.dispatch(FragenkatalogActionTypes.GetFragenkatalog, undefined);
  }
})
</script>