import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"

import { Status } from "@/models/CommonModels"

export type Getters = {
  status(state: State): Status,
  language(state: State): string

  publicPages(state: State): Array<string>
  userPages(state: State): Array<string>
  beraterPages(state: State): Array<string>
  adminPages(state: State): Array<string>
}

export const getters: GetterTree<State, RootState> & Getters = {
  status(state) {
    return state.status;
  },

  language(state) {
    return state.language;
  },

  publicPages(state) {
    return state.status.publicPages ;
  },

  userPages(state) {
    const pages = new Array<string>();
    pages.push(...state.status.publicPages);
    pages.push(...state.status.userPages);
    return pages;
  },

  beraterPages(state) {
    const pages = new Array<string>();
    pages.push(...state.status.publicPages);
    pages.push(...state.status.userPages);
    pages.push(...state.status.beraterPages);
    return pages;
  },

  adminPages(state) {
    const pages = new Array<string>();
    pages.push(...state.status.publicPages);
    pages.push(...state.status.userPages);
    pages.push(...state.status.beraterPages);
    pages.push(...state.status.adminPages);
    return pages;
  },
}