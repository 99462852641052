import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
// import ApiService from "@/services/ApiService";
import { ApiService } from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"
import { Actions as TeilnehmerAntwortActions, ActionTypes as TeilnehmerAntwortActionTypes} from "../TeilnehmerAntwort/actions"

import { Teilnehmer, TeilnehmerEmail } from '@/models/TeilnehmerModels';
import { ErrorResponseData } from '@/models/CommonModels';


export enum ActionTypes {
  GetTeilnehmer = "GETTEILNEHMER",
  CreateTeilnehmer = "CREATETEILNEHMER",
  UpdateTeilnehmer = "UPDATETEILNEHMER",

  GetTeilnehmerliste = "GETTEILNEHMERLISTE",
  GetTeilnehmerlisteByUmfrageguid = "GETTEILNEHMERLISTEBYUMFRAGEGUID",
  CreateTeilnehmerliste = "CREATETEILNEHMERLISTE",
  UpdateTeilnehmerliste = "UPDATETEILNEHMERLISTE",
  DeleteTeilnehmerliste = "DELETETEILNEHMERLISTE",

  SendTeilnehmerEmail = "SENDTEILNEHMEREMAIL",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetTeilnehmer](context: ActionArguments, guid: string): Promise<void>
  [ActionTypes.CreateTeilnehmer](context: ActionArguments, data: Teilnehmer): Promise<void>
  [ActionTypes.UpdateTeilnehmer](context: ActionArguments, data: Teilnehmer): Promise<void>

  [ActionTypes.GetTeilnehmerliste](context: ActionArguments): Promise<void>
  [ActionTypes.GetTeilnehmerlisteByUmfrageguid](context: ActionArguments, guid: string): Promise<void>
  [ActionTypes.CreateTeilnehmerliste](context: ActionArguments, data: Teilnehmer): Promise<void>
  [ActionTypes.UpdateTeilnehmerliste](context: ActionArguments, data: Teilnehmer): Promise<void>
  [ActionTypes.DeleteTeilnehmerliste](context: ActionArguments, teilnehmerguid: string): Promise<void>

  [ActionTypes.SendTeilnehmerEmail](context: ActionArguments, data: TeilnehmerEmail): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetTeilnehmer]({commit, dispatch, rootGetters}, guid) {
    const status = rootGetters.status;
    status.getTeilnehmerLoading = true;
    status.teilnehmerErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getTeilnehmerByGuid(guid)
      .then(res => {
        const data: Teilnehmer = res.data
        commit(MutationTypes.SetTeilnehmer, data)
      })
      .catch(error => {
        status.teilnehmerErrorMsg = error.response;
      })
      .finally(() => {
        status.getTeilnehmerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateTeilnehmer]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createTeilnehmerLoading = true;
    status.teilnehmerErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.CreateTeilnehmer(data)
      .then(res => {
        const data: Teilnehmer = res.data
        commit(MutationTypes.SetTeilnehmer, data)
      })
      .catch(error => {
        if (error.response.status == 400) {
          const err = error.response.data as ErrorResponseData;
          status.teilnehmerErrorMsg = err.title;
        }
        else {
          status.teilnehmerErrorMsg = error.response;
        }
      })
      .finally(() => {
        status.createTeilnehmerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateTeilnehmer]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateTeilnehmerLoading = true;
    status.teilnehmerErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);
    console.log(data);

    return ApiService.UpdateTeilnehmer(data)
      .then(res => {
        const data: Teilnehmer = res.data
        commit(MutationTypes.SetTeilnehmer, data)
      })
      .catch(error => {
        status.teilnehmerErrorMsg = error.response;
      })
      .finally(() => {
        status.updateTeilnehmerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },


  async [ActionTypes.GetTeilnehmerliste]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getTeilnehmerLoading = true;
    status.teilnehmerErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllTeilnehmer()
      .then(res => {
        const data: Array<Teilnehmer> = res.data
        commit(MutationTypes.SetTeilnehmerliste, data)
      })
      .catch(error => {
        status.teilnehmerErrorMsg = error.response;
      })
      .finally(() => {
        status.getTeilnehmerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetTeilnehmerlisteByUmfrageguid]({commit, dispatch, rootGetters}, guid) {
    const status = rootGetters.status;
    status.getTeilnehmerLoading = true;
    status.teilnehmerErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllTeilnehmerByUmfrageguid(guid)
      .then(res => {
        const data: Array<Teilnehmer> = res.data
        commit(MutationTypes.AddTeilnehmerlisteRange, data)
      })
      .catch(error => {
        status.teilnehmerErrorMsg = error.response;
      })
      .finally(() => {
        status.getTeilnehmerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateTeilnehmerliste]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createTeilnehmerLoading = true;
    status.teilnehmerErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.CreateTeilnehmer(data)
      .then(res => {
        const data: Teilnehmer = res.data
        commit(MutationTypes.AddTeilnehmerliste, data)
      })
      .catch(error => {
        if (error.response.status == 400) {
          const err = error.response.data as ErrorResponseData;
          status.teilnehmerErrorMsg = err.title;
        }
        else {
          status.teilnehmerErrorMsg = error.response;
        }
      })
      .finally(() => {
        status.createTeilnehmerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateTeilnehmerliste]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateTeilnehmerLoading = true;
    status.teilnehmerErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.UpdateTeilnehmer(data)
      .then(res => {
        const data: Teilnehmer = res.data
        commit(MutationTypes.UpdateTeilnehmerliste, data)
      })
      .catch(error => {
        status.teilnehmerErrorMsg = error.response;
      })
      .finally(() => {
        status.updateTeilnehmerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteTeilnehmerliste]({commit, dispatch, rootGetters}, teilnehmerguid) {
    const status = rootGetters.status;
    status.deleteTeilnehmerLoading = true;
    status.teilnehmerErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.DeleteTeilnehmer(teilnehmerguid)
      .then(res => {
        commit(MutationTypes.DeleteTeilnehmerliste, teilnehmerguid)
        dispatch(TeilnehmerAntwortActionTypes.RemoveTeilnehmerantwortenByTeilnehmerguid, teilnehmerguid)
      })
      .catch(error => {
        status.teilnehmerErrorMsg = error.response;
      })
      .finally(() => {
        status.deleteTeilnehmerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.SendTeilnehmerEmail]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.emailTeilnehmerLoading = true;
    status.teilnehmerErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.SendTeilnehmerEmail(data)
      .then(() => { })
      .catch(error => {
        status.teilnehmerErrorMsg = error.response;
      })
      .finally(() => {
        status.emailTeilnehmerLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

}