export class Fragenkatalog {
  id: number;
  nr: number;
  pflicht: number;
  quelle: string;
  aspektDE: string;
  aspektEN: string;
  themaDE: string;
  themaEN: string;
  frageDE: string;
  frageEN: string;
  beschreibungDE: string;
  beschreibungEN: string;
  isDeleted: number;
  isActive: number;

  constructor () {
    this.id = 0;
    this.nr = 0;
    this.pflicht = 1;
    this.quelle = "";
    this.aspektDE = "";
    this.aspektEN = "";
    this.themaDE = "";
    this.themaEN = "";
    this.frageDE = "";
    this.frageEN = "";
    this.beschreibungDE = "";
    this.beschreibungEN = "";
    this.isDeleted = 0;
    this.isActive = 0;
  }
}