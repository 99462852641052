<template>
  <div class="d-flex justify-content-between align-item-center">
    <button class="btn btn-outline-secondary mb-2" @click="router.back()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
  </div>

  <div v-if="umfrage == null || unternehmen == null" class="alert alert-danger">
    <p>Die Umfrage oder das Unternehmen konnte nicht gefunden werden.</p>
  </div>

  <div v-else>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="mb-0">Ergebnis</h1>
        <small>{{ unternehmen.firmenname }} - {{ umfrage.titel }}</small>
      </div>
    </div>

    <!-- <hr class="my-4"> -->

    <div v-if="umfrage.longlist == null || umfrage.longlist.length == 0" class="alert alert-info mb-5">
      <p>Es stehen noch keine Ergebnisse zur Verfügung.</p>
    </div>

    <div v-else>
      <div class="my-4 bg-white shadow p-3">
        <ErgebnisChart :data="longlist" title="Ergebnis" />
      </div>

      <!-- <hr class="my-4"> -->

      <div class="d-flex justify-content-end py-4">
        <button type="button" class="btn btn-outline-success" @click="exportData()">
          <font-awesome-icon icon="file-arrow-down" class="me-1" /> Excel Export
        </button>
      </div>

      <div class="row mx-1">
        <div class="col-6 border-end"></div>
        <div class="col-3 border-end">Inside-Out</div>
        <div class="col-3">Outside-In</div>
      </div>

      <div class="row py-2 mb-2 mx-1">
        <div class="col-4 my-auto border-end">Frage/Thema</div>
        <div class="col-2 my-auto border-end">Zustimmung</div>
        <div class="col-1 my-auto">Bewertung</div>
        <div class="col-2 my-auto border-end">Wert</div>
        <div class="col-1 my-auto">Bewertung</div>
        <div class="col-2 my-auto">Wert</div>
      </div>

      <ErgebnisLonglistRow v-for="item in longlist.sort((a: LongList, b: LongList) => b.zustimmung - a.zustimmung)" :key="item.id" :longlist="item" />
    </div>
  </div>

  <LoadingOverlay v-if="loading" />
</template>

<script lang="ts" setup>
  import { PropType, computed, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { writeFile as XLSXwriteFile, utils as XLSXutils } from 'xlsx';

  import { ActionTypes as UmfrageActionTypes } from '@/store/modules/Umfrage/actions';

  import LoadingOverlay from '@/components/LoadingOverlay.vue';
  import ErgebnisLonglistRow from '@/components/Umfrage/ErgebnisLonglistRow.vue';
  import ErgebnisChart from '@/components/Umfrage/Charts/ErgebnisChart.vue';

  import { Umfrage, LongList } from '@/models/UmfrageModels';
  import { Unternehmen } from '@/models/UnternehmenModels';
  import { Fragenkatalog } from '@/models/FragenkatalogModels';
  import { Unternehmensthema } from '@/models/UnternehmensthemenModels';

  const props = defineProps({
    unternehmenguid: {
      type: String as PropType<string>,
      required: true,
    },

    umfrageguid: {
      type: String as PropType<string>,
      required: true,
    },
  });

  const store = useStore();
  const router = useRouter();

  const loading = computed<boolean>(() => store.getters.status.getLonglistLoading);
  // const errorMsg = computed<string>(() => store.getters.status.longlistErrorMsg);

  const umfrage = computed<Umfrage>(() => store.getters.umfragen.find((u: Umfrage) => u.guid == props.umfrageguid));
  const unternehmen = computed<Unternehmen>(() => store.getters.unternehmenliste.find((u: Unternehmen) => u.guid == props.unternehmenguid));
  const fragenkatalog = computed<Array<Fragenkatalog>>(() => store.getters.fragenkatalog);
  const unternehmensthemen = computed<Array<Unternehmensthema>>(() => store.getters.unternehmensthemen);

  const longlist = computed<Array<LongList>>(() =>
    umfrage.value?.longlist.filter(
      (item: LongList) => item.insideOutBewertungWert > umfrage.value.insideOutWert || item.outsideInBewertungWert > umfrage.value.outsideInWert
    )
  );

  function loadLonglist() {
    store.dispatch(UmfrageActionTypes.GetUmfrageLonglist, props.umfrageguid);
  }

  onMounted(() => {
    loadLonglist();
  });

  //#region Excel Export
  function exportAsExcel(rows: any, docNameSuffix: string = 'data') {
    /* generate worksheet and workbook */
    const worksheet = XLSXutils.json_to_sheet(rows);
    const workbook = XLSXutils.book_new();
    XLSXutils.book_append_sheet(workbook, worksheet, 'Data');

    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSXwriteFile(workbook, `${umfrage.value.guid}-Ergebnis-${docNameSuffix}.xlsx`);
  }

  function exportData() {
    const rows = [];

    for (var item of umfrage.value.longlist) {
      const row = getExcelRow(item);
      rows.push(row);
    }

    exportAsExcel(rows);
  }

  function getExcelRow(item: LongList): any {
    let thema = '';
    let quelle = '';
    let aspekt = '';
    let kategorie = '';

    if (item.frageid != null) {
      const frage = fragenkatalog.value.find((f: any) => f.id == item.frageid);
      if (frage != null) {
        thema = frage.nr + ' ' + frage.frageDE;
        quelle = frage.quelle;
        aspekt = frage.aspektDE;
        kategorie = frage.themaDE;
      }
    } else if (item.themaid != null) {
      const themaItem = unternehmensthemen.value.find((t: any) => t.id == item.themaid);
      if (themaItem != null) {
        thema = themaItem.thema;
        kategorie = themaItem.kategorie;
      }
    }

    const row = {
      Ausgewählt: item.insideOutBewertungWert > umfrage.value.insideOutWert || item.outsideInBewertungWert > umfrage.value.outsideInWert ? 'Ja' : 'Nein',
      Thema: thema,
      Quelle: quelle,
      Aspekt: aspekt,
      Kategorie: kategorie,
      Zustimmung: item.zustimmung,
      InsideOutSchwellenwert: umfrage.value.insideOutWert,
      InsideOutBewertung: item.insideOutBewertung == 0 ? '-' : item.insideOutBewertung == 1 ? 'Positiv' : 'Negativ',
      InsideOutWert: item.insideOutBewertungWert,

      OutsideInSchwellenwert: umfrage.value.outsideInWert,
      OutsideInBewertung: item.outsideInBewertung == 0 ? '-' : item.outsideInBewertung == 1 ? 'Chance' : 'Risiko',
      OutsideInWert: item.outsideInBewertungWert,

      // Relevant: item.relevant,
      // NichtRelevant: item.nichtRelevant,
      // InsideOut: item.insideOut,
      // OutsideIn: item.outsideIn,
      // BothWays: item.bothWays,
      // RichtungDontKnow: item.richtungDontKnow,
      // ShortTerm: item.shortTerm,
      // MediumTerm: item.mediumTerm,
      // LongTerm: item.longTerm,
      // TermDontKnow: item.termDontKnow,
    };

    return row;
  }
</script>
