<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm umfrage-row rounded">
    <div class="col-3 my-auto">{{ umfrage.titel }}</div>
    <div class="col-2 my-auto">{{ startDatumConverted }}</div>
    <div class="col-2 my-auto">{{ endDatumConverted }}</div>
    <div class="col-3 my-auto">{{ teilnehmer.length }}</div>
    <div class="col-2 my-auto">
      <router-link :to="{ name: 'UnternehmenUmfrage', params: { unternehmenguid: umfrage.unternehmenGuid, umfrageguid: umfrage.guid }}" class="btn btn-primary btn-sm m-0 float-end">
        <font-awesome-icon icon="chevron-right"  />
      </router-link>
    </div>
  </div>
</template>
  
<script lang="ts">
  import { PropType, defineComponent, computed } from 'vue';
  import { Umfrage } from '@/models/UmfrageModels';
  import { Teilnehmer } from '@/models/TeilnehmerModels';
  import { useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { ActionTypes as TeilnehmerActionTypes } from '@/store/modules/Teilnehmer/actions';

  export default defineComponent({
    name: "UmfrageRow",
  
    props: {
      umfrage: {
        type: Object as PropType<Umfrage>,
        required: true
      }
    },
  
    setup(props) {
      const store = useStore()
      const router = useRouter()

      const startDatumConverted = computed(() => {
        if (props.umfrage.startdatum == null) {
          return "";
        }
        else {
          const date = new Date(props.umfrage.startdatum);
          return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
        }
      })

      const endDatumConverted = computed(() => {
        if (props.umfrage.enddatum == null) {
          return "";
        }
        else {
          const date = new Date(props.umfrage.enddatum);
          return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
        }
      })

      const teilnehmer = computed(() => store.getters.teilnehmerliste.filter((t: Teilnehmer) => t.umfrageGuid == props.umfrage.guid))

      function loadTeilnehmer() {
        store.dispatch(TeilnehmerActionTypes.GetTeilnehmerlisteByUmfrageguid, props.umfrage.guid);
      }
  
  
      return {
        teilnehmer,
        loadTeilnehmer,

        startDatumConverted,
        endDatumConverted
      }
    },

    mounted () {
      this.loadTeilnehmer();
    }
  
  
  })
  </script>
  
  <style scoped>
  /* .umfrage-row:hover {
    cursor: pointer;
    background-color: #ebf5d6 !important;
  } */
  </style>