<template>
  <div class="row mx-1 footer">
    <div class="col-4 offset-4 my-auto">
      <div class="d-flex justify-content-center">
        <a href="https://1aconnect.de/impressum" target="_blank" class="me-3">Impressum</a> |
        <a href="https://1aconnect.de/datenschutzerklaerung" target="_blank" class="ms-3">Datenschutz</a>
      </div>
    </div>

    <div class="col-4 text-end text-secondary my-auto">
      <small>{{ version }}</small>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';

  const version = ref('1.0.36');
</script>

<style>
  .footer {
    margin-bottom: 70px;
    background: rgb(242, 243, 248);
  }
</style>
