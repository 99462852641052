<template>
  <button class="btn btn-outline-secondary mb-2" @click="cancel()"><font-awesome-icon icon="chevron-left" /> Zurück</button>

  <div v-if="umfrage == null" class="alert alert-danger">
    <p>Die Umfrage konnte nicht gefunden werden.</p>
  </div>

  <div v-else>
    <h1>Inside-Out Themen Bewertung</h1>

    <!-- <LoadingSpinner v-if="loading" text="... Daten werden geladen .." /> -->

    <div v-if="editThemen.length == 0" class="alert alert-info">
      Es sind keine Themen für die Bewertung vorhanden.
    </div>

    <div v-else>
      <InsideOutThemaBewertungRow v-for="thema in editThemen" :key="thema.id" :longlist="thema" />

      <!-- <hr class="my-4"> -->

      <div v-if="errorMsg != ''" class="alert alert-danger">
        {{ errorMsg }}
      </div>

      <div class="position-fixed bottom-0 end-0 p-3 w-100 bg-white shadow border-top d-flex justify-content-end">
        <button @click="cancel()" class="btn btn-secondary">Abbrechen</button>
        <button @click="saveBewertung()" class="btn btn-primary ms-3">Speichern</button>
      </div>
    </div>
  </div>

  <LoadingOverlay v-if="loading" />
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

import { ActionTypes as UmfrageActionTypes } from '@/store/modules/Umfrage/actions';

import InsideOutThemaBewertungRow from '@/components/Umfrage/ThemenBewertung/InsideOutThemaBewertungRow.vue';
// import LoadingSpinner from '@/components/LoadingSpinner.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';

// import { Unternehmen } from '@/models/UnternehmenModels';
import { LongList, Umfrage } from '@/models/UmfrageModels';

export default defineComponent({
  name: 'EditThemenBewertungInsideOutView',

  components: {
    InsideOutThemaBewertungRow,
    LoadingOverlay
  },

  props: {
    unternehmenguid: {
      type: String as PropType<string>,
      required: true
    },

    umfrageguid: {
      type: String as PropType<string>,
      required: true
    },
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const loading = computed(() => store.getters.status.updateLonglistLoading);
    const errorMsg = computed(() => store.getters.status.longlistErrorMsg);

    const umfrage = computed(() => store.getters.umfragen.find((u: Umfrage) => u.guid == props.umfrageguid));
    const editThemen = ref(JSON.parse(JSON.stringify(umfrage.value?.longlist?.filter((item) => item.insideOut == 1 || item.bothWays == 1))) as Array<LongList>);

    function calcInsideOutBewertungWert() {
      editThemen.value.forEach((thema) => {
        // Positiv
        if (thema.insideOutBewertung == 1) {
          // Tatsächlich
          if (thema.insideOutReal == 1) {
            thema.insideOutWahrscheinlichkeit = 0;
            thema.insideOutBewertungWert = thema.insideOutPositivAusmass + thema.insideOutPositivUmfang;
          }
          // Potenziell
          else if (thema.insideOutReal == 2) {
            thema.insideOutBewertungWert = (thema.insideOutPositivAusmass + thema.insideOutPositivUmfang) * thema.insideOutWahrscheinlichkeit;
          }
        }
        // Negativ
        else if (thema.insideOutBewertung == 2) {
          // Tatsächlich
          if (thema.insideOutReal == 1) {
            thema.insideOutBewertungWert = thema.insideOutNegativAusmass + thema.insideOutNegativUmfang + thema.insideOutNegativBehebbarkeit;
          }
          // Potenziell
          else if (thema.insideOutReal == 2) {
            thema.insideOutBewertungWert = (thema.insideOutNegativAusmass + thema.insideOutNegativUmfang + thema.insideOutNegativBehebbarkeit) * thema.insideOutWahrscheinlichkeit;
          }
        }
      })
    }

    async function saveBewertung() {
      calcInsideOutBewertungWert();
      
      store.dispatch(UmfrageActionTypes.UpdateLonglistRange, editThemen.value)
      .then(() => {
        if (errorMsg.value == "") {
          cancel()
        }
      })
    }

    function cancel() {
      // editThemen.value = JSON.parse(JSON.stringify(umfrage.value?.longlist?.filter((item) => item.insideOut == 1 || item.bothWays == 1))) as Array<LongList>

      router.back()
    }

    return {
      router,

      loading,
      errorMsg,

      umfrage,
      editThemen,

      saveBewertung,
      cancel
    }
  }
});
</script>