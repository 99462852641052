<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm umfrage-row rounded">
    <div class="col-3 my-auto">{{ teilnehmer.name }}</div>
    <div class="col-2 my-auto">{{ teilnehmer.email}}</div>
    <div class="col-2 my-auto">{{ teilnehmer.tel }}</div>
    <div class="col-2 my-auto"> {{ teilnehmerAntworten.filter((ta: TeilnehmerAntwort) => ta.beantwortet == 1).length }} von {{ teilnehmerAntworten.length }}</div>
    <div class="col-1 my-auto">
      {{ teilnahmeDauer }}
    </div>
    <div class="col-2 my-auto d-flex justify-content-end">
      <div class="btn-group">

        <button class="btn btn-secondary" @click="copyToClipboard()" title="Teilnehmer Umfrage-Link kopieren.">
          <font-awesome-icon icon="copy" />
        </button>
        
        <button class="btn btn-primary" @click="sendTeilnehmerEmail()" title="Teilnehmer Umfrage-Link zusenden.">
          <div v-if="sendTeilnehmerEmailLoading && thisLoading" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <font-awesome-icon v-else icon="envelope" />
        </button>

        <button class="btn btn-danger" @click="showModal()" title="Teilnehmer und Antworten löschen."><font-awesome-icon icon="trash" /></button>
      </div>
    </div>
  </div>

  <!-- Delete TeilnehmerModal -->
  <div
    class="modal fade"
    :id="'deleteTeilnehmerModal' + teilnehmer.guid"
    tabindex="-1"
    :aria-labelledby="'deleteTeilnehmerModal' + teilnehmer.guid"
    aria-hidden="true"
    ref="deleteTeilnehmerModalRef"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-danger text-white py-2">
          <span class="modal-title my-auto" :id="'deleteTeilnehmerModalLabel' + teilnehmer.guid">
            Teilnehmer löschen
          </span>

          <button
            type="button"
            class="btn-close btn-sm text-white"
            @click="closeModal()"
          ></button>
        </div>

        <div class="modal-body" :id="'deleteTeilnehmerModalBody' + teilnehmer.guid">
          <LoadingSpinner v-if="deleteTeilnehmerLoading" text="... die Daten werden gelöscht ..." />

          <div v-else-if="!deleteTeilnehmerLoading && teilnehmerErrorMsg != ''" class="alert alert-danger">Es ist ein Fehler aufgetreten.<br>Bitte probieren Sie es später erneut.</div>

          <p v-else>Sind Sie sicher, dass sie den Teilnehmer <strong>{{ teilnehmer.name }}</strong> und alle Antworten des Teilnehmers <strong class="text-danger">undwiderruflich</strong> löschen wollen?</p>
          
          <hr v-if="!deleteTeilnehmerLoading" class="my-3">

          <div v-if="!deleteTeilnehmerLoading" class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-secondary me-3"
              @click="closeModal()"
              >
              Abbrechen
            </button>
            
            <button
              type="button"
              class="btn btn-danger me-3"
              @click="deleteTeilnehmer()"
              >
              Teilnehmer löschen
            </button>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>
    
<script lang="ts">
  import { PropType, defineComponent, computed, ref } from 'vue';
  import { Modal, Tooltip } from 'bootstrap';
  import { useStore } from 'vuex';

  import { ActionTypes as TeilnehmerActionTypes } from '@/store/modules/Teilnehmer/actions';

  import { Teilnehmer, TeilnehmerEmail } from '@/models/TeilnehmerModels';
  import { TeilnehmerAntwort } from '@/models/TeilnehmerAntwortModels';
  import { Umfrage } from '@/models/UmfrageModels';
  import { Unternehmen } from '@/models/UnternehmenModels';

  import LoadingSpinner from '../LoadingSpinner.vue';

  export default defineComponent({
    name: "UmfrageTeilnehmerRow",

    components: {
      LoadingSpinner
    },
  
    props: {
      teilnehmer: {
        type: Object as PropType<Teilnehmer>,
        required: true
      },

      teilnehmerAntworten:{
        type: Object as PropType<Array<TeilnehmerAntwort>>,
        required: true
      } 
    },

    setup (props) {
      const store = useStore();

      const teilnahmeDauer = computed(() => {
        // Calculate hours and remaining minutes
        var hours = Math.floor(props.teilnehmer.umfragedauer / 60);
        var remainingMinutes = props.teilnehmer.umfragedauer % 60;

        // Convert to strings and add leading zeros if needed
        var hoursStr = hours < 10 ? '0' + hours : '' + hours;
        var minutesStr = remainingMinutes < 10 ? '0' + remainingMinutes : '' + remainingMinutes;

        // Combine hours and minutes in "HH:mm" format
        var result = hoursStr + ':' + minutesStr;

        return result;
      })

      const deleteTeilnehmerModalRef = ref()
      function closeModal() {
        Modal.getInstance(deleteTeilnehmerModalRef.value)?.hide();
      }
      function showModal() {
        if (deleteTeilnehmerModalRef.value) {
          new Modal(deleteTeilnehmerModalRef.value).show();
        }
      }

      const umfrage = computed(() => store.getters.umfragen.find((u: Umfrage) => u.guid == props.teilnehmer.umfrageGuid));
      const unternehmen = computed(() => store.getters.unternehmenliste.find((u: Unternehmen) => u.guid == umfrage.value?.unternehmenGuid));

      const teilnehmerLink = computed(() => {
        const questionNr = props.teilnehmerAntworten.filter((ta: TeilnehmerAntwort) => ta.beantwortet == 1).length
        return window.location.origin + `/umfrage?firma=${unternehmen.value?.firmennummer}&umfrage=${umfrage.value.guid}&teilnehmer=${props.teilnehmer.guid}&section=3&question=${questionNr}&language=de`;
      })

      const thisLoading = ref(false);
      const sendTeilnehmerEmailLoading = computed(() => store.getters.status.emailTeilnehmerLoading);
      function sendTeilnehmerEmail() {
        thisLoading.value = true;

        const data = new TeilnehmerEmail();
        data.guid = props.teilnehmer.guid;
        data.subject = "Ihr persönlicher Link zur Wesentlichkeitsanalyse Umfrage";
        data.message = `
          <p>Hallo ${props.teilnehmer.name}</p>
          <p>Hiermit erhalten Sie Ihren persönlichen Link zur Teilnahme:</p>
          <p><a href="${teilnehmerLink.value}" style="color: #1a73e8; text-decoration: none;">Zur Umfrage</a></p>
          <p>Wir freuen uns auf Ihre Rückmeldungen und danken Ihnen im Voraus für Ihre Zeit und Unterstützung.</p>
          <p>Sollten Sie Fragen zur Umfrage haben, zögern Sie bitte nicht, Ihren Verantwortlichen zu kontaktieren.</p>
          <p>Mit freundlichen Grüßen,<br>Ihr 1.A Nachhaltigkeits Team</p>
        `;

        store.dispatch(TeilnehmerActionTypes.SendTeilnehmerEmail, data)
        .then(() => {
          thisLoading.value = false;
        })
      }

      function copyToClipboard() {
        navigator.clipboard.writeText(teilnehmerLink.value);
      }

      const deleteTeilnehmerLoading = computed(() => store.getters.status.deleteTeilnehmerLoading);
      const teilnehmerErrorMsg = computed(() => store.getters.status.teilnehmerErrorMsg);

      function deleteTeilnehmer() {
        closeModal();
        store.dispatch(TeilnehmerActionTypes.DeleteTeilnehmerliste, props.teilnehmer.guid)
        .then(() => {
          if (teilnehmerErrorMsg.value != "") {
            showModal();
          }
        })
      }

      return {
        teilnahmeDauer,

        teilnehmerLink,
        thisLoading,
        sendTeilnehmerEmailLoading,
        sendTeilnehmerEmail,
        copyToClipboard,

        deleteTeilnehmerModalRef,
        closeModal,
        showModal,

        deleteTeilnehmerLoading,
        teilnehmerErrorMsg,
        deleteTeilnehmer
      }
    },
  })
  </script>
  
  <style scoped>
  /* .umfrage-row:hover {
    cursor: pointer;
    background-color: #ebf5d6 !important;
  } */
  </style>