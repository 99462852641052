import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { Unternehmen } from '@/models/UnternehmenModels';


export type Getters = {
  unternehmen(state: State): Unternehmen,
  unternehmenliste(state: State): Array<Unternehmen>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  unternehmen(state) {
    return state.unternehmen;
  },

  unternehmenliste(state) {
    return state.unternehmenliste;
  },
}