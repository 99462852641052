<template>
  <div class="container">
    <div class="row">
      <div class="col pe-5">
        <div v-if="language == 'en'">
          <h1>Materiality analysis</h1>

          <p>
            The materiality analysis is a crucial component of sustainable corporate management. It serves two purposes by assisting in
          </p>

          <ol>
            <li>establishing priorities for sustainability strategy and goals, and</li>
            <li>defining the necessary content for non-financial reporting.</li>
          </ol>

          <p>
            A materiality analysis begins with determining sustainability topics relevant to one's own company. Topics from the "Environment," "Social," and "Governance/Corporate Management" sectors are considered. Not all topics are relevant to all companies, so the survey makes an initial preselection for your company.
          </p>

          <p>
            The survey gradually addresses topics that may be relevant in the context of your company. Please pay attention to the exact title and description of each topic. Impacts that may arise through the supply chain and relate to the company should also be considered. In addition to potential impacts, recognizable opportunities or risks can make a topic relevant. 
          </p>

          <p>
            Please first decide whether a topic is relevant to your company or not. Consider all topics with openness and do not exclude a topic prematurely. There are no right or wrong assessments here.
          </p>

          <p>
            Topics affecting your company will then be further evaluated. Indicate whether the topic has a short-term, medium-term, and/or long-term effect on your company, regardless of whether your company generates the sustainability impact or is affected by the topic. Short-term refers to a period of about 12 months.
          </p>

          <p>
            Please also indicate whether the company has a positive or negative impact on the topic (inside-out) or whether the topic has a positive or negative impact on the company (outside-in). Some topics can have effects in both directions.
          </p>

          <p>
            All survey results from members of your company will be summarized and used as a basis for the next steps in the materiality analysis. Data will only be used in the context of your own company.
          </p>

        </div>

        <div v-else>
          <h1>Die Doppelte Wesentlichkeitsanalyse (DWA)</h1>

          <p>
            Mit dieser Befragung starten Sie in die doppelte Wesentlichkeitsanalyse (DWA) und ermitteln die aus Ihrer persönlichen Sicht <strong>relevanten/wesentlichen</strong>
            Nachhaltigkeitsthemen für Ihr Unternehmen. Inhaltlich gliedert sich die Befragung in die Themenfelder Ökologie (E), Soziales und Gesellschaft (S) sowie Unternehmensführung (G). 
          </p>

          <p>
            Die doppelte Wesentlichkeit betrachtet die Verbindung von Unternehmen und Nachhaltigkeit aus zwei verschiedenen Blickwinkeln (Richtungen).
          </p>

          <ol>
            <li>Die Inside-Out Richtung (<font-awesome-icon icon="arrow-right-from-bracket" />) betrachtet die Wirkung des Unternehmens (und der Lieferkette) auf seine Umwelt (Ökologie, Wirtschaft, Gesellschaft und Politik).</li>
            <li>Die Outside-In Richtung (<font-awesome-icon icon="arrow-right-to-bracket" />) beleuchtet die Wirkung der Umwelt (Ökologie, Wirtschaft, Gesellschaft und Politik) auf das Unternehmen (und seine Lieferkette) und die daraus entstehenden finanziellen Risiken und Chancen.</li>
          </ol>

          <p>
            <strong>Wichtig:</strong> Eine Auswirkung ist eine tatsächliche oder potenzielle Veränderung in der Gesellschaft, der Umwelt und der Wirtschaft, die durch die Aktivitäten, Produkte,
            Dienstleistungen, Geschäftsbeziehungen und die Wertschöpfungskette eines Unternehmens verursacht wird.
          </p>

          <p>Praktische Hinweise zur Umsetzung der Umfrage:</p>

          <ul>
            <li>Wir haben für jedes Thema Beispielauswirkungen (in beiden Richtungen) beschrieben. Diese dienen als eine Art Leitfaden und Erläuterung, beschreiben aber nicht umfassend alle möglichen potenziellen Auswirkungen.</li>
            <li>Bitte beachten Sie, dass es bei beiden Richtungen sowohl positive als auch negative Auswirkungen (bzw. Chancen und Risiken) geben kann. Die Ermittlung der Qualität der Auswirkung (positiv und/oder negativ) erfolgt <strong>nicht</strong> in dieser Befragung.</li>
            <li>Wenn Sie ein Thema als <strong>wesentlich</strong> für Ihr Unternehmen erachten, werden Sie in einem weiteren Schritt nach dem Zeitpunkt gefragt, wann diese Auswirkung eintritt. Bitte geben Sie einen der jeweils angegebenen Zeiträume an.</li>
            <li>Sollte ein Thema für Ihr Unternehmen aus Ihrer Sicht <strong>nicht wesentlich</strong> sein, klicken Sie bitte auf <i>"Betrifft uns nicht"</i>.</li>
            <li>Wenn Sie sich sehr unsicher mit Bezug auf das Thema sind, klicken Sie auf <i>"Ich weiß nicht"</i>.</li>
          </ul>

          <p>
            Mit der Bearbeitung dieser Befragung unterstützen Sie Ihr Unternehmen bei der Entwicklung einer zukunftsweisenden Unternehmensstrategie und bei der Umsetzung seiner gesetzlichen Verpflichtung zur Nachhaltigkeits-Berichterstattung.
          </p>

          <p>Herzlichen Dank!</p>
        </div>
      </div>

      <div class="col">
        <div class="card">
          <div class="card-header bg-primary">
            <h3 v-if="language == 'en'" class="py-1 my-0 text-white">Survey</h3>
            <h3 v-else class="py-1 my-0 text-white">Umfrage</h3>
          </div>

          <div class="card-body">
            <div v-if="language == 'en'" class="card-text">
              <ul>
                <li>
                  To participate in the survey on selecting relevant sustainability topics, you will need approximately 20 minutes. Please try to complete the survey in one session.
                </li>

                <li>
                  You can take a break during the survey. You will be prompted to save the topics you have already answered and then resume the survey from where you left off.
                </li>
              </ul>

              Note on data privacy: Providing your email address is necessary for your company's ongoing work on the essential topics. It will only be used within your own company.
            </div>

            <p v-else class="card-text">
              Hinweis zum Datenschutz: Die Angabe der E-Mail-Adresse ist für die weitere Arbeit Ihres Unternehmens an den Wesentlichen Themen notwendig. Sie wird nur innerhalb ihres eigenen Unternehmens weiter verwendet.
            </p>

            <div class="mb-3">
              <label v-if="language == 'en'" for="language-select">Select Language</label>
              <label v-else for="language-select">Sprache auswählen</label>
              <select class="form-select" id="language-select" v-model="selectedLanguage" @change="setLanguage()">
                <option value="de">Deutsch</option>
                <option value="en">Englisch</option>
              </select>
            </div>

            <router-link v-if="language == 'en'" :to="{name: 'Umfrage', query:{language: language}}" class="btn btn-primary">Participate in survey</router-link>
            <router-link v-else :to="{name: 'Umfrage', query:{language: language}}" class="btn btn-primary">An Umfrage teilnehmen</router-link>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-5" />

    <!-- <div class="text-center">
      <h3>Jetzt Registrieren</h3>
      <router-link :to="{name: 'Register'}" class="btn btn-outline-primary">Registrieren</router-link>
    </div> -->
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions"

export default defineComponent({
  name: "Home",

  setup () {
    var store = useStore();

    const language = computed((() => store.getters.language))
    const selectedLanguage = ref(store.getters.language)

    function setLanguage() {
      store.dispatch(CommonActionTypes.SetLanguage, selectedLanguage.value)
    }

    return {
      language,
      selectedLanguage,
      setLanguage
    }
  }
})
</script>