import { Benutzer, BenutzerRolle } from "@/models/BenutzerModels"

export type State = {
  benutzer: Array<Benutzer>
  benutzerrollen: Array<BenutzerRolle>
}

export const state: State = {
  benutzer: localStorage.getItem("benutzer") != null ? JSON.parse(localStorage.benutzer) : new Array<Benutzer>(),
  benutzerrollen: localStorage.getItem("benutzerrollen") != null ? JSON.parse(localStorage.benutzerrollen) : new Array<BenutzerRolle>(),
}
