import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { Teilnehmer } from '@/models/TeilnehmerModels';


export type Getters = {
  teilnehmer(state: State): Teilnehmer,
  teilnehmerliste(state: State): Array<Teilnehmer>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  teilnehmer(state) {
    return state.teilnehmer;
  },

  teilnehmerliste(state) {
    return state.teilnehmerliste;
  },
}