import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
// import ApiService from "@/services/ApiService";
import { ApiService } from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { Unternehmen } from '@/models/UnternehmenModels';
import { ErrorResponseData } from '@/models/CommonModels';


export enum ActionTypes {
  GetUnternehmenByGuid = "GETUNTERNEHMENBYGUID",
  GetUnternehmenByFirmennummer = "GETUNTERNEHMENBYFIRMENNUMMER",

  GetUnternhemenliste = "GETUNTERNEHMENLISTE",
  CreateUnternehmen = "CREATEUNTERNEHMEN",
  UpdateUnternehmen = "UPDATEUNTERNEHMEN",
  DeleteUnternehmen = "DELETEUNTERNEHMEN",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetUnternhemenliste](context: ActionArguments): Promise<void>
  [ActionTypes.GetUnternehmenByGuid](context: ActionArguments, guid: string): Promise<void>
  [ActionTypes.GetUnternehmenByFirmennummer](context: ActionArguments, firmennummer: string): Promise<void>
  [ActionTypes.CreateUnternehmen](context: ActionArguments, data: Unternehmen): Promise<void>
  [ActionTypes.UpdateUnternehmen](context: ActionArguments, data: Unternehmen): Promise<void>
  [ActionTypes.DeleteUnternehmen](context: ActionArguments, unternehmenguid: string): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetUnternhemenliste]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getUnternehmenLoading = true;
    status.unternehmenErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllUnternehmen()
      .then(res => {
        const data: Array<Unternehmen> = res.data
        commit(MutationTypes.SetUnternehmenliste, data)
      })
      .catch(error => {
        status.unternehmenErrorMsg = error.response;
      })
      .finally(() => {
        status.getUnternehmenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetUnternehmenByGuid]({commit, dispatch, rootGetters}, guid) {
    const status = rootGetters.status;
    status.getUnternehmenLoading = true;
    status.unternehmenErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getUnternehmenByGuid(guid)
      .then(res => {
        const data: Unternehmen = res.data
        commit(MutationTypes.SetUnternehmen, data)
        commit(MutationTypes.SetUnternehmenliste, [data])
      })
      .catch(error => {
        status.unternehmenErrorMsg = error.response;
      })
      .finally(() => {
        status.getUnternehmenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetUnternehmenByFirmennummer]({commit, dispatch, rootGetters}, firmennummer) {
    const status = rootGetters.status;
    status.getUnternehmenLoading = true;
    status.unternehmenErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getUnternehmenByFirmennummer(firmennummer)
      .then(res => {
        const data: Unternehmen = res.data
        commit(MutationTypes.SetUnternehmen, data)
      })
      .catch(error => {
        if (error.response.status == 404) {
          const err = error.response.data as ErrorResponseData
          status.unternehmenErrorMsg = err.detail;
        }
        else {
          status.unternehmenErrorMsg = error.response;
        }
      })
      .finally(() => {
        status.getUnternehmenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateUnternehmen]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createUnternehmenLoading = true;
    status.unternehmenErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.CreateUnternehmen(data)
      .then(res => {
        const data: Unternehmen = res.data
        commit(MutationTypes.AddUnternehmenliste, data)
      })
      .catch(error => {
        if (error.response.status == 400) {
          status.unternehmenErrorMsg = error.response.data.detail;
        }
        else {
          status.unternehmenErrorMsg = error.response;
        }
      })
      .finally(() => {
        status.createUnternehmenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateUnternehmen]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateUnternehmenLoading = true;
    status.unternehmenErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.UpdateUnternehmen(data)
      .then(res => {
        const data: Unternehmen = res.data
        commit(MutationTypes.UpdateUnternehmenliste, data)
      })
      .catch(error => {
        status.unternehmenErrorMsg = error.response;
      })
      .finally(() => {
        status.updateUnternehmenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteUnternehmen]({commit, dispatch, rootGetters}, unternehmenguid) {
    const status = rootGetters.status;
    status.deleteUnternehmenLoading = true;
    status.unternehmenErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.DeleteUnternehmen(unternehmenguid)
      .then(res => {
        commit(MutationTypes.DeleteUnternehmenliste, unternehmenguid)
      })
      .catch(error => {
        status.unternehmenErrorMsg = error.response;
      })
      .finally(() => {
        status.deleteUnternehmenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
}