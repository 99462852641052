<template>
  <div class="mb-3 bg-white shadow rounded">
    <div class="row py-2 mx-1">
      <div class="col-6 my-auto border-end cut-text fw-bold" data-bs-toggle="tooltip" data-bs-placement="top" :title="titel">{{ titel }}</div>

      <div class="col-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            :name="'bewertung-' + longlist.id"
            :id="'bewertung-inside-out-positiv-' + longlist.id"
            :value="1"
            v-model="longlist.insideOutBewertung"
            @change="resetBewertung()"
          />
          <label class="form-check-label" :for="'bewertung-inside-out-positiv-' + longlist.id"> Positiv </label>
        </div>
      </div>

      <div class="col-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            :name="'bewertung-' + longlist.id"
            :id="'bewertung-inside-out-negativ-' + longlist.id"
            :value="2"
            v-model="longlist.insideOutBewertung"
            @change="resetBewertung()"
          />
          <label class="form-check-label" :for="'bewertung-inside-out-negativ-' + longlist.id"> Negativ </label>
        </div>
      </div>
    </div>

    <!-- POSITIV -->
    <div v-if="longlist.insideOutBewertung == 1" class="p-2 mx-1">
      <RadioSelect
        v-model="longlist.insideOutReal"
        title="Tatsächlich oder potenziell?"
        :keyValues="realOrPotenziellSelect"
        :name="'real-positiv-' + longlist.id"
        beschreibung="Handelt es sich um ein tatsächliches oder potenzielles Thema?"
      />

      <div v-if="longlist.insideOutReal != 0">
        <RadioSelect
          v-model="longlist.insideOutPositivAusmass"
          title="Ausmaß"
          :keyValues="oneToSixRadioSelect"
          :name="'ausmass-positiv-' + longlist.id"
          beschreibung="klein - groß"
        />
        <RadioSelect
          v-model="longlist.insideOutPositivUmfang"
          title="Umfang"
          :keyValues="oneToSixRadioSelect"
          :name="'umfang-positiv-' + longlist.id"
          beschreibung="klein - groß"
        />
        <RadioSelect
          v-if="longlist.insideOutReal == 2"
          v-model="longlist.insideOutWahrscheinlichkeit"
          title="Wahrscheinlichkeit"
          :keyValues="wahrscheinlichkeitSelect"
          :name="'wahrscheinlichkeit-positiv-' + longlist.id"
          beschreibung="Die Wahrscheinlichkeit ist"
        />
      </div>
    </div>

    <!-- NEGATIV -->
    <div v-else-if="longlist.insideOutBewertung == 2" class="p-2 mx-1">
      <RadioSelect
        v-model="longlist.insideOutReal"
        title="Tatsächlich oder potenziell?"
        :keyValues="realOrPotenziellSelect"
        :name="'real-positiv-' + longlist.id"
        beschreibung="Handelt es sich um ein tatsächliches oder potenzielles Thema?"
      />

      <div v-if="longlist.insideOutReal != 0">
        <RadioSelect
          v-model="longlist.insideOutNegativAusmass"
          title="Ausmaß"
          :keyValues="oneToSixRadioSelect"
          :name="'ausmas-negativ-' + longlist.id"
          beschreibung="klein - groß"
        />
        <RadioSelect
          v-model="longlist.insideOutNegativUmfang"
          title="Umfang"
          :keyValues="oneToSixRadioSelect"
          :name="'umfang-negativ-' + longlist.id"
          beschreibung="klein - groß"
        />
        <RadioSelect
          v-model="longlist.insideOutNegativBehebbarkeit"
          title="Behebbarkeit"
          :keyValues="oneToSixRadioSelect"
          :name="'behebbarkeit-negativ-' + longlist.id"
          beschreibung="klein - groß"
        />
        <RadioSelect
          v-if="longlist.insideOutReal == 2"
          v-model="longlist.insideOutWahrscheinlichkeit"
          title="Wahrscheinlichkeit"
          :keyValues="wahrscheinlichkeitSelect"
          :name="'wahrscheinlichkeit-negativ-' + longlist.id"
          beschreibung="Die Wahrscheinlichkeit ist"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, computed, onMounted } from 'vue';
  import { useStore } from 'vuex';

  import { ActionTypes as FragenkatalogActionTypes } from '@/store/modules/Fragenkatalog/actions';

  import RadioSelect from '@/components/FormInputs/RadioSelect.vue';

  import { LongList } from '@/models/UmfrageModels';
  import { Fragenkatalog } from '@/models/FragenkatalogModels';
  import { Unternehmensthema } from '@/models/UnternehmensthemenModels';
  import RadioSelectKeyValue from '@/components/FormInputs/FormClasses';
  //#endregion

  const props = defineProps({
    longlist: {
      type: Object as PropType<LongList>,
      required: true,
    },
  });

  const store = useStore();

  const titel = computed(() => {
    if (props.longlist.frageid != null) {
      const frage = store.getters.fragenkatalog.find((f: Fragenkatalog) => f.id == props.longlist.frageid);
      return `${frage.nr}. ${frage.frageDE}`;
    } else if (props.longlist.themaid != null) {
      const thema = store.getters.unternehmensthemen.find((t: Unternehmensthema) => t.id == props.longlist.themaid);
      return `${thema.kategorie} - ${thema.thema}`;
    } else {
      return 'unbekannt';
    }
  });

  function resetBewertung() {
    props.longlist.insideOutReal = 0;

    props.longlist.insideOutPositivAusmass = 0;
    props.longlist.insideOutPositivUmfang = 0;

    props.longlist.insideOutNegativAusmass = 0;
    props.longlist.insideOutNegativUmfang = 0;
    props.longlist.insideOutNegativBehebbarkeit = 0;

    props.longlist.insideOutWahrscheinlichkeit = 0;
    props.longlist.insideOutBewertungWert = 0;
  }

  const realOrPotenziellSelect = computed(() => {
    const options = new Array<RadioSelectKeyValue>();
    options.push(new RadioSelectKeyValue('Tatsächlich', 1));
    options.push(new RadioSelectKeyValue('Potenziell', 2));

    return options;
  });

  const oneToSixRadioSelect = computed(() => {
    const options = new Array<RadioSelectKeyValue>();
    options.push(new RadioSelectKeyValue('1', 1));
    options.push(new RadioSelectKeyValue('2', 2));
    options.push(new RadioSelectKeyValue('3', 3));
    options.push(new RadioSelectKeyValue('4', 4));
    options.push(new RadioSelectKeyValue('5', 5));
    options.push(new RadioSelectKeyValue('6', 6));

    // options.push(new RadioSelectKeyValue('sehr gering', 1));
    // options.push(new RadioSelectKeyValue('gering', 2));
    // options.push(new RadioSelectKeyValue('moderat - begrenzte Wirkung', 3));
    // options.push(new RadioSelectKeyValue('obere Mitte - spürbare Wirkung', 4));
    // options.push(new RadioSelectKeyValue('hoch', 5));
    // options.push(new RadioSelectKeyValue('sehr hoch', 6));

    return options;
  });

  const wahrscheinlichkeitSelect = computed(() => {
    const options = new Array<RadioSelectKeyValue>();
    options.push(new RadioSelectKeyValue('gering', 0.2));
    options.push(new RadioSelectKeyValue('eventuell', 0.4));
    options.push(new RadioSelectKeyValue('wahrscheinlich', 0.6));
    options.push(new RadioSelectKeyValue('hoch', 0.8));
    options.push(new RadioSelectKeyValue('sehr hoch', 0.95));

    return options;
  });

  onMounted(() => {
    if (store.getters.fragenkatalog.length == 0) {
      store.dispatch(FragenkatalogActionTypes.GetFragenkatalog, undefined);
    }
  });
</script>
