<template>
  <div class="row mb-2 py-2 border-top">
    <div class="col-2 my-auto">
      <strong>{{ title }}:</strong>
    </div>

    <div class="col">
      <small class="ms-4">{{ beschreibung }}</small>

      <div class="d-flex">
        <div v-for="el in keyValues" :key="el.value" class="form-check ms-4">
          <input
            class="form-check-input"
            type="radio"
            :name="name + '-' + el.value"
            :id="name + '-' + el.value"
            :value="el.value"
            v-model="inputvalue"
            @change="updateModelValue()"
          />
          <label class="form-check-label" :for="name + '-' + el.value">
            {{ el.key }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, ref } from 'vue';
  import RadioSelectKeyValue from './FormClasses';

  export default defineComponent({
    name: 'RadioSelect',

    props: {
      modelValue: {
        // type: Object as PropType<number | string>,
        type: [Number, String] as PropType<number | string>,
        required: true,
      },
      title: {
        type: String as PropType<string>,
        required: true,
      },
      beschreibung: {
        type: String as PropType<string>,
        default: '',
      },
      keyValues: {
        type: Array as PropType<Array<RadioSelectKeyValue>>,
        required: true,
      },
      name: {
        type: String as PropType<string>,
        default: '',
      },
    },

    emits: ['update:modelValue'],

    setup(props, { emit }) {
      const inputvalue = ref(props.modelValue);

      function updateModelValue() {
        emit('update:modelValue', inputvalue.value);
      }

      return {
        inputvalue,
        updateModelValue,
      };
    },
  });
</script>
