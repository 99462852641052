<template>
  <div class="d-flex justify-content-between">
    <h1>Unternehmensthemen</h1>

    <button class="btn" @click="loadUnternehmensthemen()"><font-awesome-icon icon="rotate" /></button>
  </div>


  <div v-if="!loading && errorMsg != ''" class="alert alert-danger my-3">
    Es ist ein Fehler beim Laden der Unternehmensthemen aufgetreten. Bitte probieren Sie es später erneut.
  </div>

  <div v-if="!loading" class="mt-3">
    <div class="row mb-3">
      <div class="col-4">
        <router-link :to="{name: 'EditUnternehmensthema'}" class="btn btn-secondary btn-sm"><font-awesome-icon icon="plus" /> Hinzufügen</router-link>
      </div>

      <div class="col-4">
        <div class="input-group input-group-sm my-auto">
          <input v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche...">
          <span class="input-group-text">
            <font-awesome-icon icon="magnifying-glass" class="p-1"/>
          </span>
        </div>
      </div>
    </div>

    <div class="row mx-1 mb-2 bg-white shadow-sm rounded">
      <div class="col-3 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Kategorie</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'kategorieasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'kategoriedesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('kategorieasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'kategorieasc'" />
                </div>
                <div class="col">
                  A - Z
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('kategoriedesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'kategoriedesc'" />
                </div>
                <div class="col">
                  Z - A
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-3 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Thema</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'themaasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'themadesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('themaasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'themaasc'" />
                </div>
                <div class="col">
                  A - Z
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('themadesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'themadesc'" />
                </div>
                <div class="col">
                  Z - A
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-6 py-2">

      </div>
    </div>

    <div v-for="thema in sortedUnternehmensthemen" :key="thema.id">
      <UnternehmensthemaRow :thema="thema" />
    </div>

    <div v-if="unternehmensthemen.length == 0" class="alert alert-info my-3">
      Es wurden keine Fragen gefunden.
    </div>
  </div>

  <LoadingOverlay v-if="loading" text="... Fragen werden geladen ..." />
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { ActionTypes as UnternehmensthemaActionTypes } from '@/store/modules/Unternehmensthema/actions';

import LoadingOverlay from '@/components/LoadingOverlay.vue';
import UnternehmensthemaRow from '@/components/Unternehmensthema/UnternehmensthemaRow.vue';

import { Unternehmensthema } from '@/models/UnternehmensthemenModels';

export default defineComponent({
  name: "Unternehmensthemen",

  components: {
    LoadingOverlay,
    UnternehmensthemaRow
  },

  setup () {
    const store = useStore()

    const loading = computed(() => store.getters.status.getUnternehmensthemaLoading);
    const errorMsg = computed(() => store.getters.status.unternehmensthemaErrorMsg);

    const searchString = ref("")

    const sortByValue = ref("kategorieasc")
    function sortBy (param: string) {
      sortByValue.value = param
    }

    const unternehmensthemen = computed(() => store.getters.unternehmensthemen);
    const sortedUnternehmensthemen = computed(() => {
      return unternehmensthemen.value
        .filter((a: Unternehmensthema) => a.kategorie.toString().includes(searchString.value) ||
          a.thema.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()))
        .sort((a: Unternehmensthema, b: Unternehmensthema) => {
          if (sortByValue.value == "kategorieasc" || sortByValue.value == "kategoriedesc") {
            var kategorieComparison = a.kategorie.trim().toLowerCase().localeCompare(b.kategorie.trim().toLowerCase())
            if (sortByValue.value == "kategoriedesc") {
              kategorieComparison = -1 * kategorieComparison
            }
          
            if (kategorieComparison == 0) {
              return a.thema.trim().toLowerCase().localeCompare(b.thema.trim().toLowerCase())
            }
            
            return kategorieComparison;
          }

          else if (sortByValue.value == "themaasc" || sortByValue.value == "themadesc") {
            var themaComparison = a.thema.trim().toLowerCase().localeCompare(b.thema.trim().toLowerCase())
            if (sortByValue.value == "themadesc") {
              themaComparison = -1 * themaComparison
            }
            
            if (themaComparison == 0) {
              return a.kategorie.trim().toLowerCase().localeCompare(b.kategorie.trim().toLowerCase())
            }
            
            return themaComparison;
          }

          return 0;
        });
    })

    function loadUnternehmensthemen () {
      store.dispatch(UnternehmensthemaActionTypes.GetUnternehmensthema, undefined);
    }

    return {
      loading,
      errorMsg,

      searchString,
      sortByValue,
      sortBy,

      unternehmensthemen,
      sortedUnternehmensthemen,

      loadUnternehmensthemen
    }
  },

  mounted () {
    this.loadUnternehmensthemen()
  }
})
</script>

<style scoped>
.table-col:hover {
  background: rgb(216, 217, 222);
  cursor: pointer;
}

.table-sort-icon {
  font-size: .7em;
}

.select-sort:hover {
  cursor: pointer;
}
</style>