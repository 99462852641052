import { MutationTree } from "vuex"
import { State } from "./state"
import { Teilnehmer } from "@/models/TeilnehmerModels"

export enum MutationTypes {
  SetTeilnehmer = "SETTEILNEHMER",

  SetTeilnehmerliste = "SETTEILNEHMERLISTE",
  AddTeilnehmerliste = "ADDTEILNEHMERLISTE",
  AddTeilnehmerlisteRange = "ADDTEILNEHMERLISTERANGE",
  UpdateTeilnehmerliste = "UPDATETEILNEHMERLISTE",
  DeleteTeilnehmerliste = "DELETETEILNEHMERLISTE"
}

export type Mutations = {
  [MutationTypes.SetTeilnehmer](state: State, teilnehmer: Teilnehmer): void

  [MutationTypes.SetTeilnehmerliste](state: State, teilnehmerliste: Array<Teilnehmer>): void
  [MutationTypes.AddTeilnehmerliste](state: State, teilnehmer: Teilnehmer): void
  [MutationTypes.AddTeilnehmerlisteRange](state: State, teilnehmer: Array<Teilnehmer>): void
  [MutationTypes.UpdateTeilnehmerliste](state: State, teilnehmer: Teilnehmer): void
  [MutationTypes.DeleteTeilnehmerliste](state: State, teilnehmerguid: string): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetTeilnehmer](state, teilnehmer) {
    state.teilnehmer = teilnehmer;
    sessionStorage.setItem("teilnehmer", JSON.stringify(state.teilnehmer));
  },

  [MutationTypes.SetTeilnehmerliste](state, teilnehmerliste) {
    state.teilnehmerliste = teilnehmerliste;
    localStorage.setItem("teilnehmerliste", JSON.stringify(state.teilnehmerliste));
  },

  [MutationTypes.AddTeilnehmerliste](state, teilnehmer) {
    const index = state.teilnehmerliste.findIndex(a => a.guid === teilnehmer.guid)
    if (index != -1){
      state.teilnehmerliste.splice(index, 1, teilnehmer)
    }
    else {
      state.teilnehmerliste.push(teilnehmer);
    }

    localStorage.setItem("teilnehmerliste", JSON.stringify(state.teilnehmerliste));
  },

  [MutationTypes.AddTeilnehmerlisteRange](state, teilnehmer) {
    for (let t of teilnehmer) {
      const index = state.teilnehmerliste.findIndex(a => a.guid === t.guid)
      if (index != -1){
        state.teilnehmerliste.splice(index, 1, t)
      }
      else {
        state.teilnehmerliste.push(t);
      }
    }

    localStorage.setItem("teilnehmerliste", JSON.stringify(state.teilnehmerliste));
  },

  [MutationTypes.UpdateTeilnehmerliste](state, teilnehmer) {
    const oldteilnehmer = state.teilnehmerliste.find(a => a.guid === teilnehmer.guid)
    if (oldteilnehmer != null) {
      const index = state.teilnehmerliste.indexOf(oldteilnehmer)
      state.teilnehmerliste.splice(index, 1, teilnehmer)
    }

    localStorage.setItem("teilnehmerliste", JSON.stringify(state.teilnehmerliste));
  },

  [MutationTypes.DeleteTeilnehmerliste](state, teilnehmerguid) {
    const oldteilnehmer = state.teilnehmerliste.find(a => a.guid === teilnehmerguid)
    if (oldteilnehmer != null) {
      const index = state.teilnehmerliste.indexOf(oldteilnehmer)
      state.teilnehmerliste.splice(index, 1)
    }

    localStorage.setItem("teilnehmerliste", JSON.stringify(state.teilnehmerliste));
  }
}