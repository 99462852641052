export class Benutzer {
  id: number
  email: string;
  name: string;
  vorname: string;

  benutzerrolleId: number;
  benutzerUnternehmen: Array<BenutzerUnternehmen>;


  constructor () {
    this.id = 0
    this.email = "";
    this.name = "";
    this.vorname = "";

    this.benutzerrolleId = 3;
    this.benutzerUnternehmen = new Array<BenutzerUnternehmen>();
  }
}

export class CreateBenutzerRequest {
  email: string;
  name: string;
  vorname: string;
  passwort: string;
  benutzerrolleId: number;

  constructor () {
    this.email = "";
    this.name = "";
    this.vorname = "";
    this.passwort = "";
    this.benutzerrolleId = 3;
  }
}

export class UpdateBenutzerPasswortRequest {
  id: number;
  passwort: string;

  constructor(id: number, passwort: string) {
    this.id = id;
    this.passwort = passwort;
  }
}

export class BenutzerRolle {
  id: number;
  titel: string;

  constructor(id: number, titel: string) {
    this.id = id;
    this.titel = titel;
    
  }
}

export class BenutzerUnternehmen {
  id: number;
  benutzerId: number;
  unternehmenGuid: string;

  constructor(benutzerId: number, unternehmenGuid: string) {
    this.id = 0;
    this.benutzerId = benutzerId;
    this.unternehmenGuid = unternehmenGuid;
  }
}

export class BeraterUnternehmenRequest {
  email: string;
  unternehmenGuid: string;

  constructor(email: string, unternehmenGuid: string) {
    this.email = email;
    this.unternehmenGuid = unternehmenGuid;
  }
}