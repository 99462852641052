<template>
  <div class="d-flex justify-content-between align-items-center">
    <button type="button" class="btn btn-outline-secondary" @click="cancel()"><font-awesome-icon icon="chevron-left" /> Zurück</button>

    <router-link v-if="unternehmensthema != null && ((unternehmensthema.unternehmenGuid == null && user.benutzerrolleId == 1) || unternehmensthema.unternehmenGuid != null)" :to="{name: 'EditUnternehmensthema', query: {unternehmensthemaid: unternehmensthemaid}}" class="btn"><font-awesome-icon icon="pen" /></router-link>

  </div>

  <div v-if="unternehmensthema == null" class="alert alert-danger">
    Das Thema konnte nicht gefunden werden.
  </div>

  <div v-else class="mt-3">
    <div class="row bg-white shadow mx-1 mb-2">
      <div class="col p-3">
        <div class="d-flex justify-content-between align-items-center border-bottom mb-4">
          <h1>Thema</h1>

        </div>

        <div class="mb-3">
          <strong>Kategorie:</strong>
          <p>{{ unternehmensthema.kategorie }}</p>
        </div>

        <div class="mb-3">
          <strong>Thema:</strong>
          <p>{{ unternehmensthema.kategorie }}</p>
        </div>

        <p><strong>Beschreibung:</strong></p>
        <div v-html="unternehmensthema.beschreibung"></div>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { Unternehmensthema } from '@/models/UnternehmensthemenModels';

export default defineComponent({
  name: "DetailsUnternehmensthema",

  props: {
    unternehmensthemaid: {
      type: String as PropType<string>,
      required: true
    }
  },

  setup (props) {
    const store = useStore()
    const router = useRouter()

    const loading = computed(() => store.getters.status.updateFragenkatalogLoading);
    const errorMsg = computed(() => store.getters.status.fragenkatalogErrorMsg);

    const user = computed(() => store.getters.authUser?.benutzer);

    const unternehmensthema = computed(() => store.getters.unternehmensthemen.find((f: Unternehmensthema) => f.id == Number.parseInt(props.unternehmensthemaid)));

    function cancel() {
      router.back();
    }

    return {
      loading,
      errorMsg,
      
      unternehmensthema,

      user,

      cancel
    }
  }
})
</script>