<template>
  <div class="d-flex justify-content-between align-items-center">
    <button type="button" class="btn btn-outline-secondary" @click="cancel()"><font-awesome-icon icon="chevron-left" /> Zurück</button>

    <router-link v-if="fragenkatalog != null && user.benutzerrolleId == 1" :to="{name: 'EditFragenkatalog', query: {fragenkatalogid: fragenkatalogid}}" class="btn"><font-awesome-icon icon="pen" /></router-link>

  </div>

  <div v-if="fragenkatalog == null" class="alert alert-danger">
    Die Frage konnte nicht gefunden werden.
  </div>

  <div v-else class="mt-3">
    <div class="row bg-white shadow mx-1 mb-2">
      <div class="col p-3">
        <div class="d-flex justify-content-between align-items-center border-bottom mb-4">
          <h1>Frage</h1>

        </div>

        <div class="row">
          <div class="col-2"><strong>Quelle:</strong></div>
          <div class="col">{{ fragenkatalog.quelle }}</div>
        </div>

        <div class="row">
          <div class="col-2"><strong>Nr:</strong></div>
          <div class="col">{{ fragenkatalog.nr }}</div>
        </div>
      </div>
    </div>

    <div class="row mx-1 mb-3">
      <div class="col bg-white shadow p-3 me-1">
        <h3>Deutsch</h3>

        <div class="row">
          <div class="col-2"><strong>Aspekt:</strong></div>
          <div class="col">{{ fragenkatalog.aspektDE }}</div>
        </div>

        <div class="row mb-3">
          <div class="col-2"><strong>Frage:</strong></div>
          <div class="col">{{ fragenkatalog.frageDE }}</div>
        </div>

        <p><strong>Beschreibung:</strong></p>
        <div v-html="fragenkatalog.beschreibungDE"></div>

      </div>

      <div class="col bg-white shadow p-3 ms-1">
        <h3>Englisch</h3>

        <div class="row">
          <div class="col-2"><strong>Aspekt:</strong></div>
          <div class="col">{{ fragenkatalog.aspektEN }}</div>
        </div>

        <div class="row mb-3">
          <div class="col-2"><strong>Frage:</strong></div>
          <div class="col">{{ fragenkatalog.frageEN }}</div>
        </div>

        <p><strong>Beschreibung:</strong></p>
        <div v-html="fragenkatalog.beschreibungEN"></div>
      </div>
    </div>

  </div>

</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { Fragenkatalog } from '@/models/FragenkatalogModels';

export default defineComponent({
  name: "DetailsFragenkatalogView",

  props: {
    fragenkatalogid: {
      type: String as PropType<string>,
      required: true
    }
  },

  setup (props) {
    const store = useStore()
    const router = useRouter()

    const loading = computed(() => store.getters.status.updateFragenkatalogLoading);
    const errorMsg = computed(() => store.getters.status.fragenkatalogErrorMsg);

    const user = computed(() => store.getters.authUser?.benutzer);

    const fragenkatalog = computed(() => store.getters.fragenkatalog.find((f: Fragenkatalog) => f.id == Number.parseInt(props.fragenkatalogid)));

    function cancel() {
      router.back();
    }

    return {
      loading,
      errorMsg,
      
      fragenkatalog,

      user,

      cancel
    }
  }
})
</script>