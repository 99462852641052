<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm umfrage-row rounded">
    <div class="col-1 my-auto">{{ fragenkatalog.nr }}</div>
    <div class="col-1 my-auto">{{ fragenkatalog.quelle }}</div>
    <div class="col-1 my-auto">{{ fragenkatalog.aspektDE }}</div>
    <div class="col-4 my-auto">{{ fragenkatalog.themaDE }}</div>
    <div class="col-4 my-auto">{{ fragenkatalog.frageDE }}</div>
    <div class="col-1 my-auto text-end">
      <router-link :to="{name: 'DetailsFragenkatalog', params: { fragenkatalogid: fragenkatalog.id.toString()}}" class="btn btn-primary btn-sm">
        <font-awesome-icon icon="magnifying-glass-plus" />
      </router-link>
    </div>
  </div>
</template>
  
<script lang="ts">
  import { PropType, defineComponent, computed } from 'vue';
  import { Fragenkatalog } from '@/models/FragenkatalogModels';

  export default defineComponent({
    name: "FragenkatalogRow",
  
    props: {
      fragenkatalog: {
        type: Object as PropType<Fragenkatalog>,
        required: true
      }
    },
  })
  </script>
  
  <style scoped>
  /* .umfrage-row:hover {
    cursor: pointer;
    background-color: #ebf5d6 !important;
  } */
  </style>