<template>
  <h1>Dashboard</h1>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
  name: "Dashboard"
})
</script>