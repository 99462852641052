import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
// import ApiService from "@/services/ApiService";
import { ApiService } from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { Unternehmensthema } from '@/models/UnternehmensthemenModels';


export enum ActionTypes {
  GetUnternehmensthema = "GETUNTERNEHMENSTHEMA",
  CreateUnternehmensthema = "CREATEUNTERNEHMENSTHEMA",
  UpdateUnternehmensthema = "UPDATEUNTERNEHMENSTHEMA",
  DeleteUnternehmensthema = "DELETEUNTERNEHMENSTHEMA",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetUnternehmensthema](context: ActionArguments, unternhemenGuid: string): Promise<void>
  [ActionTypes.CreateUnternehmensthema](context: ActionArguments, data: Unternehmensthema): Promise<void>
  [ActionTypes.UpdateUnternehmensthema](context: ActionArguments, data: Unternehmensthema): Promise<void>
  [ActionTypes.DeleteUnternehmensthema](context: ActionArguments, unternehmensthemaid: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetUnternehmensthema]({commit, dispatch, rootGetters}, unternehmenGuid = "") {
    const status = rootGetters.status;
    status.getUnternehmensthemaLoading = true;
    status.unternehmensthemaErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.GetAllUnternehmensthema(unternehmenGuid)
      .then(res => {
        const data: Array<Unternehmensthema> = res.data
        commit(MutationTypes.SetUnternehmensthema, data)
      })
      .catch(error => {
        status.unternehmensthemaErrorMsg = error.response;
      })
      .finally(() => {
        status.getUnternehmensthemaLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateUnternehmensthema]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createUnternehmensthemaLoading = true;
    status.unternehmensthemaErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.CreateUnternehmensthema(data)
      .then(res => {
        const data: Unternehmensthema = res.data
        commit(MutationTypes.AddUnternehmensthema, data)
      })
      .catch(error => {
        status.unternehmensthemaErrorMsg = error.response;
      })
      .finally(() => {
        status.createUnternehmensthemaLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },


  async [ActionTypes.UpdateUnternehmensthema]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateUnternehmensthemaLoading = true;
    status.unternehmensthemaErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.UpdateUnternehmensthema(data)
      .then(res => {
        const data: Unternehmensthema = res.data
        commit(MutationTypes.UpdateUnternehmensthema, data)
      })
      .catch(error => {
        status.unternehmensthemaErrorMsg = error.response;
      })
      .finally(() => {
        status.updateUnternehmensthemaLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },


  async [ActionTypes.DeleteUnternehmensthema]({commit, dispatch, rootGetters}, unternehmensthemaid) {
    const status = rootGetters.status;
    status.deleteUnternehmensthemaLoading = true;
    status.unternehmensthemaErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.DeleteUnternehmensthema(unternehmensthemaid)
      .then(res => {
        commit(MutationTypes.DeleteUnternehmensthema, unternehmensthemaid)
      })
      .catch(error => {
        status.unternehmensthemaErrorMsg = error.response;
      })
      .finally(() => {
        status.deleteUnternehmensthemaLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
}