import { Benutzer, CreateBenutzerRequest } from "./BenutzerModels";
import { Unternehmen } from "./UnternehmenModels";

export class AuthUser {
  benutzer: Benutzer
  token: string
  refreshtoken: string

  constructor() {
    this.benutzer = new Benutzer()
    this.token = ""
    this.refreshtoken = ""
  }
}

export class LoginRequest {
  email: string;
  passwort: string;

  constructor() {
    this.email = "";
    this.passwort = "";
  }
}

export class RefreshLoginRequest {
  refreshtoken: string;

  constructor(token: string) {
    this.refreshtoken = token;
  }
}

export class LogoutRequest {
  refreshtoken: string;

  constructor(token: string) {
    this.refreshtoken = token;
  }
}

export class RegisterRequest {
  benutzer: CreateBenutzerRequest;
  unternehmen: Unternehmen;

  constructor(benutzer: CreateBenutzerRequest, unternehmen: Unternehmen) {
    this.benutzer = benutzer;
    this.unternehmen = unternehmen;
  }
}