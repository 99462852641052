import { Fragenkatalog } from "./FragenkatalogModels"

export class TeilnehmerAntwort {
  id: number
  teilnehmerGuid: string
  fragenkatalogid: number
  frageDE: string
  insideOut: number
  outsideIn: number
  bothWays: number
  nichtRelevant: number
  richtungDontKnow: number
  shortTerm: number
  mediumTerm: number
  longTerm: number
  termDontKnow: number
  gesehen: number
  beantwortet: number
  startzeit: Date | null
  endzeit: Date | null

  constructor () {
    this.id = 0;
    this.teilnehmerGuid = "";
    this.fragenkatalogid = 0;
    this.frageDE = "";

    this.insideOut = 0;
    this.outsideIn = 0;
    this.bothWays = 0;
    this.nichtRelevant = -1;
    this.richtungDontKnow = 0;
    
    this.shortTerm = 0;
    this.mediumTerm = 0;
    this.longTerm = 0;
    this.termDontKnow = 0;
    
    this.gesehen = 0;
    this.beantwortet = 0;
    this.startzeit = null;
    this.endzeit = null;
  }

  setValuesFromDisplayAntowrt (displayAntwort: DisplayTeilnehmerAntwort) {
    this.id = displayAntwort.id
    this.teilnehmerGuid = displayAntwort.teilnehmerGuid
    this.fragenkatalogid = displayAntwort.fragenkatalogid
    this.frageDE = displayAntwort.frageDE

    this.nichtRelevant = displayAntwort.wirkungsrichtung == 4 ? 1 : 0
    this.richtungDontKnow = displayAntwort.wirkungsrichtung == 5 ? 1 : 0

    this.outsideIn = displayAntwort.wirkungsrichtung == 1 ? 1 : 0
    this.insideOut = displayAntwort.wirkungsrichtung == 2 ? 1 : 0
    this.bothWays = displayAntwort.wirkungsrichtung == 3 ? 1 : 0
    
    this.shortTerm = displayAntwort.shortTerm && (displayAntwort.wirkungsrichtung > 0 && displayAntwort.wirkungsrichtung < 4) ? 1 : 0
    this.mediumTerm = displayAntwort.mediumTerm && (displayAntwort.wirkungsrichtung > 0 && displayAntwort.wirkungsrichtung < 4) ? 1 : 0
    this.longTerm = displayAntwort.longTerm && (displayAntwort.wirkungsrichtung > 0 && displayAntwort.wirkungsrichtung < 4) ? 1 : 0
    this.termDontKnow = displayAntwort.termDontKnow && (displayAntwort.wirkungsrichtung > 0 && displayAntwort.wirkungsrichtung < 4) ? 1 : 0
    
    this.gesehen = displayAntwort.gesehen ? 1 : 0
    this.beantwortet = displayAntwort.beantwortet ? 1 : 0

    // if (this.insideOut == 1 ||
    //   this.outsideIn == 1 ||
    //   this.bothWays == 1 ||
    //   this.shortTerm == 1 ||
    //   this.mediumTerm == 1 ||
    //   this.longTerm == 1) {
    //     this.beantwortet = 1;
    //   }
    
    this.startzeit = displayAntwort.startzeit
    this.endzeit = displayAntwort.endzeit
  }
}


export class DisplayTeilnehmerAntwort {
  id: number
  teilnehmerGuid: string
  fragenkatalogid: number
  
  wirkungsrichtung: number
  nichtRelevant: number
  
  shortTerm: boolean
  mediumTerm: boolean
  longTerm: boolean
  termDontKnow: boolean
  
  gesehen: boolean
  beantwortet: boolean
  
  startzeit: Date | null
  endzeit: Date | null

  aspektDE: string
  aspektEN: string
  themaDE: string
  themaEN: string
  frageDE: string
  frageEN: string
  beschreibungDE: string
  beschreibungEN: string

  constructor (antwort: TeilnehmerAntwort, frage: Fragenkatalog) {
    this.id = antwort.id
    this.teilnehmerGuid = antwort.teilnehmerGuid
    this.fragenkatalogid = antwort.fragenkatalogid
    this.frageDE = antwort.frageDE

    this.wirkungsrichtung = 0
    if (antwort.outsideIn == 1) {
      this.wirkungsrichtung = 1
    }
    else if (antwort.insideOut == 1) {
      this.wirkungsrichtung = 2
    }
    else if (antwort.bothWays == 1) {
      this.wirkungsrichtung = 3
    }
    else if (antwort.nichtRelevant == 1) {
      this.wirkungsrichtung = 4
    }
    else if (antwort.richtungDontKnow == 1) {
      this.wirkungsrichtung = 5
    }
    
    this.nichtRelevant = antwort.nichtRelevant
    
    this.shortTerm = antwort.shortTerm == 1 && antwort.nichtRelevant == 0 && antwort.richtungDontKnow == 0 ? true : false
    this.mediumTerm = antwort.mediumTerm == 1 && antwort.nichtRelevant == 0 && antwort.richtungDontKnow == 0 ? true : false
    this.longTerm = antwort.longTerm == 1 && antwort.nichtRelevant == 0 && antwort.richtungDontKnow == 0 ? true : false
    this.termDontKnow = antwort.termDontKnow == 1 && antwort.nichtRelevant == 0 && antwort.richtungDontKnow == 0 ? true : false
    
    this.gesehen = antwort.gesehen == 1 ? true : false
    this.beantwortet = antwort.beantwortet == 1 ? true : false
    
    this.startzeit = antwort.startzeit
    this.endzeit = antwort.endzeit

    this.aspektDE = frage.aspektDE
    this.aspektEN = frage.aspektEN
    this.themaDE = frage.themaDE
    this.themaEN = frage.themaEN
    this.frageEN = frage.frageEN
    this.beschreibungDE = frage.beschreibungDE
    this.beschreibungEN = frage.beschreibungEN
  }
}